export default {
  city: [
    {
      id: 2,
      parent_id: 1,
      region_name: "北京",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 52,
          parent_id: 2,
          region_name: "北京",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 500,
              parent_id: 52,
              region_name: "东城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 501,
              parent_id: 52,
              region_name: "西城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 502,
              parent_id: 52,
              region_name: "海淀区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 503,
              parent_id: 52,
              region_name: "朝阳区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 504,
              parent_id: 52,
              region_name: "崇文区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 505,
              parent_id: 52,
              region_name: "宣武区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 506,
              parent_id: 52,
              region_name: "丰台区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 507,
              parent_id: 52,
              region_name: "石景山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 508,
              parent_id: 52,
              region_name: "房山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 509,
              parent_id: 52,
              region_name: "门头沟区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 510,
              parent_id: 52,
              region_name: "通州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 511,
              parent_id: 52,
              region_name: "顺义区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 512,
              parent_id: 52,
              region_name: "昌平区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 513,
              parent_id: 52,
              region_name: "怀柔区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 514,
              parent_id: 52,
              region_name: "平谷区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 515,
              parent_id: 52,
              region_name: "大兴区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 516,
              parent_id: 52,
              region_name: "密云县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 517,
              parent_id: 52,
              region_name: "延庆县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 3,
      parent_id: 1,
      region_name: "安徽",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 36,
          parent_id: 3,
          region_name: "安庆",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 398,
              parent_id: 36,
              region_name: "迎江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 399,
              parent_id: 36,
              region_name: "大观区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 400,
              parent_id: 36,
              region_name: "宜秀区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 401,
              parent_id: 36,
              region_name: "桐城市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 402,
              parent_id: 36,
              region_name: "怀宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 403,
              parent_id: 36,
              region_name: "枞阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 404,
              parent_id: 36,
              region_name: "潜山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 405,
              parent_id: 36,
              region_name: "太湖县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 406,
              parent_id: 36,
              region_name: "宿松县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 407,
              parent_id: 36,
              region_name: "望江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 408,
              parent_id: 36,
              region_name: "岳西县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 37,
          parent_id: 3,
          region_name: "蚌埠",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 409,
              parent_id: 37,
              region_name: "中市区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 410,
              parent_id: 37,
              region_name: "东市区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 411,
              parent_id: 37,
              region_name: "西市区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 412,
              parent_id: 37,
              region_name: "郊区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 413,
              parent_id: 37,
              region_name: "怀远县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 414,
              parent_id: 37,
              region_name: "五河县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 415,
              parent_id: 37,
              region_name: "固镇县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 38,
          parent_id: 3,
          region_name: "巢湖",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 416,
              parent_id: 38,
              region_name: "居巢区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 417,
              parent_id: 38,
              region_name: "庐江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 418,
              parent_id: 38,
              region_name: "无为县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 419,
              parent_id: 38,
              region_name: "含山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 420,
              parent_id: 38,
              region_name: "和县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 39,
          parent_id: 3,
          region_name: "池州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 421,
              parent_id: 39,
              region_name: "贵池区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 422,
              parent_id: 39,
              region_name: "东至县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 423,
              parent_id: 39,
              region_name: "石台县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 424,
              parent_id: 39,
              region_name: "青阳县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 40,
          parent_id: 3,
          region_name: "滁州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 425,
              parent_id: 40,
              region_name: "琅琊区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 426,
              parent_id: 40,
              region_name: "南谯区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 427,
              parent_id: 40,
              region_name: "天长市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 428,
              parent_id: 40,
              region_name: "明光市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 429,
              parent_id: 40,
              region_name: "来安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 430,
              parent_id: 40,
              region_name: "全椒县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 431,
              parent_id: 40,
              region_name: "定远县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 432,
              parent_id: 40,
              region_name: "凤阳县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 41,
          parent_id: 3,
          region_name: "阜阳",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 433,
              parent_id: 41,
              region_name: "蚌山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 434,
              parent_id: 41,
              region_name: "龙子湖区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 435,
              parent_id: 41,
              region_name: "禹会区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 436,
              parent_id: 41,
              region_name: "淮上区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 437,
              parent_id: 41,
              region_name: "颍州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 438,
              parent_id: 41,
              region_name: "颍东区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 439,
              parent_id: 41,
              region_name: "颍泉区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 440,
              parent_id: 41,
              region_name: "界首市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 441,
              parent_id: 41,
              region_name: "临泉县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 442,
              parent_id: 41,
              region_name: "太和县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 443,
              parent_id: 41,
              region_name: "阜南县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 444,
              parent_id: 41,
              region_name: "颖上县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 42,
          parent_id: 3,
          region_name: "淮北",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 445,
              parent_id: 42,
              region_name: "相山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 446,
              parent_id: 42,
              region_name: "杜集区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 447,
              parent_id: 42,
              region_name: "烈山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 448,
              parent_id: 42,
              region_name: "濉溪县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 43,
          parent_id: 3,
          region_name: "淮南",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 449,
              parent_id: 43,
              region_name: "田家庵区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 450,
              parent_id: 43,
              region_name: "大通区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 451,
              parent_id: 43,
              region_name: "谢家集区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 452,
              parent_id: 43,
              region_name: "八公山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 453,
              parent_id: 43,
              region_name: "潘集区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 454,
              parent_id: 43,
              region_name: "凤台县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 44,
          parent_id: 3,
          region_name: "黄山",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 455,
              parent_id: 44,
              region_name: "屯溪区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 456,
              parent_id: 44,
              region_name: "黄山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 457,
              parent_id: 44,
              region_name: "徽州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 458,
              parent_id: 44,
              region_name: "歙县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 459,
              parent_id: 44,
              region_name: "休宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 460,
              parent_id: 44,
              region_name: "黟县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 461,
              parent_id: 44,
              region_name: "祁门县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 45,
          parent_id: 3,
          region_name: "六安",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 462,
              parent_id: 45,
              region_name: "金安区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 463,
              parent_id: 45,
              region_name: "裕安区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 464,
              parent_id: 45,
              region_name: "寿县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 465,
              parent_id: 45,
              region_name: "霍邱县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 466,
              parent_id: 45,
              region_name: "舒城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 467,
              parent_id: 45,
              region_name: "金寨县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 468,
              parent_id: 45,
              region_name: "霍山县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 46,
          parent_id: 3,
          region_name: "马鞍山",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 469,
              parent_id: 46,
              region_name: "雨山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 470,
              parent_id: 46,
              region_name: "花山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 471,
              parent_id: 46,
              region_name: "金家庄区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 472,
              parent_id: 46,
              region_name: "当涂县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 47,
          parent_id: 3,
          region_name: "宿州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 473,
              parent_id: 47,
              region_name: "埇桥区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 474,
              parent_id: 47,
              region_name: "砀山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 475,
              parent_id: 47,
              region_name: "萧县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 476,
              parent_id: 47,
              region_name: "灵璧县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 477,
              parent_id: 47,
              region_name: "泗县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 48,
          parent_id: 3,
          region_name: "铜陵",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 478,
              parent_id: 48,
              region_name: "铜官山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 479,
              parent_id: 48,
              region_name: "狮子山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 480,
              parent_id: 48,
              region_name: "郊区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 481,
              parent_id: 48,
              region_name: "铜陵县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 49,
          parent_id: 3,
          region_name: "芜湖",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 482,
              parent_id: 49,
              region_name: "镜湖区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 483,
              parent_id: 49,
              region_name: "弋江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 484,
              parent_id: 49,
              region_name: "鸠江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 485,
              parent_id: 49,
              region_name: "三山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 486,
              parent_id: 49,
              region_name: "芜湖县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 487,
              parent_id: 49,
              region_name: "繁昌县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 488,
              parent_id: 49,
              region_name: "南陵县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 50,
          parent_id: 3,
          region_name: "宣城",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 489,
              parent_id: 50,
              region_name: "宣州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 490,
              parent_id: 50,
              region_name: "宁国市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 491,
              parent_id: 50,
              region_name: "郎溪县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 492,
              parent_id: 50,
              region_name: "广德县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 493,
              parent_id: 50,
              region_name: "泾县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 494,
              parent_id: 50,
              region_name: "绩溪县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 495,
              parent_id: 50,
              region_name: "旌德县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 51,
          parent_id: 3,
          region_name: "亳州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 496,
              parent_id: 51,
              region_name: "涡阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 497,
              parent_id: 51,
              region_name: "蒙城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 498,
              parent_id: 51,
              region_name: "利辛县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 499,
              parent_id: 51,
              region_name: "谯城区",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 3401,
          parent_id: 3,
          region_name: "合肥",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3402,
              parent_id: 3401,
              region_name: "庐阳区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3403,
              parent_id: 3401,
              region_name: "瑶海区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3404,
              parent_id: 3401,
              region_name: "蜀山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3405,
              parent_id: 3401,
              region_name: "包河区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3406,
              parent_id: 3401,
              region_name: "长丰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3407,
              parent_id: 3401,
              region_name: "肥东县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3408,
              parent_id: 3401,
              region_name: "肥西县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 4,
      parent_id: 1,
      region_name: "福建",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 53,
          parent_id: 4,
          region_name: "福州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 518,
              parent_id: 53,
              region_name: "鼓楼区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 519,
              parent_id: 53,
              region_name: "台江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 520,
              parent_id: 53,
              region_name: "仓山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 521,
              parent_id: 53,
              region_name: "马尾区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 522,
              parent_id: 53,
              region_name: "晋安区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 523,
              parent_id: 53,
              region_name: "福清市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 524,
              parent_id: 53,
              region_name: "长乐市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 525,
              parent_id: 53,
              region_name: "闽侯县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 526,
              parent_id: 53,
              region_name: "连江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 527,
              parent_id: 53,
              region_name: "罗源县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 528,
              parent_id: 53,
              region_name: "闽清县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 529,
              parent_id: 53,
              region_name: "永泰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 530,
              parent_id: 53,
              region_name: "平潭县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 54,
          parent_id: 4,
          region_name: "龙岩",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 531,
              parent_id: 54,
              region_name: "新罗区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 532,
              parent_id: 54,
              region_name: "漳平市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 533,
              parent_id: 54,
              region_name: "长汀县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 534,
              parent_id: 54,
              region_name: "永定县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 535,
              parent_id: 54,
              region_name: "上杭县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 536,
              parent_id: 54,
              region_name: "武平县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 537,
              parent_id: 54,
              region_name: "连城县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 55,
          parent_id: 4,
          region_name: "南平",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 538,
              parent_id: 55,
              region_name: "延平区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 539,
              parent_id: 55,
              region_name: "邵武市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 540,
              parent_id: 55,
              region_name: "武夷山市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 541,
              parent_id: 55,
              region_name: "建瓯市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 542,
              parent_id: 55,
              region_name: "建阳市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 543,
              parent_id: 55,
              region_name: "顺昌县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 544,
              parent_id: 55,
              region_name: "浦城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 545,
              parent_id: 55,
              region_name: "光泽县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 546,
              parent_id: 55,
              region_name: "松溪县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 547,
              parent_id: 55,
              region_name: "政和县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 56,
          parent_id: 4,
          region_name: "宁德",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 548,
              parent_id: 56,
              region_name: "蕉城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 549,
              parent_id: 56,
              region_name: "福安市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 550,
              parent_id: 56,
              region_name: "福鼎市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 551,
              parent_id: 56,
              region_name: "霞浦县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 552,
              parent_id: 56,
              region_name: "古田县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 553,
              parent_id: 56,
              region_name: "屏南县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 554,
              parent_id: 56,
              region_name: "寿宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 555,
              parent_id: 56,
              region_name: "周宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 556,
              parent_id: 56,
              region_name: "柘荣县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 57,
          parent_id: 4,
          region_name: "莆田",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 557,
              parent_id: 57,
              region_name: "城厢区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 558,
              parent_id: 57,
              region_name: "涵江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 559,
              parent_id: 57,
              region_name: "荔城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 560,
              parent_id: 57,
              region_name: "秀屿区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 561,
              parent_id: 57,
              region_name: "仙游县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 58,
          parent_id: 4,
          region_name: "泉州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 562,
              parent_id: 58,
              region_name: "鲤城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 563,
              parent_id: 58,
              region_name: "丰泽区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 564,
              parent_id: 58,
              region_name: "洛江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 565,
              parent_id: 58,
              region_name: "清濛开发区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 566,
              parent_id: 58,
              region_name: "泉港区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 567,
              parent_id: 58,
              region_name: "石狮市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 568,
              parent_id: 58,
              region_name: "晋江市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 569,
              parent_id: 58,
              region_name: "南安市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 570,
              parent_id: 58,
              region_name: "惠安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 571,
              parent_id: 58,
              region_name: "安溪县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 572,
              parent_id: 58,
              region_name: "永春县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 573,
              parent_id: 58,
              region_name: "德化县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 574,
              parent_id: 58,
              region_name: "金门县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 59,
          parent_id: 4,
          region_name: "三明",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 575,
              parent_id: 59,
              region_name: "梅列区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 576,
              parent_id: 59,
              region_name: "三元区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 577,
              parent_id: 59,
              region_name: "永安市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 578,
              parent_id: 59,
              region_name: "明溪县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 579,
              parent_id: 59,
              region_name: "清流县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 580,
              parent_id: 59,
              region_name: "宁化县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 581,
              parent_id: 59,
              region_name: "大田县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 582,
              parent_id: 59,
              region_name: "尤溪县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 583,
              parent_id: 59,
              region_name: "沙县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 584,
              parent_id: 59,
              region_name: "将乐县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 585,
              parent_id: 59,
              region_name: "泰宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 586,
              parent_id: 59,
              region_name: "建宁县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 60,
          parent_id: 4,
          region_name: "厦门",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 587,
              parent_id: 60,
              region_name: "思明区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 588,
              parent_id: 60,
              region_name: "海沧区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 589,
              parent_id: 60,
              region_name: "湖里区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 590,
              parent_id: 60,
              region_name: "集美区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 591,
              parent_id: 60,
              region_name: "同安区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 592,
              parent_id: 60,
              region_name: "翔安区",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 61,
          parent_id: 4,
          region_name: "漳州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 593,
              parent_id: 61,
              region_name: "芗城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 594,
              parent_id: 61,
              region_name: "龙文区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 595,
              parent_id: 61,
              region_name: "龙海市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 596,
              parent_id: 61,
              region_name: "云霄县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 597,
              parent_id: 61,
              region_name: "漳浦县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 598,
              parent_id: 61,
              region_name: "诏安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 599,
              parent_id: 61,
              region_name: "长泰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 600,
              parent_id: 61,
              region_name: "东山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 601,
              parent_id: 61,
              region_name: "南靖县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 602,
              parent_id: 61,
              region_name: "平和县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 603,
              parent_id: 61,
              region_name: "华安县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 5,
      parent_id: 1,
      region_name: "甘肃",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 62,
          parent_id: 5,
          region_name: "兰州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 604,
              parent_id: 62,
              region_name: "皋兰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 605,
              parent_id: 62,
              region_name: "城关区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 606,
              parent_id: 62,
              region_name: "七里河区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 607,
              parent_id: 62,
              region_name: "西固区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 608,
              parent_id: 62,
              region_name: "安宁区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 609,
              parent_id: 62,
              region_name: "红古区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 610,
              parent_id: 62,
              region_name: "永登县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 611,
              parent_id: 62,
              region_name: "榆中县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 63,
          parent_id: 5,
          region_name: "白银",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 612,
              parent_id: 63,
              region_name: "白银区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 613,
              parent_id: 63,
              region_name: "平川区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 614,
              parent_id: 63,
              region_name: "会宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 615,
              parent_id: 63,
              region_name: "景泰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 616,
              parent_id: 63,
              region_name: "靖远县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 64,
          parent_id: 5,
          region_name: "定西",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 617,
              parent_id: 64,
              region_name: "临洮县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 618,
              parent_id: 64,
              region_name: "陇西县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 619,
              parent_id: 64,
              region_name: "通渭县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 620,
              parent_id: 64,
              region_name: "渭源县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 621,
              parent_id: 64,
              region_name: "漳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 622,
              parent_id: 64,
              region_name: "岷县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 623,
              parent_id: 64,
              region_name: "安定区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 624,
              parent_id: 64,
              region_name: "安定区",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 65,
          parent_id: 5,
          region_name: "甘南",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 625,
              parent_id: 65,
              region_name: "合作市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 626,
              parent_id: 65,
              region_name: "临潭县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 627,
              parent_id: 65,
              region_name: "卓尼县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 628,
              parent_id: 65,
              region_name: "舟曲县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 629,
              parent_id: 65,
              region_name: "迭部县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 630,
              parent_id: 65,
              region_name: "玛曲县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 631,
              parent_id: 65,
              region_name: "碌曲县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 632,
              parent_id: 65,
              region_name: "夏河县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 66,
          parent_id: 5,
          region_name: "嘉峪关",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 633,
              parent_id: 66,
              region_name: "嘉峪关市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 67,
          parent_id: 5,
          region_name: "金昌",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 634,
              parent_id: 67,
              region_name: "金川区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 635,
              parent_id: 67,
              region_name: "永昌县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 68,
          parent_id: 5,
          region_name: "酒泉",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 636,
              parent_id: 68,
              region_name: "肃州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 637,
              parent_id: 68,
              region_name: "玉门市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 638,
              parent_id: 68,
              region_name: "敦煌市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 639,
              parent_id: 68,
              region_name: "金塔县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 640,
              parent_id: 68,
              region_name: "瓜州县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 641,
              parent_id: 68,
              region_name: "肃北",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 642,
              parent_id: 68,
              region_name: "阿克塞",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 69,
          parent_id: 5,
          region_name: "临夏",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 643,
              parent_id: 69,
              region_name: "临夏市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 644,
              parent_id: 69,
              region_name: "临夏县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 645,
              parent_id: 69,
              region_name: "康乐县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 646,
              parent_id: 69,
              region_name: "永靖县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 647,
              parent_id: 69,
              region_name: "广河县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 648,
              parent_id: 69,
              region_name: "和政县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 649,
              parent_id: 69,
              region_name: "东乡族自治县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 650,
              parent_id: 69,
              region_name: "积石山",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 70,
          parent_id: 5,
          region_name: "陇南",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 651,
              parent_id: 70,
              region_name: "成县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 652,
              parent_id: 70,
              region_name: "徽县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 653,
              parent_id: 70,
              region_name: "康县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 654,
              parent_id: 70,
              region_name: "礼县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 655,
              parent_id: 70,
              region_name: "两当县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 656,
              parent_id: 70,
              region_name: "文县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 657,
              parent_id: 70,
              region_name: "西和县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 658,
              parent_id: 70,
              region_name: "宕昌县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 659,
              parent_id: 70,
              region_name: "武都区",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 71,
          parent_id: 5,
          region_name: "平凉",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 660,
              parent_id: 71,
              region_name: "崇信县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 661,
              parent_id: 71,
              region_name: "华亭县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 662,
              parent_id: 71,
              region_name: "静宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 663,
              parent_id: 71,
              region_name: "灵台县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 664,
              parent_id: 71,
              region_name: "崆峒区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 665,
              parent_id: 71,
              region_name: "庄浪县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 666,
              parent_id: 71,
              region_name: "泾川县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 72,
          parent_id: 5,
          region_name: "庆阳",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 667,
              parent_id: 72,
              region_name: "合水县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 668,
              parent_id: 72,
              region_name: "华池县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 669,
              parent_id: 72,
              region_name: "环县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 670,
              parent_id: 72,
              region_name: "宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 671,
              parent_id: 72,
              region_name: "庆城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 672,
              parent_id: 72,
              region_name: "西峰区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 673,
              parent_id: 72,
              region_name: "镇原县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 674,
              parent_id: 72,
              region_name: "正宁县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 73,
          parent_id: 5,
          region_name: "天水",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 675,
              parent_id: 73,
              region_name: "甘谷县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 676,
              parent_id: 73,
              region_name: "秦安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 677,
              parent_id: 73,
              region_name: "清水县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 678,
              parent_id: 73,
              region_name: "秦州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 679,
              parent_id: 73,
              region_name: "麦积区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 680,
              parent_id: 73,
              region_name: "武山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 681,
              parent_id: 73,
              region_name: "张家川",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 74,
          parent_id: 5,
          region_name: "武威",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 682,
              parent_id: 74,
              region_name: "古浪县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 683,
              parent_id: 74,
              region_name: "民勤县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 684,
              parent_id: 74,
              region_name: "天祝",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 685,
              parent_id: 74,
              region_name: "凉州区",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 75,
          parent_id: 5,
          region_name: "张掖",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 686,
              parent_id: 75,
              region_name: "高台县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 687,
              parent_id: 75,
              region_name: "临泽县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 688,
              parent_id: 75,
              region_name: "民乐县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 689,
              parent_id: 75,
              region_name: "山丹县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 690,
              parent_id: 75,
              region_name: "肃南",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 691,
              parent_id: 75,
              region_name: "甘州区",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 6,
      parent_id: 1,
      region_name: "广东",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 76,
          parent_id: 6,
          region_name: "广州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 692,
              parent_id: 76,
              region_name: "从化市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 693,
              parent_id: 76,
              region_name: "天河区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 694,
              parent_id: 76,
              region_name: "东山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 695,
              parent_id: 76,
              region_name: "白云区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 696,
              parent_id: 76,
              region_name: "海珠区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 697,
              parent_id: 76,
              region_name: "荔湾区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 698,
              parent_id: 76,
              region_name: "越秀区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 699,
              parent_id: 76,
              region_name: "黄埔区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 700,
              parent_id: 76,
              region_name: "番禺区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 701,
              parent_id: 76,
              region_name: "花都区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 702,
              parent_id: 76,
              region_name: "增城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 703,
              parent_id: 76,
              region_name: "从化区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 704,
              parent_id: 76,
              region_name: "市郊",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 77,
          parent_id: 6,
          region_name: "深圳",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 705,
              parent_id: 77,
              region_name: "福田区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 706,
              parent_id: 77,
              region_name: "罗湖区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 707,
              parent_id: 77,
              region_name: "南山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 708,
              parent_id: 77,
              region_name: "宝安区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 709,
              parent_id: 77,
              region_name: "龙岗区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 710,
              parent_id: 77,
              region_name: "盐田区",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 78,
          parent_id: 6,
          region_name: "潮州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 711,
              parent_id: 78,
              region_name: "湘桥区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 712,
              parent_id: 78,
              region_name: "潮安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 713,
              parent_id: 78,
              region_name: "饶平县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 79,
          parent_id: 6,
          region_name: "东莞",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 714,
              parent_id: 79,
              region_name: "南城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 715,
              parent_id: 79,
              region_name: "东城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 716,
              parent_id: 79,
              region_name: "万江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 717,
              parent_id: 79,
              region_name: "莞城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 718,
              parent_id: 79,
              region_name: "石龙镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 719,
              parent_id: 79,
              region_name: "虎门镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 720,
              parent_id: 79,
              region_name: "麻涌镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 721,
              parent_id: 79,
              region_name: "道滘镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 722,
              parent_id: 79,
              region_name: "石碣镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 723,
              parent_id: 79,
              region_name: "沙田镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 724,
              parent_id: 79,
              region_name: "望牛墩镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 725,
              parent_id: 79,
              region_name: "洪梅镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 726,
              parent_id: 79,
              region_name: "茶山镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 727,
              parent_id: 79,
              region_name: "寮步镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 728,
              parent_id: 79,
              region_name: "大岭山镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 729,
              parent_id: 79,
              region_name: "大朗镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 730,
              parent_id: 79,
              region_name: "黄江镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 731,
              parent_id: 79,
              region_name: "樟木头",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 732,
              parent_id: 79,
              region_name: "凤岗镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 733,
              parent_id: 79,
              region_name: "塘厦镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 734,
              parent_id: 79,
              region_name: "谢岗镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 735,
              parent_id: 79,
              region_name: "厚街镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 736,
              parent_id: 79,
              region_name: "清溪镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 737,
              parent_id: 79,
              region_name: "常平镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 738,
              parent_id: 79,
              region_name: "桥头镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 739,
              parent_id: 79,
              region_name: "横沥镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 740,
              parent_id: 79,
              region_name: "东坑镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 741,
              parent_id: 79,
              region_name: "企石镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 742,
              parent_id: 79,
              region_name: "石排镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 743,
              parent_id: 79,
              region_name: "长安镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 744,
              parent_id: 79,
              region_name: "中堂镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 745,
              parent_id: 79,
              region_name: "高埗镇",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 80,
          parent_id: 6,
          region_name: "佛山",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 746,
              parent_id: 80,
              region_name: "禅城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 747,
              parent_id: 80,
              region_name: "南海区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 748,
              parent_id: 80,
              region_name: "顺德区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 749,
              parent_id: 80,
              region_name: "三水区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 750,
              parent_id: 80,
              region_name: "高明区",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 81,
          parent_id: 6,
          region_name: "河源",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 751,
              parent_id: 81,
              region_name: "东源县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 752,
              parent_id: 81,
              region_name: "和平县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 753,
              parent_id: 81,
              region_name: "源城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 754,
              parent_id: 81,
              region_name: "连平县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 755,
              parent_id: 81,
              region_name: "龙川县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 756,
              parent_id: 81,
              region_name: "紫金县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 82,
          parent_id: 6,
          region_name: "惠州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 757,
              parent_id: 82,
              region_name: "惠阳区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 758,
              parent_id: 82,
              region_name: "惠城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 759,
              parent_id: 82,
              region_name: "大亚湾",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 760,
              parent_id: 82,
              region_name: "博罗县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 761,
              parent_id: 82,
              region_name: "惠东县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 762,
              parent_id: 82,
              region_name: "龙门县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 83,
          parent_id: 6,
          region_name: "江门",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 763,
              parent_id: 83,
              region_name: "江海区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 764,
              parent_id: 83,
              region_name: "蓬江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 765,
              parent_id: 83,
              region_name: "新会区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 766,
              parent_id: 83,
              region_name: "台山市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 767,
              parent_id: 83,
              region_name: "开平市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 768,
              parent_id: 83,
              region_name: "鹤山市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 769,
              parent_id: 83,
              region_name: "恩平市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 84,
          parent_id: 6,
          region_name: "揭阳",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 770,
              parent_id: 84,
              region_name: "榕城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 771,
              parent_id: 84,
              region_name: "普宁市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 772,
              parent_id: 84,
              region_name: "揭东县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 773,
              parent_id: 84,
              region_name: "揭西县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 774,
              parent_id: 84,
              region_name: "惠来县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 85,
          parent_id: 6,
          region_name: "茂名",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 775,
              parent_id: 85,
              region_name: "茂南区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 776,
              parent_id: 85,
              region_name: "茂港区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 777,
              parent_id: 85,
              region_name: "高州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 778,
              parent_id: 85,
              region_name: "化州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 779,
              parent_id: 85,
              region_name: "信宜市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 780,
              parent_id: 85,
              region_name: "电白县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 86,
          parent_id: 6,
          region_name: "梅州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 781,
              parent_id: 86,
              region_name: "梅县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 782,
              parent_id: 86,
              region_name: "梅江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 783,
              parent_id: 86,
              region_name: "兴宁市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 784,
              parent_id: 86,
              region_name: "大埔县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 785,
              parent_id: 86,
              region_name: "丰顺县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 786,
              parent_id: 86,
              region_name: "五华县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 787,
              parent_id: 86,
              region_name: "平远县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 788,
              parent_id: 86,
              region_name: "蕉岭县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 87,
          parent_id: 6,
          region_name: "清远",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 789,
              parent_id: 87,
              region_name: "清城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 790,
              parent_id: 87,
              region_name: "英德市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 791,
              parent_id: 87,
              region_name: "连州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 792,
              parent_id: 87,
              region_name: "佛冈县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 793,
              parent_id: 87,
              region_name: "阳山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 794,
              parent_id: 87,
              region_name: "清新县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 795,
              parent_id: 87,
              region_name: "连山",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 796,
              parent_id: 87,
              region_name: "连南",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 88,
          parent_id: 6,
          region_name: "汕头",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 797,
              parent_id: 88,
              region_name: "南澳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 798,
              parent_id: 88,
              region_name: "潮阳区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 799,
              parent_id: 88,
              region_name: "澄海区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 800,
              parent_id: 88,
              region_name: "龙湖区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 801,
              parent_id: 88,
              region_name: "金平区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 802,
              parent_id: 88,
              region_name: "濠江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 803,
              parent_id: 88,
              region_name: "潮南区",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 89,
          parent_id: 6,
          region_name: "汕尾",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 804,
              parent_id: 89,
              region_name: "城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 805,
              parent_id: 89,
              region_name: "陆丰市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 806,
              parent_id: 89,
              region_name: "海丰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 807,
              parent_id: 89,
              region_name: "陆河县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 90,
          parent_id: 6,
          region_name: "韶关",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 808,
              parent_id: 90,
              region_name: "曲江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 809,
              parent_id: 90,
              region_name: "浈江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 810,
              parent_id: 90,
              region_name: "武江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 811,
              parent_id: 90,
              region_name: "曲江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 812,
              parent_id: 90,
              region_name: "乐昌市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 813,
              parent_id: 90,
              region_name: "南雄市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 814,
              parent_id: 90,
              region_name: "始兴县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 815,
              parent_id: 90,
              region_name: "仁化县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 816,
              parent_id: 90,
              region_name: "翁源县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 817,
              parent_id: 90,
              region_name: "新丰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 818,
              parent_id: 90,
              region_name: "乳源",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 91,
          parent_id: 6,
          region_name: "阳江",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 819,
              parent_id: 91,
              region_name: "江城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 820,
              parent_id: 91,
              region_name: "阳春市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 821,
              parent_id: 91,
              region_name: "阳西县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 822,
              parent_id: 91,
              region_name: "阳东县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 92,
          parent_id: 6,
          region_name: "云浮",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 823,
              parent_id: 92,
              region_name: "云城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 824,
              parent_id: 92,
              region_name: "罗定市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 825,
              parent_id: 92,
              region_name: "新兴县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 826,
              parent_id: 92,
              region_name: "郁南县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 827,
              parent_id: 92,
              region_name: "云安县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 93,
          parent_id: 6,
          region_name: "湛江",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 828,
              parent_id: 93,
              region_name: "赤坎区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 829,
              parent_id: 93,
              region_name: "霞山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 830,
              parent_id: 93,
              region_name: "坡头区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 831,
              parent_id: 93,
              region_name: "麻章区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 832,
              parent_id: 93,
              region_name: "廉江市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 833,
              parent_id: 93,
              region_name: "雷州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 834,
              parent_id: 93,
              region_name: "吴川市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 835,
              parent_id: 93,
              region_name: "遂溪县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 836,
              parent_id: 93,
              region_name: "徐闻县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 94,
          parent_id: 6,
          region_name: "肇庆",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 837,
              parent_id: 94,
              region_name: "肇庆市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 838,
              parent_id: 94,
              region_name: "高要市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 839,
              parent_id: 94,
              region_name: "四会市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 840,
              parent_id: 94,
              region_name: "广宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 841,
              parent_id: 94,
              region_name: "怀集县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 842,
              parent_id: 94,
              region_name: "封开县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 843,
              parent_id: 94,
              region_name: "德庆县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 95,
          parent_id: 6,
          region_name: "中山",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 844,
              parent_id: 95,
              region_name: "石岐街道",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 845,
              parent_id: 95,
              region_name: "东区街道",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 846,
              parent_id: 95,
              region_name: "西区街道",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 847,
              parent_id: 95,
              region_name: "环城街道",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 848,
              parent_id: 95,
              region_name: "中山港街道",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 849,
              parent_id: 95,
              region_name: "五桂山街道",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 96,
          parent_id: 6,
          region_name: "珠海",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 850,
              parent_id: 96,
              region_name: "香洲区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 851,
              parent_id: 96,
              region_name: "斗门区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 852,
              parent_id: 96,
              region_name: "金湾区",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 7,
      parent_id: 1,
      region_name: "广西",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 97,
          parent_id: 7,
          region_name: "南宁",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 853,
              parent_id: 97,
              region_name: "邕宁区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 854,
              parent_id: 97,
              region_name: "青秀区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 855,
              parent_id: 97,
              region_name: "兴宁区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 856,
              parent_id: 97,
              region_name: "良庆区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 857,
              parent_id: 97,
              region_name: "西乡塘区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 858,
              parent_id: 97,
              region_name: "江南区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 859,
              parent_id: 97,
              region_name: "武鸣县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 860,
              parent_id: 97,
              region_name: "隆安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 861,
              parent_id: 97,
              region_name: "马山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 862,
              parent_id: 97,
              region_name: "上林县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 863,
              parent_id: 97,
              region_name: "宾阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 864,
              parent_id: 97,
              region_name: "横县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 98,
          parent_id: 7,
          region_name: "桂林",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 865,
              parent_id: 98,
              region_name: "秀峰区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 866,
              parent_id: 98,
              region_name: "叠彩区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 867,
              parent_id: 98,
              region_name: "象山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 868,
              parent_id: 98,
              region_name: "七星区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 869,
              parent_id: 98,
              region_name: "雁山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 870,
              parent_id: 98,
              region_name: "阳朔县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 871,
              parent_id: 98,
              region_name: "临桂县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 872,
              parent_id: 98,
              region_name: "灵川县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 873,
              parent_id: 98,
              region_name: "全州县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 874,
              parent_id: 98,
              region_name: "平乐县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 875,
              parent_id: 98,
              region_name: "兴安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 876,
              parent_id: 98,
              region_name: "灌阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 877,
              parent_id: 98,
              region_name: "荔浦县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 878,
              parent_id: 98,
              region_name: "资源县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 879,
              parent_id: 98,
              region_name: "永福县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 880,
              parent_id: 98,
              region_name: "龙胜",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 881,
              parent_id: 98,
              region_name: "恭城",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 99,
          parent_id: 7,
          region_name: "百色",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 882,
              parent_id: 99,
              region_name: "右江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 883,
              parent_id: 99,
              region_name: "凌云县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 884,
              parent_id: 99,
              region_name: "平果县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 885,
              parent_id: 99,
              region_name: "西林县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 886,
              parent_id: 99,
              region_name: "乐业县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 887,
              parent_id: 99,
              region_name: "德保县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 888,
              parent_id: 99,
              region_name: "田林县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 889,
              parent_id: 99,
              region_name: "田阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 890,
              parent_id: 99,
              region_name: "靖西县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 891,
              parent_id: 99,
              region_name: "田东县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 892,
              parent_id: 99,
              region_name: "那坡县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 893,
              parent_id: 99,
              region_name: "隆林",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 100,
          parent_id: 7,
          region_name: "北海",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 894,
              parent_id: 100,
              region_name: "海城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 895,
              parent_id: 100,
              region_name: "银海区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 896,
              parent_id: 100,
              region_name: "铁山港区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 897,
              parent_id: 100,
              region_name: "合浦县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 101,
          parent_id: 7,
          region_name: "崇左",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 898,
              parent_id: 101,
              region_name: "江州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 899,
              parent_id: 101,
              region_name: "凭祥市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 900,
              parent_id: 101,
              region_name: "宁明县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 901,
              parent_id: 101,
              region_name: "扶绥县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 902,
              parent_id: 101,
              region_name: "龙州县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 903,
              parent_id: 101,
              region_name: "大新县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 904,
              parent_id: 101,
              region_name: "天等县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 102,
          parent_id: 7,
          region_name: "防城港",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 905,
              parent_id: 102,
              region_name: "港口区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 906,
              parent_id: 102,
              region_name: "防城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 907,
              parent_id: 102,
              region_name: "东兴市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 908,
              parent_id: 102,
              region_name: "上思县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 103,
          parent_id: 7,
          region_name: "贵港",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 909,
              parent_id: 103,
              region_name: "港北区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 910,
              parent_id: 103,
              region_name: "港南区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 911,
              parent_id: 103,
              region_name: "覃塘区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 912,
              parent_id: 103,
              region_name: "桂平市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 913,
              parent_id: 103,
              region_name: "平南县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 104,
          parent_id: 7,
          region_name: "河池",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 914,
              parent_id: 104,
              region_name: "金城江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 915,
              parent_id: 104,
              region_name: "宜州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 916,
              parent_id: 104,
              region_name: "天峨县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 917,
              parent_id: 104,
              region_name: "凤山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 918,
              parent_id: 104,
              region_name: "南丹县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 919,
              parent_id: 104,
              region_name: "东兰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 920,
              parent_id: 104,
              region_name: "都安",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 921,
              parent_id: 104,
              region_name: "罗城",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 922,
              parent_id: 104,
              region_name: "巴马",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 923,
              parent_id: 104,
              region_name: "环江",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 924,
              parent_id: 104,
              region_name: "大化",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 105,
          parent_id: 7,
          region_name: "贺州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 925,
              parent_id: 105,
              region_name: "八步区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 926,
              parent_id: 105,
              region_name: "钟山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 927,
              parent_id: 105,
              region_name: "昭平县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 928,
              parent_id: 105,
              region_name: "富川",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 106,
          parent_id: 7,
          region_name: "来宾",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 929,
              parent_id: 106,
              region_name: "兴宾区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 930,
              parent_id: 106,
              region_name: "合山市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 931,
              parent_id: 106,
              region_name: "象州县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 932,
              parent_id: 106,
              region_name: "武宣县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 933,
              parent_id: 106,
              region_name: "忻城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 934,
              parent_id: 106,
              region_name: "金秀",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 107,
          parent_id: 7,
          region_name: "柳州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 935,
              parent_id: 107,
              region_name: "城中区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 936,
              parent_id: 107,
              region_name: "鱼峰区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 937,
              parent_id: 107,
              region_name: "柳北区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 938,
              parent_id: 107,
              region_name: "柳南区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 939,
              parent_id: 107,
              region_name: "柳江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 940,
              parent_id: 107,
              region_name: "柳城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 941,
              parent_id: 107,
              region_name: "鹿寨县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 942,
              parent_id: 107,
              region_name: "融安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 943,
              parent_id: 107,
              region_name: "融水",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 944,
              parent_id: 107,
              region_name: "三江",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 108,
          parent_id: 7,
          region_name: "钦州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 945,
              parent_id: 108,
              region_name: "钦南区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 946,
              parent_id: 108,
              region_name: "钦北区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 947,
              parent_id: 108,
              region_name: "灵山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 948,
              parent_id: 108,
              region_name: "浦北县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 109,
          parent_id: 7,
          region_name: "梧州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 949,
              parent_id: 109,
              region_name: "万秀区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 950,
              parent_id: 109,
              region_name: "蝶山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 951,
              parent_id: 109,
              region_name: "长洲区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 952,
              parent_id: 109,
              region_name: "岑溪市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 953,
              parent_id: 109,
              region_name: "苍梧县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 954,
              parent_id: 109,
              region_name: "藤县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 955,
              parent_id: 109,
              region_name: "蒙山县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 110,
          parent_id: 7,
          region_name: "玉林",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 956,
              parent_id: 110,
              region_name: "玉州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 957,
              parent_id: 110,
              region_name: "北流市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 958,
              parent_id: 110,
              region_name: "容县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 959,
              parent_id: 110,
              region_name: "陆川县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 960,
              parent_id: 110,
              region_name: "博白县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 961,
              parent_id: 110,
              region_name: "兴业县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 8,
      parent_id: 1,
      region_name: "贵州",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 111,
          parent_id: 8,
          region_name: "贵阳",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 962,
              parent_id: 111,
              region_name: "南明区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 963,
              parent_id: 111,
              region_name: "云岩区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 964,
              parent_id: 111,
              region_name: "花溪区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 965,
              parent_id: 111,
              region_name: "乌当区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 966,
              parent_id: 111,
              region_name: "白云区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 967,
              parent_id: 111,
              region_name: "小河区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 968,
              parent_id: 111,
              region_name: "金阳新区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 969,
              parent_id: 111,
              region_name: "新天园区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 970,
              parent_id: 111,
              region_name: "清镇市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 971,
              parent_id: 111,
              region_name: "开阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 972,
              parent_id: 111,
              region_name: "修文县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 973,
              parent_id: 111,
              region_name: "息烽县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 112,
          parent_id: 8,
          region_name: "安顺",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 974,
              parent_id: 112,
              region_name: "西秀区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 975,
              parent_id: 112,
              region_name: "关岭",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 976,
              parent_id: 112,
              region_name: "镇宁",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 977,
              parent_id: 112,
              region_name: "紫云",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 978,
              parent_id: 112,
              region_name: "平坝县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 979,
              parent_id: 112,
              region_name: "普定县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 113,
          parent_id: 8,
          region_name: "毕节",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 980,
              parent_id: 113,
              region_name: "毕节市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 981,
              parent_id: 113,
              region_name: "大方县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 982,
              parent_id: 113,
              region_name: "黔西县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 983,
              parent_id: 113,
              region_name: "金沙县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 984,
              parent_id: 113,
              region_name: "织金县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 985,
              parent_id: 113,
              region_name: "纳雍县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 986,
              parent_id: 113,
              region_name: "赫章县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 987,
              parent_id: 113,
              region_name: "威宁",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 114,
          parent_id: 8,
          region_name: "六盘水",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 988,
              parent_id: 114,
              region_name: "钟山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 989,
              parent_id: 114,
              region_name: "六枝特区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 990,
              parent_id: 114,
              region_name: "水城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 991,
              parent_id: 114,
              region_name: "盘县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 115,
          parent_id: 8,
          region_name: "黔东南",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 992,
              parent_id: 115,
              region_name: "凯里市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 993,
              parent_id: 115,
              region_name: "黄平县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 994,
              parent_id: 115,
              region_name: "施秉县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 995,
              parent_id: 115,
              region_name: "三穗县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 996,
              parent_id: 115,
              region_name: "镇远县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 997,
              parent_id: 115,
              region_name: "岑巩县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 998,
              parent_id: 115,
              region_name: "天柱县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 999,
              parent_id: 115,
              region_name: "锦屏县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1000,
              parent_id: 115,
              region_name: "剑河县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1001,
              parent_id: 115,
              region_name: "台江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1002,
              parent_id: 115,
              region_name: "黎平县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1003,
              parent_id: 115,
              region_name: "榕江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1004,
              parent_id: 115,
              region_name: "从江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1005,
              parent_id: 115,
              region_name: "雷山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1006,
              parent_id: 115,
              region_name: "麻江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1007,
              parent_id: 115,
              region_name: "丹寨县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 116,
          parent_id: 8,
          region_name: "黔南",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1008,
              parent_id: 116,
              region_name: "都匀市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1009,
              parent_id: 116,
              region_name: "福泉市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1010,
              parent_id: 116,
              region_name: "荔波县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1011,
              parent_id: 116,
              region_name: "贵定县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1012,
              parent_id: 116,
              region_name: "瓮安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1013,
              parent_id: 116,
              region_name: "独山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1014,
              parent_id: 116,
              region_name: "平塘县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1015,
              parent_id: 116,
              region_name: "罗甸县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1016,
              parent_id: 116,
              region_name: "长顺县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1017,
              parent_id: 116,
              region_name: "龙里县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1018,
              parent_id: 116,
              region_name: "惠水县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1019,
              parent_id: 116,
              region_name: "三都",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 117,
          parent_id: 8,
          region_name: "黔西南",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1020,
              parent_id: 117,
              region_name: "兴义市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1021,
              parent_id: 117,
              region_name: "兴仁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1022,
              parent_id: 117,
              region_name: "普安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1023,
              parent_id: 117,
              region_name: "晴隆县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1024,
              parent_id: 117,
              region_name: "贞丰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1025,
              parent_id: 117,
              region_name: "望谟县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1026,
              parent_id: 117,
              region_name: "册亨县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1027,
              parent_id: 117,
              region_name: "安龙县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 118,
          parent_id: 8,
          region_name: "铜仁",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1028,
              parent_id: 118,
              region_name: "铜仁市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1029,
              parent_id: 118,
              region_name: "江口县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1030,
              parent_id: 118,
              region_name: "石阡县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1031,
              parent_id: 118,
              region_name: "思南县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1032,
              parent_id: 118,
              region_name: "德江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1033,
              parent_id: 118,
              region_name: "玉屏",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1034,
              parent_id: 118,
              region_name: "印江",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1035,
              parent_id: 118,
              region_name: "沿河",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1036,
              parent_id: 118,
              region_name: "松桃",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1037,
              parent_id: 118,
              region_name: "万山特区",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 119,
          parent_id: 8,
          region_name: "遵义",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1038,
              parent_id: 119,
              region_name: "红花岗区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1039,
              parent_id: 119,
              region_name: "务川县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1040,
              parent_id: 119,
              region_name: "道真县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1041,
              parent_id: 119,
              region_name: "汇川区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1042,
              parent_id: 119,
              region_name: "赤水市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1043,
              parent_id: 119,
              region_name: "仁怀市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1044,
              parent_id: 119,
              region_name: "遵义县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1045,
              parent_id: 119,
              region_name: "桐梓县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1046,
              parent_id: 119,
              region_name: "绥阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1047,
              parent_id: 119,
              region_name: "正安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1048,
              parent_id: 119,
              region_name: "凤冈县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1049,
              parent_id: 119,
              region_name: "湄潭县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1050,
              parent_id: 119,
              region_name: "余庆县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1051,
              parent_id: 119,
              region_name: "习水县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1052,
              parent_id: 119,
              region_name: "道真",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1053,
              parent_id: 119,
              region_name: "务川",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 9,
      parent_id: 1,
      region_name: "海南",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 120,
          parent_id: 9,
          region_name: "海口",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1054,
              parent_id: 120,
              region_name: "秀英区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1055,
              parent_id: 120,
              region_name: "龙华区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1056,
              parent_id: 120,
              region_name: "琼山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1057,
              parent_id: 120,
              region_name: "美兰区",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 121,
          parent_id: 9,
          region_name: "三亚",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3409,
              parent_id: 121,
              region_name: "海棠湾镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3410,
              parent_id: 121,
              region_name: "田独镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3411,
              parent_id: 121,
              region_name: "凤凰镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3412,
              parent_id: 121,
              region_name: "崖城镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3413,
              parent_id: 121,
              region_name: "天涯镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3414,
              parent_id: 121,
              region_name: "育才镇",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 122,
          parent_id: 9,
          region_name: "白沙",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3415,
              parent_id: 122,
              region_name: "牙叉镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3416,
              parent_id: 122,
              region_name: "七坊镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3417,
              parent_id: 122,
              region_name: "邦溪镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3418,
              parent_id: 122,
              region_name: "打安镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3419,
              parent_id: 122,
              region_name: "细水乡",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3420,
              parent_id: 122,
              region_name: "元门乡",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3421,
              parent_id: 122,
              region_name: "南开乡",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3422,
              parent_id: 122,
              region_name: "阜龙乡",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3423,
              parent_id: 122,
              region_name: "青松乡",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3424,
              parent_id: 122,
              region_name: "金波乡",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3425,
              parent_id: 122,
              region_name: "荣邦乡",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 123,
          parent_id: 9,
          region_name: "保亭",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3426,
              parent_id: 123,
              region_name: "保城镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3427,
              parent_id: 123,
              region_name: "什玲镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3428,
              parent_id: 123,
              region_name: "加茂镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3429,
              parent_id: 123,
              region_name: "响水镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3430,
              parent_id: 123,
              region_name: "新政镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3431,
              parent_id: 123,
              region_name: "三道镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3432,
              parent_id: 123,
              region_name: "六弓乡",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3433,
              parent_id: 123,
              region_name: "南林乡",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3434,
              parent_id: 123,
              region_name: "毛感乡",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 124,
          parent_id: 9,
          region_name: "昌江",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3435,
              parent_id: 124,
              region_name: "石碌镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3436,
              parent_id: 124,
              region_name: "叉河镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3437,
              parent_id: 124,
              region_name: "十月田镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3438,
              parent_id: 124,
              region_name: "乌烈镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3439,
              parent_id: 124,
              region_name: "昌化镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3440,
              parent_id: 124,
              region_name: "海尾镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3441,
              parent_id: 124,
              region_name: "七叉镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3442,
              parent_id: 124,
              region_name: "王下乡",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 125,
          parent_id: 9,
          region_name: "澄迈县",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3443,
              parent_id: 125,
              region_name: "金江镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3444,
              parent_id: 125,
              region_name: "老城镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3445,
              parent_id: 125,
              region_name: "瑞溪镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3446,
              parent_id: 125,
              region_name: "永发镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3447,
              parent_id: 125,
              region_name: "加乐镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3448,
              parent_id: 125,
              region_name: "文儒镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3449,
              parent_id: 125,
              region_name: "中兴镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3450,
              parent_id: 125,
              region_name: "仁兴镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3451,
              parent_id: 125,
              region_name: "福山镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3452,
              parent_id: 125,
              region_name: "桥头镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3453,
              parent_id: 125,
              region_name: "大丰镇",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 126,
          parent_id: 9,
          region_name: "定安县",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3454,
              parent_id: 126,
              region_name: "定城镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3455,
              parent_id: 126,
              region_name: "新竹镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3456,
              parent_id: 126,
              region_name: "龙湖镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3457,
              parent_id: 126,
              region_name: "黄竹镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3458,
              parent_id: 126,
              region_name: "雷鸣镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3459,
              parent_id: 126,
              region_name: "龙门镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3460,
              parent_id: 126,
              region_name: "龙河镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3461,
              parent_id: 126,
              region_name: "岭口镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3462,
              parent_id: 126,
              region_name: "翰林镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3463,
              parent_id: 126,
              region_name: "富文镇",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 127,
          parent_id: 9,
          region_name: "东方",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3464,
              parent_id: 127,
              region_name: "八所镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3465,
              parent_id: 127,
              region_name: "东河镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3466,
              parent_id: 127,
              region_name: "大田镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3467,
              parent_id: 127,
              region_name: "感城镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3468,
              parent_id: 127,
              region_name: "板桥镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3469,
              parent_id: 127,
              region_name: "三家镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3470,
              parent_id: 127,
              region_name: "四更镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3471,
              parent_id: 127,
              region_name: "新龙镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3472,
              parent_id: 127,
              region_name: "天安乡",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3473,
              parent_id: 127,
              region_name: "江边乡",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 128,
          parent_id: 9,
          region_name: "乐东",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3474,
              parent_id: 128,
              region_name: "抱由镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3475,
              parent_id: 128,
              region_name: "万冲镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3476,
              parent_id: 128,
              region_name: "大安镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3477,
              parent_id: 128,
              region_name: "志仲镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3478,
              parent_id: 128,
              region_name: "千家镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3479,
              parent_id: 128,
              region_name: "九所镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3480,
              parent_id: 128,
              region_name: "利国镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3481,
              parent_id: 128,
              region_name: "黄流镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3482,
              parent_id: 128,
              region_name: "佛罗镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3483,
              parent_id: 128,
              region_name: "尖峰镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3484,
              parent_id: 128,
              region_name: "莺歌海镇",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 129,
          parent_id: 9,
          region_name: "临高县",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3485,
              parent_id: 129,
              region_name: "临城镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3486,
              parent_id: 129,
              region_name: "波莲镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3487,
              parent_id: 129,
              region_name: "东英镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3488,
              parent_id: 129,
              region_name: "博厚镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3489,
              parent_id: 129,
              region_name: "皇桐镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3490,
              parent_id: 129,
              region_name: "多文镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3491,
              parent_id: 129,
              region_name: "和舍镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3492,
              parent_id: 129,
              region_name: "南宝镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3493,
              parent_id: 129,
              region_name: "新盈镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3494,
              parent_id: 129,
              region_name: "调楼镇",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 130,
          parent_id: 9,
          region_name: "陵水",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3495,
              parent_id: 130,
              region_name: "椰林镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3496,
              parent_id: 130,
              region_name: "光坡镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3497,
              parent_id: 130,
              region_name: "三才镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3498,
              parent_id: 130,
              region_name: "英州镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3499,
              parent_id: 130,
              region_name: "隆广镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3500,
              parent_id: 130,
              region_name: "文罗镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3501,
              parent_id: 130,
              region_name: "本号镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3502,
              parent_id: 130,
              region_name: "新村镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3503,
              parent_id: 130,
              region_name: "黎安镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3504,
              parent_id: 130,
              region_name: "提蒙乡",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3505,
              parent_id: 130,
              region_name: "群英乡",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 131,
          parent_id: 9,
          region_name: "琼海",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3506,
              parent_id: 131,
              region_name: "嘉积镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3507,
              parent_id: 131,
              region_name: "万泉镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3508,
              parent_id: 131,
              region_name: "石壁镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3509,
              parent_id: 131,
              region_name: "中原镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3510,
              parent_id: 131,
              region_name: "博鳌镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3511,
              parent_id: 131,
              region_name: "阳江镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3512,
              parent_id: 131,
              region_name: "龙江镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3513,
              parent_id: 131,
              region_name: "潭门镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3514,
              parent_id: 131,
              region_name: "塔洋镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3515,
              parent_id: 131,
              region_name: "长坡镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3516,
              parent_id: 131,
              region_name: "大路镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3517,
              parent_id: 131,
              region_name: "会山镇",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 132,
          parent_id: 9,
          region_name: "琼中",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3518,
              parent_id: 132,
              region_name: "营根镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3519,
              parent_id: 132,
              region_name: "湾岭镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3520,
              parent_id: 132,
              region_name: "黎母山镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3521,
              parent_id: 132,
              region_name: "和平镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3522,
              parent_id: 132,
              region_name: "长征镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3523,
              parent_id: 132,
              region_name: "红毛镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3524,
              parent_id: 132,
              region_name: "中平镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3525,
              parent_id: 132,
              region_name: "吊罗山乡",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3526,
              parent_id: 132,
              region_name: "上安乡",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3527,
              parent_id: 132,
              region_name: "什运乡",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 133,
          parent_id: 9,
          region_name: "屯昌县",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3528,
              parent_id: 133,
              region_name: "屯城镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3529,
              parent_id: 133,
              region_name: "新兴镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3530,
              parent_id: 133,
              region_name: "枫木镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3531,
              parent_id: 133,
              region_name: "乌坡镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3532,
              parent_id: 133,
              region_name: "南吕镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3533,
              parent_id: 133,
              region_name: "南坤镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3534,
              parent_id: 133,
              region_name: "坡心镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3535,
              parent_id: 133,
              region_name: "西昌镇",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 134,
          parent_id: 9,
          region_name: "万宁",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3536,
              parent_id: 134,
              region_name: "万城镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3537,
              parent_id: 134,
              region_name: "龙滚镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3538,
              parent_id: 134,
              region_name: "和乐镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3539,
              parent_id: 134,
              region_name: "后安镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3540,
              parent_id: 134,
              region_name: "大茂镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3541,
              parent_id: 134,
              region_name: "东澳镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3542,
              parent_id: 134,
              region_name: "礼纪镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3543,
              parent_id: 134,
              region_name: "长丰镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3544,
              parent_id: 134,
              region_name: "山根镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3545,
              parent_id: 134,
              region_name: "山根镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3546,
              parent_id: 134,
              region_name: "南桥镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3547,
              parent_id: 134,
              region_name: "三更罗镇",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 135,
          parent_id: 9,
          region_name: "文昌",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3548,
              parent_id: 135,
              region_name: "文城镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3549,
              parent_id: 135,
              region_name: "重兴镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3550,
              parent_id: 135,
              region_name: "蓬莱镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3551,
              parent_id: 135,
              region_name: "会文镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3552,
              parent_id: 135,
              region_name: "东路镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3553,
              parent_id: 135,
              region_name: "潭牛镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3554,
              parent_id: 135,
              region_name: "东阁镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3555,
              parent_id: 135,
              region_name: "文教镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3556,
              parent_id: 135,
              region_name: "东郊镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3557,
              parent_id: 135,
              region_name: "龙楼镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3558,
              parent_id: 135,
              region_name: "昌洒镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3559,
              parent_id: 135,
              region_name: "翁田镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3560,
              parent_id: 135,
              region_name: "抱罗镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3561,
              parent_id: 135,
              region_name: "冯坡镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3562,
              parent_id: 135,
              region_name: "锦山镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3563,
              parent_id: 135,
              region_name: "铺前镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3564,
              parent_id: 135,
              region_name: "公坡镇",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 136,
          parent_id: 9,
          region_name: "五指山",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3565,
              parent_id: 136,
              region_name: "冲山镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3566,
              parent_id: 136,
              region_name: "南圣镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3567,
              parent_id: 136,
              region_name: "毛阳镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3568,
              parent_id: 136,
              region_name: "番阳镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3569,
              parent_id: 136,
              region_name: "畅好乡",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3570,
              parent_id: 136,
              region_name: "毛道乡",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3571,
              parent_id: 136,
              region_name: "水满乡",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 137,
          parent_id: 9,
          region_name: "儋州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1058,
              parent_id: 137,
              region_name: "市区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1059,
              parent_id: 137,
              region_name: "洋浦开发区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1060,
              parent_id: 137,
              region_name: "那大镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1061,
              parent_id: 137,
              region_name: "王五镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1062,
              parent_id: 137,
              region_name: "雅星镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1063,
              parent_id: 137,
              region_name: "大成镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1064,
              parent_id: 137,
              region_name: "中和镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1065,
              parent_id: 137,
              region_name: "峨蔓镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1066,
              parent_id: 137,
              region_name: "南丰镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1067,
              parent_id: 137,
              region_name: "白马井镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1068,
              parent_id: 137,
              region_name: "兰洋镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1069,
              parent_id: 137,
              region_name: "和庆镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1070,
              parent_id: 137,
              region_name: "海头镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1071,
              parent_id: 137,
              region_name: "排浦镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1072,
              parent_id: 137,
              region_name: "东成镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1073,
              parent_id: 137,
              region_name: "光村镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1074,
              parent_id: 137,
              region_name: "木棠镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1075,
              parent_id: 137,
              region_name: "新州镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1076,
              parent_id: 137,
              region_name: "三都镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1077,
              parent_id: 137,
              region_name: "其他",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 10,
      parent_id: 1,
      region_name: "河北",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 138,
          parent_id: 10,
          region_name: "石家庄",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1078,
              parent_id: 138,
              region_name: "长安区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1079,
              parent_id: 138,
              region_name: "桥东区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1080,
              parent_id: 138,
              region_name: "桥西区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1081,
              parent_id: 138,
              region_name: "新华区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1082,
              parent_id: 138,
              region_name: "裕华区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1083,
              parent_id: 138,
              region_name: "井陉矿区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1084,
              parent_id: 138,
              region_name: "高新区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1085,
              parent_id: 138,
              region_name: "辛集市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1086,
              parent_id: 138,
              region_name: "藁城市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1087,
              parent_id: 138,
              region_name: "晋州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1088,
              parent_id: 138,
              region_name: "新乐市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1089,
              parent_id: 138,
              region_name: "鹿泉市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1090,
              parent_id: 138,
              region_name: "井陉县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1091,
              parent_id: 138,
              region_name: "正定县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1092,
              parent_id: 138,
              region_name: "栾城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1093,
              parent_id: 138,
              region_name: "行唐县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1094,
              parent_id: 138,
              region_name: "灵寿县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1095,
              parent_id: 138,
              region_name: "高邑县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1096,
              parent_id: 138,
              region_name: "深泽县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1097,
              parent_id: 138,
              region_name: "赞皇县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1098,
              parent_id: 138,
              region_name: "无极县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1099,
              parent_id: 138,
              region_name: "平山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1100,
              parent_id: 138,
              region_name: "元氏县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1101,
              parent_id: 138,
              region_name: "赵县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 139,
          parent_id: 10,
          region_name: "保定",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1102,
              parent_id: 139,
              region_name: "新市区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1103,
              parent_id: 139,
              region_name: "南市区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1104,
              parent_id: 139,
              region_name: "北市区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1105,
              parent_id: 139,
              region_name: "涿州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1106,
              parent_id: 139,
              region_name: "定州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1107,
              parent_id: 139,
              region_name: "安国市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1108,
              parent_id: 139,
              region_name: "高碑店市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1109,
              parent_id: 139,
              region_name: "满城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1110,
              parent_id: 139,
              region_name: "清苑县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1111,
              parent_id: 139,
              region_name: "涞水县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1112,
              parent_id: 139,
              region_name: "阜平县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1113,
              parent_id: 139,
              region_name: "徐水县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1114,
              parent_id: 139,
              region_name: "定兴县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1115,
              parent_id: 139,
              region_name: "唐县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1116,
              parent_id: 139,
              region_name: "高阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1117,
              parent_id: 139,
              region_name: "容城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1118,
              parent_id: 139,
              region_name: "涞源县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1119,
              parent_id: 139,
              region_name: "望都县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1120,
              parent_id: 139,
              region_name: "安新县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1121,
              parent_id: 139,
              region_name: "易县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1122,
              parent_id: 139,
              region_name: "曲阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1123,
              parent_id: 139,
              region_name: "蠡县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1124,
              parent_id: 139,
              region_name: "顺平县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1125,
              parent_id: 139,
              region_name: "博野县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1126,
              parent_id: 139,
              region_name: "雄县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 140,
          parent_id: 10,
          region_name: "沧州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1127,
              parent_id: 140,
              region_name: "运河区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1128,
              parent_id: 140,
              region_name: "新华区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1129,
              parent_id: 140,
              region_name: "泊头市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1130,
              parent_id: 140,
              region_name: "任丘市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1131,
              parent_id: 140,
              region_name: "黄骅市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1132,
              parent_id: 140,
              region_name: "河间市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1133,
              parent_id: 140,
              region_name: "沧县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1134,
              parent_id: 140,
              region_name: "青县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1135,
              parent_id: 140,
              region_name: "东光县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1136,
              parent_id: 140,
              region_name: "海兴县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1137,
              parent_id: 140,
              region_name: "盐山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1138,
              parent_id: 140,
              region_name: "肃宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1139,
              parent_id: 140,
              region_name: "南皮县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1140,
              parent_id: 140,
              region_name: "吴桥县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1141,
              parent_id: 140,
              region_name: "献县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1142,
              parent_id: 140,
              region_name: "孟村",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 141,
          parent_id: 10,
          region_name: "承德",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1143,
              parent_id: 141,
              region_name: "双桥区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1144,
              parent_id: 141,
              region_name: "双滦区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1145,
              parent_id: 141,
              region_name: "鹰手营子矿区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1146,
              parent_id: 141,
              region_name: "承德县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1147,
              parent_id: 141,
              region_name: "兴隆县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1148,
              parent_id: 141,
              region_name: "平泉县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1149,
              parent_id: 141,
              region_name: "滦平县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1150,
              parent_id: 141,
              region_name: "隆化县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1151,
              parent_id: 141,
              region_name: "丰宁",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1152,
              parent_id: 141,
              region_name: "宽城",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1153,
              parent_id: 141,
              region_name: "围场",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 142,
          parent_id: 10,
          region_name: "邯郸",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1154,
              parent_id: 142,
              region_name: "从台区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1155,
              parent_id: 142,
              region_name: "复兴区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1156,
              parent_id: 142,
              region_name: "邯山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1157,
              parent_id: 142,
              region_name: "峰峰矿区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1158,
              parent_id: 142,
              region_name: "武安市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1159,
              parent_id: 142,
              region_name: "邯郸县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1160,
              parent_id: 142,
              region_name: "临漳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1161,
              parent_id: 142,
              region_name: "成安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1162,
              parent_id: 142,
              region_name: "大名县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1163,
              parent_id: 142,
              region_name: "涉县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1164,
              parent_id: 142,
              region_name: "磁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1165,
              parent_id: 142,
              region_name: "肥乡县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1166,
              parent_id: 142,
              region_name: "永年县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1167,
              parent_id: 142,
              region_name: "邱县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1168,
              parent_id: 142,
              region_name: "鸡泽县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1169,
              parent_id: 142,
              region_name: "广平县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1170,
              parent_id: 142,
              region_name: "馆陶县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1171,
              parent_id: 142,
              region_name: "魏县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1172,
              parent_id: 142,
              region_name: "曲周县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 143,
          parent_id: 10,
          region_name: "衡水",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1173,
              parent_id: 143,
              region_name: "桃城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1174,
              parent_id: 143,
              region_name: "冀州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1175,
              parent_id: 143,
              region_name: "深州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1176,
              parent_id: 143,
              region_name: "枣强县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1177,
              parent_id: 143,
              region_name: "武邑县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1178,
              parent_id: 143,
              region_name: "武强县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1179,
              parent_id: 143,
              region_name: "饶阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1180,
              parent_id: 143,
              region_name: "安平县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1181,
              parent_id: 143,
              region_name: "故城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1182,
              parent_id: 143,
              region_name: "景县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1183,
              parent_id: 143,
              region_name: "阜城县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 144,
          parent_id: 10,
          region_name: "廊坊",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1184,
              parent_id: 144,
              region_name: "安次区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1185,
              parent_id: 144,
              region_name: "广阳区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1186,
              parent_id: 144,
              region_name: "霸州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1187,
              parent_id: 144,
              region_name: "三河市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1188,
              parent_id: 144,
              region_name: "固安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1189,
              parent_id: 144,
              region_name: "永清县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1190,
              parent_id: 144,
              region_name: "香河县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1191,
              parent_id: 144,
              region_name: "大城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1192,
              parent_id: 144,
              region_name: "文安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1193,
              parent_id: 144,
              region_name: "大厂",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 145,
          parent_id: 10,
          region_name: "秦皇岛",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1194,
              parent_id: 145,
              region_name: "海港区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1195,
              parent_id: 145,
              region_name: "山海关区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1196,
              parent_id: 145,
              region_name: "北戴河区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1197,
              parent_id: 145,
              region_name: "昌黎县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1198,
              parent_id: 145,
              region_name: "抚宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1199,
              parent_id: 145,
              region_name: "卢龙县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1200,
              parent_id: 145,
              region_name: "青龙",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 146,
          parent_id: 10,
          region_name: "唐山",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1201,
              parent_id: 146,
              region_name: "路北区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1202,
              parent_id: 146,
              region_name: "路南区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1203,
              parent_id: 146,
              region_name: "古冶区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1204,
              parent_id: 146,
              region_name: "开平区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1205,
              parent_id: 146,
              region_name: "丰南区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1206,
              parent_id: 146,
              region_name: "丰润区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1207,
              parent_id: 146,
              region_name: "遵化市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1208,
              parent_id: 146,
              region_name: "迁安市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1209,
              parent_id: 146,
              region_name: "滦县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1210,
              parent_id: 146,
              region_name: "滦南县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1211,
              parent_id: 146,
              region_name: "乐亭县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1212,
              parent_id: 146,
              region_name: "迁西县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1213,
              parent_id: 146,
              region_name: "玉田县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1214,
              parent_id: 146,
              region_name: "唐海县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 147,
          parent_id: 10,
          region_name: "邢台",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1215,
              parent_id: 147,
              region_name: "桥东区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1216,
              parent_id: 147,
              region_name: "桥西区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1217,
              parent_id: 147,
              region_name: "南宫市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1218,
              parent_id: 147,
              region_name: "沙河市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1219,
              parent_id: 147,
              region_name: "邢台县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1220,
              parent_id: 147,
              region_name: "临城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1221,
              parent_id: 147,
              region_name: "内丘县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1222,
              parent_id: 147,
              region_name: "柏乡县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1223,
              parent_id: 147,
              region_name: "隆尧县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1224,
              parent_id: 147,
              region_name: "任县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1225,
              parent_id: 147,
              region_name: "南和县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1226,
              parent_id: 147,
              region_name: "宁晋县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1227,
              parent_id: 147,
              region_name: "巨鹿县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1228,
              parent_id: 147,
              region_name: "新河县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1229,
              parent_id: 147,
              region_name: "广宗县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1230,
              parent_id: 147,
              region_name: "平乡县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1231,
              parent_id: 147,
              region_name: "威县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1232,
              parent_id: 147,
              region_name: "清河县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1233,
              parent_id: 147,
              region_name: "临西县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 148,
          parent_id: 10,
          region_name: "张家口",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1234,
              parent_id: 148,
              region_name: "桥西区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1235,
              parent_id: 148,
              region_name: "桥东区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1236,
              parent_id: 148,
              region_name: "宣化区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1237,
              parent_id: 148,
              region_name: "下花园区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1238,
              parent_id: 148,
              region_name: "宣化县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1239,
              parent_id: 148,
              region_name: "张北县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1240,
              parent_id: 148,
              region_name: "康保县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1241,
              parent_id: 148,
              region_name: "沽源县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1242,
              parent_id: 148,
              region_name: "尚义县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1243,
              parent_id: 148,
              region_name: "蔚县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1244,
              parent_id: 148,
              region_name: "阳原县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1245,
              parent_id: 148,
              region_name: "怀安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1246,
              parent_id: 148,
              region_name: "万全县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1247,
              parent_id: 148,
              region_name: "怀来县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1248,
              parent_id: 148,
              region_name: "涿鹿县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1249,
              parent_id: 148,
              region_name: "赤城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1250,
              parent_id: 148,
              region_name: "崇礼县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 11,
      parent_id: 1,
      region_name: "河南",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 149,
          parent_id: 11,
          region_name: "郑州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1251,
              parent_id: 149,
              region_name: "金水区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1252,
              parent_id: 149,
              region_name: "邙山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1253,
              parent_id: 149,
              region_name: "二七区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1254,
              parent_id: 149,
              region_name: "管城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1255,
              parent_id: 149,
              region_name: "中原区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1256,
              parent_id: 149,
              region_name: "上街区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1257,
              parent_id: 149,
              region_name: "惠济区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1258,
              parent_id: 149,
              region_name: "郑东新区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1259,
              parent_id: 149,
              region_name: "经济技术开发区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1260,
              parent_id: 149,
              region_name: "高新开发区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1261,
              parent_id: 149,
              region_name: "出口加工区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1262,
              parent_id: 149,
              region_name: "巩义市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1263,
              parent_id: 149,
              region_name: "荥阳市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1264,
              parent_id: 149,
              region_name: "新密市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1265,
              parent_id: 149,
              region_name: "新郑市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1266,
              parent_id: 149,
              region_name: "登封市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1267,
              parent_id: 149,
              region_name: "中牟县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 150,
          parent_id: 11,
          region_name: "洛阳",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1268,
              parent_id: 150,
              region_name: "西工区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1269,
              parent_id: 150,
              region_name: "老城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1270,
              parent_id: 150,
              region_name: "涧西区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1271,
              parent_id: 150,
              region_name: "瀍河回族区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1272,
              parent_id: 150,
              region_name: "洛龙区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1273,
              parent_id: 150,
              region_name: "吉利区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1274,
              parent_id: 150,
              region_name: "偃师市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1275,
              parent_id: 150,
              region_name: "孟津县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1276,
              parent_id: 150,
              region_name: "新安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1277,
              parent_id: 150,
              region_name: "栾川县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1278,
              parent_id: 150,
              region_name: "嵩县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1279,
              parent_id: 150,
              region_name: "汝阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1280,
              parent_id: 150,
              region_name: "宜阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1281,
              parent_id: 150,
              region_name: "洛宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1282,
              parent_id: 150,
              region_name: "伊川县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 151,
          parent_id: 11,
          region_name: "开封",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1283,
              parent_id: 151,
              region_name: "鼓楼区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1284,
              parent_id: 151,
              region_name: "龙亭区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1285,
              parent_id: 151,
              region_name: "顺河回族区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1286,
              parent_id: 151,
              region_name: "金明区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1287,
              parent_id: 151,
              region_name: "禹王台区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1288,
              parent_id: 151,
              region_name: "杞县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1289,
              parent_id: 151,
              region_name: "通许县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1290,
              parent_id: 151,
              region_name: "尉氏县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1291,
              parent_id: 151,
              region_name: "开封县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1292,
              parent_id: 151,
              region_name: "兰考县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 152,
          parent_id: 11,
          region_name: "安阳",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1293,
              parent_id: 152,
              region_name: "北关区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1294,
              parent_id: 152,
              region_name: "文峰区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1295,
              parent_id: 152,
              region_name: "殷都区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1296,
              parent_id: 152,
              region_name: "龙安区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1297,
              parent_id: 152,
              region_name: "林州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1298,
              parent_id: 152,
              region_name: "安阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1299,
              parent_id: 152,
              region_name: "汤阴县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1300,
              parent_id: 152,
              region_name: "滑县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1301,
              parent_id: 152,
              region_name: "内黄县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 153,
          parent_id: 11,
          region_name: "鹤壁",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1302,
              parent_id: 153,
              region_name: "淇滨区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1303,
              parent_id: 153,
              region_name: "山城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1304,
              parent_id: 153,
              region_name: "鹤山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1305,
              parent_id: 153,
              region_name: "浚县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1306,
              parent_id: 153,
              region_name: "淇县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 154,
          parent_id: 11,
          region_name: "济源",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1307,
              parent_id: 154,
              region_name: "济源市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 155,
          parent_id: 11,
          region_name: "焦作",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1308,
              parent_id: 155,
              region_name: "解放区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1309,
              parent_id: 155,
              region_name: "中站区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1310,
              parent_id: 155,
              region_name: "马村区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1311,
              parent_id: 155,
              region_name: "山阳区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1312,
              parent_id: 155,
              region_name: "沁阳市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1313,
              parent_id: 155,
              region_name: "孟州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1314,
              parent_id: 155,
              region_name: "修武县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1315,
              parent_id: 155,
              region_name: "博爱县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1316,
              parent_id: 155,
              region_name: "武陟县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1317,
              parent_id: 155,
              region_name: "温县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 156,
          parent_id: 11,
          region_name: "南阳",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1318,
              parent_id: 156,
              region_name: "卧龙区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1319,
              parent_id: 156,
              region_name: "宛城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1320,
              parent_id: 156,
              region_name: "邓州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1321,
              parent_id: 156,
              region_name: "南召县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1322,
              parent_id: 156,
              region_name: "方城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1323,
              parent_id: 156,
              region_name: "西峡县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1324,
              parent_id: 156,
              region_name: "镇平县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1325,
              parent_id: 156,
              region_name: "内乡县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1326,
              parent_id: 156,
              region_name: "淅川县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1327,
              parent_id: 156,
              region_name: "社旗县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1328,
              parent_id: 156,
              region_name: "唐河县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1329,
              parent_id: 156,
              region_name: "新野县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1330,
              parent_id: 156,
              region_name: "桐柏县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 157,
          parent_id: 11,
          region_name: "平顶山",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1331,
              parent_id: 157,
              region_name: "新华区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1332,
              parent_id: 157,
              region_name: "卫东区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1333,
              parent_id: 157,
              region_name: "湛河区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1334,
              parent_id: 157,
              region_name: "石龙区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1335,
              parent_id: 157,
              region_name: "舞钢市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1336,
              parent_id: 157,
              region_name: "汝州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1337,
              parent_id: 157,
              region_name: "宝丰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1338,
              parent_id: 157,
              region_name: "叶县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1339,
              parent_id: 157,
              region_name: "鲁山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1340,
              parent_id: 157,
              region_name: "郏县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 158,
          parent_id: 11,
          region_name: "三门峡",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1341,
              parent_id: 158,
              region_name: "湖滨区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1342,
              parent_id: 158,
              region_name: "义马市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1343,
              parent_id: 158,
              region_name: "灵宝市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1344,
              parent_id: 158,
              region_name: "渑池县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1345,
              parent_id: 158,
              region_name: "陕县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1346,
              parent_id: 158,
              region_name: "卢氏县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 159,
          parent_id: 11,
          region_name: "商丘",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1347,
              parent_id: 159,
              region_name: "梁园区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1348,
              parent_id: 159,
              region_name: "睢阳区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1349,
              parent_id: 159,
              region_name: "永城市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1350,
              parent_id: 159,
              region_name: "民权县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1351,
              parent_id: 159,
              region_name: "睢县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1352,
              parent_id: 159,
              region_name: "宁陵县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1353,
              parent_id: 159,
              region_name: "虞城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1354,
              parent_id: 159,
              region_name: "柘城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1355,
              parent_id: 159,
              region_name: "夏邑县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 160,
          parent_id: 11,
          region_name: "新乡",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1356,
              parent_id: 160,
              region_name: "卫滨区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1357,
              parent_id: 160,
              region_name: "红旗区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1358,
              parent_id: 160,
              region_name: "凤泉区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1359,
              parent_id: 160,
              region_name: "牧野区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1360,
              parent_id: 160,
              region_name: "卫辉市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1361,
              parent_id: 160,
              region_name: "辉县市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1362,
              parent_id: 160,
              region_name: "新乡县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1363,
              parent_id: 160,
              region_name: "获嘉县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1364,
              parent_id: 160,
              region_name: "原阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1365,
              parent_id: 160,
              region_name: "延津县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1366,
              parent_id: 160,
              region_name: "封丘县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1367,
              parent_id: 160,
              region_name: "长垣县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 161,
          parent_id: 11,
          region_name: "信阳",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1368,
              parent_id: 161,
              region_name: "浉河区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1369,
              parent_id: 161,
              region_name: "平桥区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1370,
              parent_id: 161,
              region_name: "罗山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1371,
              parent_id: 161,
              region_name: "光山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1372,
              parent_id: 161,
              region_name: "新县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1373,
              parent_id: 161,
              region_name: "商城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1374,
              parent_id: 161,
              region_name: "固始县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1375,
              parent_id: 161,
              region_name: "潢川县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1376,
              parent_id: 161,
              region_name: "淮滨县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1377,
              parent_id: 161,
              region_name: "息县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 162,
          parent_id: 11,
          region_name: "许昌",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1378,
              parent_id: 162,
              region_name: "魏都区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1379,
              parent_id: 162,
              region_name: "禹州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1380,
              parent_id: 162,
              region_name: "长葛市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1381,
              parent_id: 162,
              region_name: "许昌县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1382,
              parent_id: 162,
              region_name: "鄢陵县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1383,
              parent_id: 162,
              region_name: "襄城县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 163,
          parent_id: 11,
          region_name: "周口",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1384,
              parent_id: 163,
              region_name: "川汇区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1385,
              parent_id: 163,
              region_name: "项城市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1386,
              parent_id: 163,
              region_name: "扶沟县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1387,
              parent_id: 163,
              region_name: "西华县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1388,
              parent_id: 163,
              region_name: "商水县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1389,
              parent_id: 163,
              region_name: "沈丘县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1390,
              parent_id: 163,
              region_name: "郸城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1391,
              parent_id: 163,
              region_name: "淮阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1392,
              parent_id: 163,
              region_name: "太康县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1393,
              parent_id: 163,
              region_name: "鹿邑县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 164,
          parent_id: 11,
          region_name: "驻马店",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1394,
              parent_id: 164,
              region_name: "驿城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1395,
              parent_id: 164,
              region_name: "西平县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1396,
              parent_id: 164,
              region_name: "上蔡县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1397,
              parent_id: 164,
              region_name: "平舆县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1398,
              parent_id: 164,
              region_name: "正阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1399,
              parent_id: 164,
              region_name: "确山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1400,
              parent_id: 164,
              region_name: "泌阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1401,
              parent_id: 164,
              region_name: "汝南县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1402,
              parent_id: 164,
              region_name: "遂平县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1403,
              parent_id: 164,
              region_name: "新蔡县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 165,
          parent_id: 11,
          region_name: "漯河",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1404,
              parent_id: 165,
              region_name: "郾城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1405,
              parent_id: 165,
              region_name: "源汇区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1406,
              parent_id: 165,
              region_name: "召陵区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1407,
              parent_id: 165,
              region_name: "舞阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1408,
              parent_id: 165,
              region_name: "临颍县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 166,
          parent_id: 11,
          region_name: "濮阳",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1409,
              parent_id: 166,
              region_name: "华龙区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1410,
              parent_id: 166,
              region_name: "清丰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1411,
              parent_id: 166,
              region_name: "南乐县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1412,
              parent_id: 166,
              region_name: "范县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1413,
              parent_id: 166,
              region_name: "台前县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1414,
              parent_id: 166,
              region_name: "濮阳县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 12,
      parent_id: 1,
      region_name: "黑龙江",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 167,
          parent_id: 12,
          region_name: "哈尔滨",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1415,
              parent_id: 167,
              region_name: "道里区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1416,
              parent_id: 167,
              region_name: "南岗区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1417,
              parent_id: 167,
              region_name: "动力区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1418,
              parent_id: 167,
              region_name: "平房区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1419,
              parent_id: 167,
              region_name: "香坊区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1420,
              parent_id: 167,
              region_name: "太平区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1421,
              parent_id: 167,
              region_name: "道外区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1422,
              parent_id: 167,
              region_name: "阿城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1423,
              parent_id: 167,
              region_name: "呼兰区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1424,
              parent_id: 167,
              region_name: "松北区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1425,
              parent_id: 167,
              region_name: "尚志市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1426,
              parent_id: 167,
              region_name: "双城市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1427,
              parent_id: 167,
              region_name: "五常市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1428,
              parent_id: 167,
              region_name: "方正县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1429,
              parent_id: 167,
              region_name: "宾县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1430,
              parent_id: 167,
              region_name: "依兰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1431,
              parent_id: 167,
              region_name: "巴彦县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1432,
              parent_id: 167,
              region_name: "通河县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1433,
              parent_id: 167,
              region_name: "木兰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1434,
              parent_id: 167,
              region_name: "延寿县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 168,
          parent_id: 12,
          region_name: "大庆",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1435,
              parent_id: 168,
              region_name: "萨尔图区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1436,
              parent_id: 168,
              region_name: "红岗区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1437,
              parent_id: 168,
              region_name: "龙凤区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1438,
              parent_id: 168,
              region_name: "让胡路区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1439,
              parent_id: 168,
              region_name: "大同区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1440,
              parent_id: 168,
              region_name: "肇州县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1441,
              parent_id: 168,
              region_name: "肇源县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1442,
              parent_id: 168,
              region_name: "林甸县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1443,
              parent_id: 168,
              region_name: "杜尔伯特",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 169,
          parent_id: 12,
          region_name: "大兴安岭",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1444,
              parent_id: 169,
              region_name: "呼玛县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1445,
              parent_id: 169,
              region_name: "漠河县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1446,
              parent_id: 169,
              region_name: "塔河县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3572,
              parent_id: 169,
              region_name: "加格达奇区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3573,
              parent_id: 169,
              region_name: "松岭区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3574,
              parent_id: 169,
              region_name: "新林区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3575,
              parent_id: 169,
              region_name: "呼中区",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 170,
          parent_id: 12,
          region_name: "鹤岗",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1447,
              parent_id: 170,
              region_name: "兴山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1448,
              parent_id: 170,
              region_name: "工农区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1449,
              parent_id: 170,
              region_name: "南山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1450,
              parent_id: 170,
              region_name: "兴安区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1451,
              parent_id: 170,
              region_name: "向阳区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1452,
              parent_id: 170,
              region_name: "东山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1453,
              parent_id: 170,
              region_name: "萝北县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1454,
              parent_id: 170,
              region_name: "绥滨县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 171,
          parent_id: 12,
          region_name: "黑河",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1455,
              parent_id: 171,
              region_name: "爱辉区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1456,
              parent_id: 171,
              region_name: "五大连池市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1457,
              parent_id: 171,
              region_name: "北安市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1458,
              parent_id: 171,
              region_name: "嫩江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1459,
              parent_id: 171,
              region_name: "逊克县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1460,
              parent_id: 171,
              region_name: "孙吴县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 172,
          parent_id: 12,
          region_name: "鸡西",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1461,
              parent_id: 172,
              region_name: "鸡冠区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1462,
              parent_id: 172,
              region_name: "恒山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1463,
              parent_id: 172,
              region_name: "城子河区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1464,
              parent_id: 172,
              region_name: "滴道区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1465,
              parent_id: 172,
              region_name: "梨树区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1466,
              parent_id: 172,
              region_name: "虎林市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1467,
              parent_id: 172,
              region_name: "密山市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1468,
              parent_id: 172,
              region_name: "鸡东县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 173,
          parent_id: 12,
          region_name: "佳木斯",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1469,
              parent_id: 173,
              region_name: "前进区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1470,
              parent_id: 173,
              region_name: "郊区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1471,
              parent_id: 173,
              region_name: "向阳区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1472,
              parent_id: 173,
              region_name: "东风区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1473,
              parent_id: 173,
              region_name: "同江市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1474,
              parent_id: 173,
              region_name: "富锦市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1475,
              parent_id: 173,
              region_name: "桦南县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1476,
              parent_id: 173,
              region_name: "桦川县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1477,
              parent_id: 173,
              region_name: "汤原县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1478,
              parent_id: 173,
              region_name: "抚远县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 174,
          parent_id: 12,
          region_name: "牡丹江",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1479,
              parent_id: 174,
              region_name: "爱民区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1480,
              parent_id: 174,
              region_name: "东安区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1481,
              parent_id: 174,
              region_name: "阳明区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1482,
              parent_id: 174,
              region_name: "西安区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1483,
              parent_id: 174,
              region_name: "绥芬河市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1484,
              parent_id: 174,
              region_name: "海林市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1485,
              parent_id: 174,
              region_name: "宁安市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1486,
              parent_id: 174,
              region_name: "穆棱市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1487,
              parent_id: 174,
              region_name: "东宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1488,
              parent_id: 174,
              region_name: "林口县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 175,
          parent_id: 12,
          region_name: "七台河",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1489,
              parent_id: 175,
              region_name: "桃山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1490,
              parent_id: 175,
              region_name: "新兴区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1491,
              parent_id: 175,
              region_name: "茄子河区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1492,
              parent_id: 175,
              region_name: "勃利县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 176,
          parent_id: 12,
          region_name: "齐齐哈尔",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1493,
              parent_id: 176,
              region_name: "龙沙区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1494,
              parent_id: 176,
              region_name: "昂昂溪区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1495,
              parent_id: 176,
              region_name: "铁峰区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1496,
              parent_id: 176,
              region_name: "建华区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1497,
              parent_id: 176,
              region_name: "富拉尔基区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1498,
              parent_id: 176,
              region_name: "碾子山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1499,
              parent_id: 176,
              region_name: "梅里斯达斡尔区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1500,
              parent_id: 176,
              region_name: "讷河市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1501,
              parent_id: 176,
              region_name: "龙江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1502,
              parent_id: 176,
              region_name: "依安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1503,
              parent_id: 176,
              region_name: "泰来县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1504,
              parent_id: 176,
              region_name: "甘南县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1505,
              parent_id: 176,
              region_name: "富裕县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1506,
              parent_id: 176,
              region_name: "克山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1507,
              parent_id: 176,
              region_name: "克东县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1508,
              parent_id: 176,
              region_name: "拜泉县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 177,
          parent_id: 12,
          region_name: "双鸭山",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1509,
              parent_id: 177,
              region_name: "尖山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1510,
              parent_id: 177,
              region_name: "岭东区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1511,
              parent_id: 177,
              region_name: "四方台区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1512,
              parent_id: 177,
              region_name: "宝山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1513,
              parent_id: 177,
              region_name: "集贤县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1514,
              parent_id: 177,
              region_name: "友谊县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1515,
              parent_id: 177,
              region_name: "宝清县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1516,
              parent_id: 177,
              region_name: "饶河县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 178,
          parent_id: 12,
          region_name: "绥化",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1517,
              parent_id: 178,
              region_name: "北林区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1518,
              parent_id: 178,
              region_name: "安达市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1519,
              parent_id: 178,
              region_name: "肇东市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1520,
              parent_id: 178,
              region_name: "海伦市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1521,
              parent_id: 178,
              region_name: "望奎县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1522,
              parent_id: 178,
              region_name: "兰西县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1523,
              parent_id: 178,
              region_name: "青冈县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1524,
              parent_id: 178,
              region_name: "庆安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1525,
              parent_id: 178,
              region_name: "明水县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1526,
              parent_id: 178,
              region_name: "绥棱县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 179,
          parent_id: 12,
          region_name: "伊春",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1527,
              parent_id: 179,
              region_name: "伊春区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1528,
              parent_id: 179,
              region_name: "带岭区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1529,
              parent_id: 179,
              region_name: "南岔区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1530,
              parent_id: 179,
              region_name: "金山屯区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1531,
              parent_id: 179,
              region_name: "西林区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1532,
              parent_id: 179,
              region_name: "美溪区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1533,
              parent_id: 179,
              region_name: "乌马河区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1534,
              parent_id: 179,
              region_name: "翠峦区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1535,
              parent_id: 179,
              region_name: "友好区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1536,
              parent_id: 179,
              region_name: "上甘岭区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1537,
              parent_id: 179,
              region_name: "五营区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1538,
              parent_id: 179,
              region_name: "红星区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1539,
              parent_id: 179,
              region_name: "新青区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1540,
              parent_id: 179,
              region_name: "汤旺河区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1541,
              parent_id: 179,
              region_name: "乌伊岭区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1542,
              parent_id: 179,
              region_name: "铁力市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1543,
              parent_id: 179,
              region_name: "嘉荫县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 13,
      parent_id: 1,
      region_name: "湖北",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 180,
          parent_id: 13,
          region_name: "武汉",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1544,
              parent_id: 180,
              region_name: "江岸区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1545,
              parent_id: 180,
              region_name: "武昌区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1546,
              parent_id: 180,
              region_name: "江汉区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1547,
              parent_id: 180,
              region_name: "硚口区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1548,
              parent_id: 180,
              region_name: "汉阳区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1549,
              parent_id: 180,
              region_name: "青山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1550,
              parent_id: 180,
              region_name: "洪山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1551,
              parent_id: 180,
              region_name: "东西湖区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1552,
              parent_id: 180,
              region_name: "汉南区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1553,
              parent_id: 180,
              region_name: "蔡甸区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1554,
              parent_id: 180,
              region_name: "江夏区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1555,
              parent_id: 180,
              region_name: "黄陂区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1556,
              parent_id: 180,
              region_name: "新洲区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1557,
              parent_id: 180,
              region_name: "经济开发区",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 181,
          parent_id: 13,
          region_name: "仙桃",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1558,
              parent_id: 181,
              region_name: "仙桃市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 182,
          parent_id: 13,
          region_name: "鄂州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1559,
              parent_id: 182,
              region_name: "鄂城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1560,
              parent_id: 182,
              region_name: "华容区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1561,
              parent_id: 182,
              region_name: "梁子湖区",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 183,
          parent_id: 13,
          region_name: "黄冈",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1562,
              parent_id: 183,
              region_name: "黄州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1563,
              parent_id: 183,
              region_name: "麻城市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1564,
              parent_id: 183,
              region_name: "武穴市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1565,
              parent_id: 183,
              region_name: "团风县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1566,
              parent_id: 183,
              region_name: "红安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1567,
              parent_id: 183,
              region_name: "罗田县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1568,
              parent_id: 183,
              region_name: "英山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1569,
              parent_id: 183,
              region_name: "浠水县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1570,
              parent_id: 183,
              region_name: "蕲春县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1571,
              parent_id: 183,
              region_name: "黄梅县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 184,
          parent_id: 13,
          region_name: "黄石",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1572,
              parent_id: 184,
              region_name: "黄石港区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1573,
              parent_id: 184,
              region_name: "西塞山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1574,
              parent_id: 184,
              region_name: "下陆区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1575,
              parent_id: 184,
              region_name: "铁山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1576,
              parent_id: 184,
              region_name: "大冶市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1577,
              parent_id: 184,
              region_name: "阳新县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 185,
          parent_id: 13,
          region_name: "荆门",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1578,
              parent_id: 185,
              region_name: "东宝区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1579,
              parent_id: 185,
              region_name: "掇刀区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1580,
              parent_id: 185,
              region_name: "钟祥市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1581,
              parent_id: 185,
              region_name: "京山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1582,
              parent_id: 185,
              region_name: "沙洋县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 186,
          parent_id: 13,
          region_name: "荆州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1583,
              parent_id: 186,
              region_name: "沙市区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1584,
              parent_id: 186,
              region_name: "荆州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1585,
              parent_id: 186,
              region_name: "石首市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1586,
              parent_id: 186,
              region_name: "洪湖市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1587,
              parent_id: 186,
              region_name: "松滋市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1588,
              parent_id: 186,
              region_name: "公安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1589,
              parent_id: 186,
              region_name: "监利县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1590,
              parent_id: 186,
              region_name: "江陵县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 187,
          parent_id: 13,
          region_name: "潜江",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1591,
              parent_id: 187,
              region_name: "潜江市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 188,
          parent_id: 13,
          region_name: "神农架林区",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1592,
              parent_id: 188,
              region_name: "神农架林区",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 189,
          parent_id: 13,
          region_name: "十堰",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1593,
              parent_id: 189,
              region_name: "张湾区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1594,
              parent_id: 189,
              region_name: "茅箭区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1595,
              parent_id: 189,
              region_name: "丹江口市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1596,
              parent_id: 189,
              region_name: "郧县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1597,
              parent_id: 189,
              region_name: "郧西县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1598,
              parent_id: 189,
              region_name: "竹山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1599,
              parent_id: 189,
              region_name: "竹溪县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1600,
              parent_id: 189,
              region_name: "房县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 190,
          parent_id: 13,
          region_name: "随州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1601,
              parent_id: 190,
              region_name: "曾都区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1602,
              parent_id: 190,
              region_name: "广水市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 191,
          parent_id: 13,
          region_name: "天门",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1603,
              parent_id: 191,
              region_name: "天门市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 192,
          parent_id: 13,
          region_name: "咸宁",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1604,
              parent_id: 192,
              region_name: "咸安区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1605,
              parent_id: 192,
              region_name: "赤壁市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1606,
              parent_id: 192,
              region_name: "嘉鱼县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1607,
              parent_id: 192,
              region_name: "通城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1608,
              parent_id: 192,
              region_name: "崇阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1609,
              parent_id: 192,
              region_name: "通山县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 193,
          parent_id: 13,
          region_name: "襄樊",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1610,
              parent_id: 193,
              region_name: "襄城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1611,
              parent_id: 193,
              region_name: "樊城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1612,
              parent_id: 193,
              region_name: "襄阳区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1613,
              parent_id: 193,
              region_name: "老河口市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1614,
              parent_id: 193,
              region_name: "枣阳市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1615,
              parent_id: 193,
              region_name: "宜城市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1616,
              parent_id: 193,
              region_name: "南漳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1617,
              parent_id: 193,
              region_name: "谷城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1618,
              parent_id: 193,
              region_name: "保康县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 194,
          parent_id: 13,
          region_name: "孝感",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1619,
              parent_id: 194,
              region_name: "孝南区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1620,
              parent_id: 194,
              region_name: "应城市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1621,
              parent_id: 194,
              region_name: "安陆市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1622,
              parent_id: 194,
              region_name: "汉川市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1623,
              parent_id: 194,
              region_name: "孝昌县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1624,
              parent_id: 194,
              region_name: "大悟县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1625,
              parent_id: 194,
              region_name: "云梦县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 195,
          parent_id: 13,
          region_name: "宜昌",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1626,
              parent_id: 195,
              region_name: "长阳",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1627,
              parent_id: 195,
              region_name: "五峰",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1628,
              parent_id: 195,
              region_name: "西陵区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1629,
              parent_id: 195,
              region_name: "伍家岗区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1630,
              parent_id: 195,
              region_name: "点军区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1631,
              parent_id: 195,
              region_name: "猇亭区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1632,
              parent_id: 195,
              region_name: "夷陵区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1633,
              parent_id: 195,
              region_name: "宜都市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1634,
              parent_id: 195,
              region_name: "当阳市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1635,
              parent_id: 195,
              region_name: "枝江市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1636,
              parent_id: 195,
              region_name: "远安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1637,
              parent_id: 195,
              region_name: "兴山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1638,
              parent_id: 195,
              region_name: "秭归县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 196,
          parent_id: 13,
          region_name: "恩施",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1639,
              parent_id: 196,
              region_name: "恩施市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1640,
              parent_id: 196,
              region_name: "利川市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1641,
              parent_id: 196,
              region_name: "建始县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1642,
              parent_id: 196,
              region_name: "巴东县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1643,
              parent_id: 196,
              region_name: "宣恩县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1644,
              parent_id: 196,
              region_name: "咸丰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1645,
              parent_id: 196,
              region_name: "来凤县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1646,
              parent_id: 196,
              region_name: "鹤峰县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 14,
      parent_id: 1,
      region_name: "湖南",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 197,
          parent_id: 14,
          region_name: "长沙",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1647,
              parent_id: 197,
              region_name: "岳麓区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1648,
              parent_id: 197,
              region_name: "芙蓉区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1649,
              parent_id: 197,
              region_name: "天心区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1650,
              parent_id: 197,
              region_name: "开福区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1651,
              parent_id: 197,
              region_name: "雨花区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1652,
              parent_id: 197,
              region_name: "开发区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1653,
              parent_id: 197,
              region_name: "浏阳市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1654,
              parent_id: 197,
              region_name: "长沙县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1655,
              parent_id: 197,
              region_name: "望城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1656,
              parent_id: 197,
              region_name: "宁乡县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 198,
          parent_id: 14,
          region_name: "张家界",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1657,
              parent_id: 198,
              region_name: "永定区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1658,
              parent_id: 198,
              region_name: "武陵源区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1659,
              parent_id: 198,
              region_name: "慈利县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1660,
              parent_id: 198,
              region_name: "桑植县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 199,
          parent_id: 14,
          region_name: "常德",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1661,
              parent_id: 199,
              region_name: "武陵区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1662,
              parent_id: 199,
              region_name: "鼎城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1663,
              parent_id: 199,
              region_name: "津市市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1664,
              parent_id: 199,
              region_name: "安乡县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1665,
              parent_id: 199,
              region_name: "汉寿县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1666,
              parent_id: 199,
              region_name: "澧县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1667,
              parent_id: 199,
              region_name: "临澧县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1668,
              parent_id: 199,
              region_name: "桃源县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1669,
              parent_id: 199,
              region_name: "石门县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 200,
          parent_id: 14,
          region_name: "郴州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1670,
              parent_id: 200,
              region_name: "北湖区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1671,
              parent_id: 200,
              region_name: "苏仙区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1672,
              parent_id: 200,
              region_name: "资兴市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1673,
              parent_id: 200,
              region_name: "桂阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1674,
              parent_id: 200,
              region_name: "宜章县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1675,
              parent_id: 200,
              region_name: "永兴县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1676,
              parent_id: 200,
              region_name: "嘉禾县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1677,
              parent_id: 200,
              region_name: "临武县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1678,
              parent_id: 200,
              region_name: "汝城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1679,
              parent_id: 200,
              region_name: "桂东县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1680,
              parent_id: 200,
              region_name: "安仁县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 201,
          parent_id: 14,
          region_name: "衡阳",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1681,
              parent_id: 201,
              region_name: "雁峰区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1682,
              parent_id: 201,
              region_name: "珠晖区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1683,
              parent_id: 201,
              region_name: "石鼓区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1684,
              parent_id: 201,
              region_name: "蒸湘区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1685,
              parent_id: 201,
              region_name: "南岳区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1686,
              parent_id: 201,
              region_name: "耒阳市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1687,
              parent_id: 201,
              region_name: "常宁市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1688,
              parent_id: 201,
              region_name: "衡阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1689,
              parent_id: 201,
              region_name: "衡南县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1690,
              parent_id: 201,
              region_name: "衡山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1691,
              parent_id: 201,
              region_name: "衡东县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1692,
              parent_id: 201,
              region_name: "祁东县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 202,
          parent_id: 14,
          region_name: "怀化",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1693,
              parent_id: 202,
              region_name: "鹤城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1694,
              parent_id: 202,
              region_name: "靖州",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1695,
              parent_id: 202,
              region_name: "麻阳",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1696,
              parent_id: 202,
              region_name: "通道",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1697,
              parent_id: 202,
              region_name: "新晃",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1698,
              parent_id: 202,
              region_name: "芷江",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1699,
              parent_id: 202,
              region_name: "沅陵县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1700,
              parent_id: 202,
              region_name: "辰溪县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1701,
              parent_id: 202,
              region_name: "溆浦县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1702,
              parent_id: 202,
              region_name: "中方县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1703,
              parent_id: 202,
              region_name: "会同县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1704,
              parent_id: 202,
              region_name: "洪江市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 203,
          parent_id: 14,
          region_name: "娄底",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1705,
              parent_id: 203,
              region_name: "娄星区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1706,
              parent_id: 203,
              region_name: "冷水江市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1707,
              parent_id: 203,
              region_name: "涟源市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1708,
              parent_id: 203,
              region_name: "双峰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1709,
              parent_id: 203,
              region_name: "新化县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 204,
          parent_id: 14,
          region_name: "邵阳",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1710,
              parent_id: 204,
              region_name: "城步",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1711,
              parent_id: 204,
              region_name: "双清区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1712,
              parent_id: 204,
              region_name: "大祥区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1713,
              parent_id: 204,
              region_name: "北塔区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1714,
              parent_id: 204,
              region_name: "武冈市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1715,
              parent_id: 204,
              region_name: "邵东县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1716,
              parent_id: 204,
              region_name: "新邵县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1717,
              parent_id: 204,
              region_name: "邵阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1718,
              parent_id: 204,
              region_name: "隆回县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1719,
              parent_id: 204,
              region_name: "洞口县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1720,
              parent_id: 204,
              region_name: "绥宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1721,
              parent_id: 204,
              region_name: "新宁县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 205,
          parent_id: 14,
          region_name: "湘潭",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1722,
              parent_id: 205,
              region_name: "岳塘区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1723,
              parent_id: 205,
              region_name: "雨湖区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1724,
              parent_id: 205,
              region_name: "湘乡市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1725,
              parent_id: 205,
              region_name: "韶山市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1726,
              parent_id: 205,
              region_name: "湘潭县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 206,
          parent_id: 14,
          region_name: "湘西",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1727,
              parent_id: 206,
              region_name: "吉首市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1728,
              parent_id: 206,
              region_name: "泸溪县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1729,
              parent_id: 206,
              region_name: "凤凰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1730,
              parent_id: 206,
              region_name: "花垣县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1731,
              parent_id: 206,
              region_name: "保靖县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1732,
              parent_id: 206,
              region_name: "古丈县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1733,
              parent_id: 206,
              region_name: "永顺县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1734,
              parent_id: 206,
              region_name: "龙山县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 207,
          parent_id: 14,
          region_name: "益阳",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1735,
              parent_id: 207,
              region_name: "赫山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1736,
              parent_id: 207,
              region_name: "资阳区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1737,
              parent_id: 207,
              region_name: "沅江市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1738,
              parent_id: 207,
              region_name: "南县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1739,
              parent_id: 207,
              region_name: "桃江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1740,
              parent_id: 207,
              region_name: "安化县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 208,
          parent_id: 14,
          region_name: "永州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1741,
              parent_id: 208,
              region_name: "江华",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1742,
              parent_id: 208,
              region_name: "冷水滩区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1743,
              parent_id: 208,
              region_name: "零陵区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1744,
              parent_id: 208,
              region_name: "祁阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1745,
              parent_id: 208,
              region_name: "东安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1746,
              parent_id: 208,
              region_name: "双牌县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1747,
              parent_id: 208,
              region_name: "道县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1748,
              parent_id: 208,
              region_name: "江永县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1749,
              parent_id: 208,
              region_name: "宁远县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1750,
              parent_id: 208,
              region_name: "蓝山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1751,
              parent_id: 208,
              region_name: "新田县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 209,
          parent_id: 14,
          region_name: "岳阳",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1752,
              parent_id: 209,
              region_name: "岳阳楼区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1753,
              parent_id: 209,
              region_name: "君山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1754,
              parent_id: 209,
              region_name: "云溪区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1755,
              parent_id: 209,
              region_name: "汨罗市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1756,
              parent_id: 209,
              region_name: "临湘市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1757,
              parent_id: 209,
              region_name: "岳阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1758,
              parent_id: 209,
              region_name: "华容县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1759,
              parent_id: 209,
              region_name: "湘阴县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1760,
              parent_id: 209,
              region_name: "平江县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 210,
          parent_id: 14,
          region_name: "株洲",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1761,
              parent_id: 210,
              region_name: "天元区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1762,
              parent_id: 210,
              region_name: "荷塘区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1763,
              parent_id: 210,
              region_name: "芦淞区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1764,
              parent_id: 210,
              region_name: "石峰区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1765,
              parent_id: 210,
              region_name: "醴陵市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1766,
              parent_id: 210,
              region_name: "株洲县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1767,
              parent_id: 210,
              region_name: "攸县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1768,
              parent_id: 210,
              region_name: "茶陵县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1769,
              parent_id: 210,
              region_name: "炎陵县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 15,
      parent_id: 1,
      region_name: "吉林",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 211,
          parent_id: 15,
          region_name: "长春",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1770,
              parent_id: 211,
              region_name: "朝阳区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1771,
              parent_id: 211,
              region_name: "宽城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1772,
              parent_id: 211,
              region_name: "二道区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1773,
              parent_id: 211,
              region_name: "南关区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1774,
              parent_id: 211,
              region_name: "绿园区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1775,
              parent_id: 211,
              region_name: "双阳区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1776,
              parent_id: 211,
              region_name: "净月潭开发区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1777,
              parent_id: 211,
              region_name: "高新技术开发区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1778,
              parent_id: 211,
              region_name: "经济技术开发区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1779,
              parent_id: 211,
              region_name: "汽车产业开发区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1780,
              parent_id: 211,
              region_name: "德惠市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1781,
              parent_id: 211,
              region_name: "九台市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1782,
              parent_id: 211,
              region_name: "榆树市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1783,
              parent_id: 211,
              region_name: "农安县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 212,
          parent_id: 15,
          region_name: "吉林",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1784,
              parent_id: 212,
              region_name: "船营区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1785,
              parent_id: 212,
              region_name: "昌邑区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1786,
              parent_id: 212,
              region_name: "龙潭区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1787,
              parent_id: 212,
              region_name: "丰满区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1788,
              parent_id: 212,
              region_name: "蛟河市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1789,
              parent_id: 212,
              region_name: "桦甸市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1790,
              parent_id: 212,
              region_name: "舒兰市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1791,
              parent_id: 212,
              region_name: "磐石市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1792,
              parent_id: 212,
              region_name: "永吉县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 213,
          parent_id: 15,
          region_name: "白城",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1793,
              parent_id: 213,
              region_name: "洮北区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1794,
              parent_id: 213,
              region_name: "洮南市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1795,
              parent_id: 213,
              region_name: "大安市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1796,
              parent_id: 213,
              region_name: "镇赉县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1797,
              parent_id: 213,
              region_name: "通榆县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 214,
          parent_id: 15,
          region_name: "白山",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1798,
              parent_id: 214,
              region_name: "江源区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1799,
              parent_id: 214,
              region_name: "八道江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1800,
              parent_id: 214,
              region_name: "长白",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1801,
              parent_id: 214,
              region_name: "临江市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1802,
              parent_id: 214,
              region_name: "抚松县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1803,
              parent_id: 214,
              region_name: "靖宇县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 215,
          parent_id: 15,
          region_name: "辽源",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1804,
              parent_id: 215,
              region_name: "龙山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1805,
              parent_id: 215,
              region_name: "西安区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1806,
              parent_id: 215,
              region_name: "东丰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1807,
              parent_id: 215,
              region_name: "东辽县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 216,
          parent_id: 15,
          region_name: "四平",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1808,
              parent_id: 216,
              region_name: "铁西区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1809,
              parent_id: 216,
              region_name: "铁东区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1810,
              parent_id: 216,
              region_name: "伊通",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1811,
              parent_id: 216,
              region_name: "公主岭市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1812,
              parent_id: 216,
              region_name: "双辽市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1813,
              parent_id: 216,
              region_name: "梨树县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 217,
          parent_id: 15,
          region_name: "松原",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1814,
              parent_id: 217,
              region_name: "前郭尔罗斯",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1815,
              parent_id: 217,
              region_name: "宁江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1816,
              parent_id: 217,
              region_name: "长岭县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1817,
              parent_id: 217,
              region_name: "乾安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1818,
              parent_id: 217,
              region_name: "扶余县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 218,
          parent_id: 15,
          region_name: "通化",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1819,
              parent_id: 218,
              region_name: "东昌区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1820,
              parent_id: 218,
              region_name: "二道江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1821,
              parent_id: 218,
              region_name: "梅河口市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1822,
              parent_id: 218,
              region_name: "集安市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1823,
              parent_id: 218,
              region_name: "通化县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1824,
              parent_id: 218,
              region_name: "辉南县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1825,
              parent_id: 218,
              region_name: "柳河县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 219,
          parent_id: 15,
          region_name: "延边",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1826,
              parent_id: 219,
              region_name: "延吉市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1827,
              parent_id: 219,
              region_name: "图们市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1828,
              parent_id: 219,
              region_name: "敦化市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1829,
              parent_id: 219,
              region_name: "珲春市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1830,
              parent_id: 219,
              region_name: "龙井市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1831,
              parent_id: 219,
              region_name: "和龙市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1832,
              parent_id: 219,
              region_name: "安图县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1833,
              parent_id: 219,
              region_name: "汪清县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 16,
      parent_id: 1,
      region_name: "江苏",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 220,
          parent_id: 16,
          region_name: "南京",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1834,
              parent_id: 220,
              region_name: "玄武区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1835,
              parent_id: 220,
              region_name: "鼓楼区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1836,
              parent_id: 220,
              region_name: "白下区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1837,
              parent_id: 220,
              region_name: "建邺区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1838,
              parent_id: 220,
              region_name: "秦淮区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1839,
              parent_id: 220,
              region_name: "雨花台区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1840,
              parent_id: 220,
              region_name: "下关区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1841,
              parent_id: 220,
              region_name: "栖霞区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1842,
              parent_id: 220,
              region_name: "浦口区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1843,
              parent_id: 220,
              region_name: "江宁区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1844,
              parent_id: 220,
              region_name: "六合区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1845,
              parent_id: 220,
              region_name: "溧水县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1846,
              parent_id: 220,
              region_name: "高淳县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 221,
          parent_id: 16,
          region_name: "苏州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1847,
              parent_id: 221,
              region_name: "沧浪区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1848,
              parent_id: 221,
              region_name: "金阊区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1849,
              parent_id: 221,
              region_name: "平江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1850,
              parent_id: 221,
              region_name: "虎丘区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1851,
              parent_id: 221,
              region_name: "吴中区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1852,
              parent_id: 221,
              region_name: "相城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1853,
              parent_id: 221,
              region_name: "园区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1854,
              parent_id: 221,
              region_name: "新区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1855,
              parent_id: 221,
              region_name: "常熟市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1856,
              parent_id: 221,
              region_name: "张家港市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1857,
              parent_id: 221,
              region_name: "玉山镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1858,
              parent_id: 221,
              region_name: "巴城镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1859,
              parent_id: 221,
              region_name: "周市镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1860,
              parent_id: 221,
              region_name: "陆家镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1861,
              parent_id: 221,
              region_name: "花桥镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1862,
              parent_id: 221,
              region_name: "淀山湖镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1863,
              parent_id: 221,
              region_name: "张浦镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1864,
              parent_id: 221,
              region_name: "周庄镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1865,
              parent_id: 221,
              region_name: "千灯镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1866,
              parent_id: 221,
              region_name: "锦溪镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1867,
              parent_id: 221,
              region_name: "开发区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1868,
              parent_id: 221,
              region_name: "吴江市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1869,
              parent_id: 221,
              region_name: "太仓市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 222,
          parent_id: 16,
          region_name: "无锡",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1870,
              parent_id: 222,
              region_name: "崇安区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1871,
              parent_id: 222,
              region_name: "北塘区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1872,
              parent_id: 222,
              region_name: "南长区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1873,
              parent_id: 222,
              region_name: "锡山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1874,
              parent_id: 222,
              region_name: "惠山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1875,
              parent_id: 222,
              region_name: "滨湖区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1876,
              parent_id: 222,
              region_name: "新区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1877,
              parent_id: 222,
              region_name: "江阴市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1878,
              parent_id: 222,
              region_name: "宜兴市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 223,
          parent_id: 16,
          region_name: "常州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1879,
              parent_id: 223,
              region_name: "天宁区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1880,
              parent_id: 223,
              region_name: "钟楼区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1881,
              parent_id: 223,
              region_name: "戚墅堰区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1882,
              parent_id: 223,
              region_name: "郊区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1883,
              parent_id: 223,
              region_name: "新北区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1884,
              parent_id: 223,
              region_name: "武进区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1885,
              parent_id: 223,
              region_name: "溧阳市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1886,
              parent_id: 223,
              region_name: "金坛市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 224,
          parent_id: 16,
          region_name: "淮安",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1887,
              parent_id: 224,
              region_name: "清河区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1888,
              parent_id: 224,
              region_name: "清浦区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1889,
              parent_id: 224,
              region_name: "楚州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1890,
              parent_id: 224,
              region_name: "淮阴区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1891,
              parent_id: 224,
              region_name: "涟水县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1892,
              parent_id: 224,
              region_name: "洪泽县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1893,
              parent_id: 224,
              region_name: "盱眙县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1894,
              parent_id: 224,
              region_name: "金湖县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 225,
          parent_id: 16,
          region_name: "连云港",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1895,
              parent_id: 225,
              region_name: "新浦区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1896,
              parent_id: 225,
              region_name: "连云区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1897,
              parent_id: 225,
              region_name: "海州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1898,
              parent_id: 225,
              region_name: "赣榆县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1899,
              parent_id: 225,
              region_name: "东海县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1900,
              parent_id: 225,
              region_name: "灌云县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1901,
              parent_id: 225,
              region_name: "灌南县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 226,
          parent_id: 16,
          region_name: "南通",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1902,
              parent_id: 226,
              region_name: "崇川区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1903,
              parent_id: 226,
              region_name: "港闸区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1904,
              parent_id: 226,
              region_name: "经济开发区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1905,
              parent_id: 226,
              region_name: "启东市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1906,
              parent_id: 226,
              region_name: "如皋市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1907,
              parent_id: 226,
              region_name: "通州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1908,
              parent_id: 226,
              region_name: "海门市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1909,
              parent_id: 226,
              region_name: "海安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1910,
              parent_id: 226,
              region_name: "如东县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 227,
          parent_id: 16,
          region_name: "宿迁",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1911,
              parent_id: 227,
              region_name: "宿城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1912,
              parent_id: 227,
              region_name: "宿豫区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1913,
              parent_id: 227,
              region_name: "宿豫县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1914,
              parent_id: 227,
              region_name: "沭阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1915,
              parent_id: 227,
              region_name: "泗阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1916,
              parent_id: 227,
              region_name: "泗洪县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 228,
          parent_id: 16,
          region_name: "泰州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1917,
              parent_id: 228,
              region_name: "海陵区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1918,
              parent_id: 228,
              region_name: "高港区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1919,
              parent_id: 228,
              region_name: "兴化市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1920,
              parent_id: 228,
              region_name: "靖江市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1921,
              parent_id: 228,
              region_name: "泰兴市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1922,
              parent_id: 228,
              region_name: "姜堰市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 229,
          parent_id: 16,
          region_name: "徐州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1923,
              parent_id: 229,
              region_name: "云龙区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1924,
              parent_id: 229,
              region_name: "鼓楼区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1925,
              parent_id: 229,
              region_name: "九里区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1926,
              parent_id: 229,
              region_name: "贾汪区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1927,
              parent_id: 229,
              region_name: "泉山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1928,
              parent_id: 229,
              region_name: "新沂市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1929,
              parent_id: 229,
              region_name: "邳州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1930,
              parent_id: 229,
              region_name: "丰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1931,
              parent_id: 229,
              region_name: "沛县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1932,
              parent_id: 229,
              region_name: "铜山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1933,
              parent_id: 229,
              region_name: "睢宁县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 230,
          parent_id: 16,
          region_name: "盐城",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1934,
              parent_id: 230,
              region_name: "城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1935,
              parent_id: 230,
              region_name: "亭湖区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1936,
              parent_id: 230,
              region_name: "盐都区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1937,
              parent_id: 230,
              region_name: "盐都县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1938,
              parent_id: 230,
              region_name: "东台市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1939,
              parent_id: 230,
              region_name: "大丰市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1940,
              parent_id: 230,
              region_name: "响水县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1941,
              parent_id: 230,
              region_name: "滨海县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1942,
              parent_id: 230,
              region_name: "阜宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1943,
              parent_id: 230,
              region_name: "射阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1944,
              parent_id: 230,
              region_name: "建湖县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 231,
          parent_id: 16,
          region_name: "扬州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1945,
              parent_id: 231,
              region_name: "广陵区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1946,
              parent_id: 231,
              region_name: "维扬区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1947,
              parent_id: 231,
              region_name: "邗江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1948,
              parent_id: 231,
              region_name: "仪征市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1949,
              parent_id: 231,
              region_name: "高邮市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1950,
              parent_id: 231,
              region_name: "江都市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1951,
              parent_id: 231,
              region_name: "宝应县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 232,
          parent_id: 16,
          region_name: "镇江",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1952,
              parent_id: 232,
              region_name: "京口区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1953,
              parent_id: 232,
              region_name: "润州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1954,
              parent_id: 232,
              region_name: "丹徒区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1955,
              parent_id: 232,
              region_name: "丹阳市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1956,
              parent_id: 232,
              region_name: "扬中市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1957,
              parent_id: 232,
              region_name: "句容市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 17,
      parent_id: 1,
      region_name: "江西",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 233,
          parent_id: 17,
          region_name: "南昌",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1958,
              parent_id: 233,
              region_name: "东湖区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1959,
              parent_id: 233,
              region_name: "西湖区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1960,
              parent_id: 233,
              region_name: "青云谱区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1961,
              parent_id: 233,
              region_name: "湾里区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1962,
              parent_id: 233,
              region_name: "青山湖区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1963,
              parent_id: 233,
              region_name: "红谷滩新区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1964,
              parent_id: 233,
              region_name: "昌北区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1965,
              parent_id: 233,
              region_name: "高新区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1966,
              parent_id: 233,
              region_name: "南昌县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1967,
              parent_id: 233,
              region_name: "新建县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1968,
              parent_id: 233,
              region_name: "安义县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1969,
              parent_id: 233,
              region_name: "进贤县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 234,
          parent_id: 17,
          region_name: "抚州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1970,
              parent_id: 234,
              region_name: "临川区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1971,
              parent_id: 234,
              region_name: "南城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1972,
              parent_id: 234,
              region_name: "黎川县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1973,
              parent_id: 234,
              region_name: "南丰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1974,
              parent_id: 234,
              region_name: "崇仁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1975,
              parent_id: 234,
              region_name: "乐安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1976,
              parent_id: 234,
              region_name: "宜黄县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1977,
              parent_id: 234,
              region_name: "金溪县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1978,
              parent_id: 234,
              region_name: "资溪县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1979,
              parent_id: 234,
              region_name: "东乡县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1980,
              parent_id: 234,
              region_name: "广昌县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 235,
          parent_id: 17,
          region_name: "赣州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1981,
              parent_id: 235,
              region_name: "章贡区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1982,
              parent_id: 235,
              region_name: "于都县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1983,
              parent_id: 235,
              region_name: "瑞金市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1984,
              parent_id: 235,
              region_name: "南康市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1985,
              parent_id: 235,
              region_name: "赣县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1986,
              parent_id: 235,
              region_name: "信丰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1987,
              parent_id: 235,
              region_name: "大余县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1988,
              parent_id: 235,
              region_name: "上犹县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1989,
              parent_id: 235,
              region_name: "崇义县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1990,
              parent_id: 235,
              region_name: "安远县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1991,
              parent_id: 235,
              region_name: "龙南县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1992,
              parent_id: 235,
              region_name: "定南县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1993,
              parent_id: 235,
              region_name: "全南县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1994,
              parent_id: 235,
              region_name: "宁都县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1995,
              parent_id: 235,
              region_name: "兴国县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1996,
              parent_id: 235,
              region_name: "会昌县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1997,
              parent_id: 235,
              region_name: "寻乌县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 1998,
              parent_id: 235,
              region_name: "石城县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 236,
          parent_id: 17,
          region_name: "吉安",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 1999,
              parent_id: 236,
              region_name: "安福县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2000,
              parent_id: 236,
              region_name: "吉州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2001,
              parent_id: 236,
              region_name: "青原区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2002,
              parent_id: 236,
              region_name: "井冈山市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2003,
              parent_id: 236,
              region_name: "吉安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2004,
              parent_id: 236,
              region_name: "吉水县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2005,
              parent_id: 236,
              region_name: "峡江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2006,
              parent_id: 236,
              region_name: "新干县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2007,
              parent_id: 236,
              region_name: "永丰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2008,
              parent_id: 236,
              region_name: "泰和县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2009,
              parent_id: 236,
              region_name: "遂川县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2010,
              parent_id: 236,
              region_name: "万安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2011,
              parent_id: 236,
              region_name: "永新县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 237,
          parent_id: 17,
          region_name: "景德镇",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2012,
              parent_id: 237,
              region_name: "珠山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2013,
              parent_id: 237,
              region_name: "昌江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2014,
              parent_id: 237,
              region_name: "乐平市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2015,
              parent_id: 237,
              region_name: "浮梁县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 238,
          parent_id: 17,
          region_name: "九江",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2016,
              parent_id: 238,
              region_name: "浔阳区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2017,
              parent_id: 238,
              region_name: "庐山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2018,
              parent_id: 238,
              region_name: "瑞昌市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2019,
              parent_id: 238,
              region_name: "九江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2020,
              parent_id: 238,
              region_name: "武宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2021,
              parent_id: 238,
              region_name: "修水县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2022,
              parent_id: 238,
              region_name: "永修县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2023,
              parent_id: 238,
              region_name: "德安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2024,
              parent_id: 238,
              region_name: "星子县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2025,
              parent_id: 238,
              region_name: "都昌县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2026,
              parent_id: 238,
              region_name: "湖口县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2027,
              parent_id: 238,
              region_name: "彭泽县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 239,
          parent_id: 17,
          region_name: "萍乡",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2028,
              parent_id: 239,
              region_name: "安源区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2029,
              parent_id: 239,
              region_name: "湘东区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2030,
              parent_id: 239,
              region_name: "莲花县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2031,
              parent_id: 239,
              region_name: "芦溪县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2032,
              parent_id: 239,
              region_name: "上栗县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 240,
          parent_id: 17,
          region_name: "上饶",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2033,
              parent_id: 240,
              region_name: "信州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2034,
              parent_id: 240,
              region_name: "德兴市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2035,
              parent_id: 240,
              region_name: "上饶县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2036,
              parent_id: 240,
              region_name: "广丰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2037,
              parent_id: 240,
              region_name: "玉山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2038,
              parent_id: 240,
              region_name: "铅山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2039,
              parent_id: 240,
              region_name: "横峰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2040,
              parent_id: 240,
              region_name: "弋阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2041,
              parent_id: 240,
              region_name: "余干县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2042,
              parent_id: 240,
              region_name: "波阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2043,
              parent_id: 240,
              region_name: "万年县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2044,
              parent_id: 240,
              region_name: "婺源县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 241,
          parent_id: 17,
          region_name: "新余",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2045,
              parent_id: 241,
              region_name: "渝水区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2046,
              parent_id: 241,
              region_name: "分宜县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 242,
          parent_id: 17,
          region_name: "宜春",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2047,
              parent_id: 242,
              region_name: "袁州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2048,
              parent_id: 242,
              region_name: "丰城市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2049,
              parent_id: 242,
              region_name: "樟树市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2050,
              parent_id: 242,
              region_name: "高安市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2051,
              parent_id: 242,
              region_name: "奉新县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2052,
              parent_id: 242,
              region_name: "万载县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2053,
              parent_id: 242,
              region_name: "上高县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2054,
              parent_id: 242,
              region_name: "宜丰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2055,
              parent_id: 242,
              region_name: "靖安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2056,
              parent_id: 242,
              region_name: "铜鼓县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 243,
          parent_id: 17,
          region_name: "鹰潭",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2057,
              parent_id: 243,
              region_name: "月湖区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2058,
              parent_id: 243,
              region_name: "贵溪市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2059,
              parent_id: 243,
              region_name: "余江县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 18,
      parent_id: 1,
      region_name: "辽宁",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 244,
          parent_id: 18,
          region_name: "沈阳",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2060,
              parent_id: 244,
              region_name: "沈河区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2061,
              parent_id: 244,
              region_name: "皇姑区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2062,
              parent_id: 244,
              region_name: "和平区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2063,
              parent_id: 244,
              region_name: "大东区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2064,
              parent_id: 244,
              region_name: "铁西区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2065,
              parent_id: 244,
              region_name: "苏家屯区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2066,
              parent_id: 244,
              region_name: "东陵区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2067,
              parent_id: 244,
              region_name: "沈北新区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2068,
              parent_id: 244,
              region_name: "于洪区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2069,
              parent_id: 244,
              region_name: "浑南新区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2070,
              parent_id: 244,
              region_name: "新民市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2071,
              parent_id: 244,
              region_name: "辽中县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2072,
              parent_id: 244,
              region_name: "康平县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2073,
              parent_id: 244,
              region_name: "法库县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 245,
          parent_id: 18,
          region_name: "大连",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2074,
              parent_id: 245,
              region_name: "西岗区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2075,
              parent_id: 245,
              region_name: "中山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2076,
              parent_id: 245,
              region_name: "沙河口区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2077,
              parent_id: 245,
              region_name: "甘井子区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2078,
              parent_id: 245,
              region_name: "旅顺口区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2079,
              parent_id: 245,
              region_name: "金州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2080,
              parent_id: 245,
              region_name: "开发区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2081,
              parent_id: 245,
              region_name: "瓦房店市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2082,
              parent_id: 245,
              region_name: "普兰店市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2083,
              parent_id: 245,
              region_name: "庄河市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2084,
              parent_id: 245,
              region_name: "长海县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 246,
          parent_id: 18,
          region_name: "鞍山",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2085,
              parent_id: 246,
              region_name: "铁东区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2086,
              parent_id: 246,
              region_name: "铁西区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2087,
              parent_id: 246,
              region_name: "立山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2088,
              parent_id: 246,
              region_name: "千山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2089,
              parent_id: 246,
              region_name: "岫岩",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2090,
              parent_id: 246,
              region_name: "海城市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2091,
              parent_id: 246,
              region_name: "台安县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 247,
          parent_id: 18,
          region_name: "本溪",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2092,
              parent_id: 247,
              region_name: "本溪",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2093,
              parent_id: 247,
              region_name: "平山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2094,
              parent_id: 247,
              region_name: "明山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2095,
              parent_id: 247,
              region_name: "溪湖区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2096,
              parent_id: 247,
              region_name: "南芬区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2097,
              parent_id: 247,
              region_name: "桓仁",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 248,
          parent_id: 18,
          region_name: "朝阳",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2098,
              parent_id: 248,
              region_name: "双塔区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2099,
              parent_id: 248,
              region_name: "龙城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2100,
              parent_id: 248,
              region_name: "喀喇沁左翼蒙古族自治县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2101,
              parent_id: 248,
              region_name: "北票市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2102,
              parent_id: 248,
              region_name: "凌源市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2103,
              parent_id: 248,
              region_name: "朝阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2104,
              parent_id: 248,
              region_name: "建平县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 249,
          parent_id: 18,
          region_name: "丹东",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2105,
              parent_id: 249,
              region_name: "振兴区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2106,
              parent_id: 249,
              region_name: "元宝区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2107,
              parent_id: 249,
              region_name: "振安区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2108,
              parent_id: 249,
              region_name: "宽甸",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2109,
              parent_id: 249,
              region_name: "东港市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2110,
              parent_id: 249,
              region_name: "凤城市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 250,
          parent_id: 18,
          region_name: "抚顺",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2111,
              parent_id: 250,
              region_name: "顺城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2112,
              parent_id: 250,
              region_name: "新抚区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2113,
              parent_id: 250,
              region_name: "东洲区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2114,
              parent_id: 250,
              region_name: "望花区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2115,
              parent_id: 250,
              region_name: "清原",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2116,
              parent_id: 250,
              region_name: "新宾",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2117,
              parent_id: 250,
              region_name: "抚顺县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 251,
          parent_id: 18,
          region_name: "阜新",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2118,
              parent_id: 251,
              region_name: "阜新",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2119,
              parent_id: 251,
              region_name: "海州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2120,
              parent_id: 251,
              region_name: "新邱区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2121,
              parent_id: 251,
              region_name: "太平区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2122,
              parent_id: 251,
              region_name: "清河门区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2123,
              parent_id: 251,
              region_name: "细河区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2124,
              parent_id: 251,
              region_name: "彰武县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 252,
          parent_id: 18,
          region_name: "葫芦岛",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2125,
              parent_id: 252,
              region_name: "龙港区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2126,
              parent_id: 252,
              region_name: "南票区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2127,
              parent_id: 252,
              region_name: "连山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2128,
              parent_id: 252,
              region_name: "兴城市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2129,
              parent_id: 252,
              region_name: "绥中县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2130,
              parent_id: 252,
              region_name: "建昌县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 253,
          parent_id: 18,
          region_name: "锦州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2131,
              parent_id: 253,
              region_name: "太和区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2132,
              parent_id: 253,
              region_name: "古塔区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2133,
              parent_id: 253,
              region_name: "凌河区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2134,
              parent_id: 253,
              region_name: "凌海市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2135,
              parent_id: 253,
              region_name: "北镇市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2136,
              parent_id: 253,
              region_name: "黑山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2137,
              parent_id: 253,
              region_name: "义县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 254,
          parent_id: 18,
          region_name: "辽阳",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2138,
              parent_id: 254,
              region_name: "白塔区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2139,
              parent_id: 254,
              region_name: "文圣区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2140,
              parent_id: 254,
              region_name: "宏伟区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2141,
              parent_id: 254,
              region_name: "太子河区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2142,
              parent_id: 254,
              region_name: "弓长岭区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2143,
              parent_id: 254,
              region_name: "灯塔市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2144,
              parent_id: 254,
              region_name: "辽阳县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 255,
          parent_id: 18,
          region_name: "盘锦",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2145,
              parent_id: 255,
              region_name: "双台子区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2146,
              parent_id: 255,
              region_name: "兴隆台区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2147,
              parent_id: 255,
              region_name: "大洼县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2148,
              parent_id: 255,
              region_name: "盘山县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 256,
          parent_id: 18,
          region_name: "铁岭",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2149,
              parent_id: 256,
              region_name: "银州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2150,
              parent_id: 256,
              region_name: "清河区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2151,
              parent_id: 256,
              region_name: "调兵山市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2152,
              parent_id: 256,
              region_name: "开原市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2153,
              parent_id: 256,
              region_name: "铁岭县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2154,
              parent_id: 256,
              region_name: "西丰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2155,
              parent_id: 256,
              region_name: "昌图县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 257,
          parent_id: 18,
          region_name: "营口",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2156,
              parent_id: 257,
              region_name: "站前区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2157,
              parent_id: 257,
              region_name: "西市区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2158,
              parent_id: 257,
              region_name: "鲅鱼圈区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2159,
              parent_id: 257,
              region_name: "老边区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2160,
              parent_id: 257,
              region_name: "盖州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2161,
              parent_id: 257,
              region_name: "大石桥市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 19,
      parent_id: 1,
      region_name: "内蒙古",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 258,
          parent_id: 19,
          region_name: "呼和浩特",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2162,
              parent_id: 258,
              region_name: "回民区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2163,
              parent_id: 258,
              region_name: "玉泉区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2164,
              parent_id: 258,
              region_name: "新城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2165,
              parent_id: 258,
              region_name: "赛罕区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2166,
              parent_id: 258,
              region_name: "清水河县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2167,
              parent_id: 258,
              region_name: "土默特左旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2168,
              parent_id: 258,
              region_name: "托克托县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2169,
              parent_id: 258,
              region_name: "和林格尔县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2170,
              parent_id: 258,
              region_name: "武川县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 259,
          parent_id: 19,
          region_name: "阿拉善盟",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2171,
              parent_id: 259,
              region_name: "阿拉善左旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2172,
              parent_id: 259,
              region_name: "阿拉善右旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2173,
              parent_id: 259,
              region_name: "额济纳旗",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 260,
          parent_id: 19,
          region_name: "巴彦淖尔盟",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2174,
              parent_id: 260,
              region_name: "临河区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2175,
              parent_id: 260,
              region_name: "五原县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2176,
              parent_id: 260,
              region_name: "磴口县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2177,
              parent_id: 260,
              region_name: "乌拉特前旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2178,
              parent_id: 260,
              region_name: "乌拉特中旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2179,
              parent_id: 260,
              region_name: "乌拉特后旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2180,
              parent_id: 260,
              region_name: "杭锦后旗",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 261,
          parent_id: 19,
          region_name: "包头",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2181,
              parent_id: 261,
              region_name: "昆都仑区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2182,
              parent_id: 261,
              region_name: "青山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2183,
              parent_id: 261,
              region_name: "东河区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2184,
              parent_id: 261,
              region_name: "九原区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2185,
              parent_id: 261,
              region_name: "石拐区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2186,
              parent_id: 261,
              region_name: "白云矿区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2187,
              parent_id: 261,
              region_name: "土默特右旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2188,
              parent_id: 261,
              region_name: "固阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2189,
              parent_id: 261,
              region_name: "达尔罕茂明安联合旗",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 262,
          parent_id: 19,
          region_name: "赤峰",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2190,
              parent_id: 262,
              region_name: "红山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2191,
              parent_id: 262,
              region_name: "元宝山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2192,
              parent_id: 262,
              region_name: "松山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2193,
              parent_id: 262,
              region_name: "阿鲁科尔沁旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2194,
              parent_id: 262,
              region_name: "巴林左旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2195,
              parent_id: 262,
              region_name: "巴林右旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2196,
              parent_id: 262,
              region_name: "林西县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2197,
              parent_id: 262,
              region_name: "克什克腾旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2198,
              parent_id: 262,
              region_name: "翁牛特旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2199,
              parent_id: 262,
              region_name: "喀喇沁旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2200,
              parent_id: 262,
              region_name: "宁城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2201,
              parent_id: 262,
              region_name: "敖汉旗",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 263,
          parent_id: 19,
          region_name: "鄂尔多斯",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2202,
              parent_id: 263,
              region_name: "东胜区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2203,
              parent_id: 263,
              region_name: "达拉特旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2204,
              parent_id: 263,
              region_name: "准格尔旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2205,
              parent_id: 263,
              region_name: "鄂托克前旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2206,
              parent_id: 263,
              region_name: "鄂托克旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2207,
              parent_id: 263,
              region_name: "杭锦旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2208,
              parent_id: 263,
              region_name: "乌审旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2209,
              parent_id: 263,
              region_name: "伊金霍洛旗",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 264,
          parent_id: 19,
          region_name: "呼伦贝尔",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2210,
              parent_id: 264,
              region_name: "海拉尔区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2211,
              parent_id: 264,
              region_name: "莫力达瓦",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2212,
              parent_id: 264,
              region_name: "满洲里市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2213,
              parent_id: 264,
              region_name: "牙克石市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2214,
              parent_id: 264,
              region_name: "扎兰屯市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2215,
              parent_id: 264,
              region_name: "额尔古纳市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2216,
              parent_id: 264,
              region_name: "根河市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2217,
              parent_id: 264,
              region_name: "阿荣旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2218,
              parent_id: 264,
              region_name: "鄂伦春自治旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2219,
              parent_id: 264,
              region_name: "鄂温克族自治旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2220,
              parent_id: 264,
              region_name: "陈巴尔虎旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2221,
              parent_id: 264,
              region_name: "新巴尔虎左旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2222,
              parent_id: 264,
              region_name: "新巴尔虎右旗",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 265,
          parent_id: 19,
          region_name: "通辽",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2223,
              parent_id: 265,
              region_name: "科尔沁区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2224,
              parent_id: 265,
              region_name: "霍林郭勒市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2225,
              parent_id: 265,
              region_name: "科尔沁左翼中旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2226,
              parent_id: 265,
              region_name: "科尔沁左翼后旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2227,
              parent_id: 265,
              region_name: "开鲁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2228,
              parent_id: 265,
              region_name: "库伦旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2229,
              parent_id: 265,
              region_name: "奈曼旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2230,
              parent_id: 265,
              region_name: "扎鲁特旗",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 266,
          parent_id: 19,
          region_name: "乌海",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2231,
              parent_id: 266,
              region_name: "海勃湾区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2232,
              parent_id: 266,
              region_name: "乌达区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2233,
              parent_id: 266,
              region_name: "海南区",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 267,
          parent_id: 19,
          region_name: "乌兰察布市",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2234,
              parent_id: 267,
              region_name: "化德县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2235,
              parent_id: 267,
              region_name: "集宁区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2236,
              parent_id: 267,
              region_name: "丰镇市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2237,
              parent_id: 267,
              region_name: "卓资县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2238,
              parent_id: 267,
              region_name: "商都县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2239,
              parent_id: 267,
              region_name: "兴和县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2240,
              parent_id: 267,
              region_name: "凉城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2241,
              parent_id: 267,
              region_name: "察哈尔右翼前旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2242,
              parent_id: 267,
              region_name: "察哈尔右翼中旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2243,
              parent_id: 267,
              region_name: "察哈尔右翼后旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2244,
              parent_id: 267,
              region_name: "四子王旗",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 268,
          parent_id: 19,
          region_name: "锡林郭勒盟",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2245,
              parent_id: 268,
              region_name: "二连浩特市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2246,
              parent_id: 268,
              region_name: "锡林浩特市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2247,
              parent_id: 268,
              region_name: "阿巴嘎旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2248,
              parent_id: 268,
              region_name: "苏尼特左旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2249,
              parent_id: 268,
              region_name: "苏尼特右旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2250,
              parent_id: 268,
              region_name: "东乌珠穆沁旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2251,
              parent_id: 268,
              region_name: "西乌珠穆沁旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2252,
              parent_id: 268,
              region_name: "太仆寺旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2253,
              parent_id: 268,
              region_name: "镶黄旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2254,
              parent_id: 268,
              region_name: "正镶白旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2255,
              parent_id: 268,
              region_name: "正蓝旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2256,
              parent_id: 268,
              region_name: "多伦县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 269,
          parent_id: 19,
          region_name: "兴安盟",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2257,
              parent_id: 269,
              region_name: "乌兰浩特市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2258,
              parent_id: 269,
              region_name: "阿尔山市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2259,
              parent_id: 269,
              region_name: "科尔沁右翼前旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2260,
              parent_id: 269,
              region_name: "科尔沁右翼中旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2261,
              parent_id: 269,
              region_name: "扎赉特旗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2262,
              parent_id: 269,
              region_name: "突泉县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 20,
      parent_id: 1,
      region_name: "宁夏",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 270,
          parent_id: 20,
          region_name: "银川",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2263,
              parent_id: 270,
              region_name: "西夏区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2264,
              parent_id: 270,
              region_name: "金凤区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2265,
              parent_id: 270,
              region_name: "兴庆区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2266,
              parent_id: 270,
              region_name: "灵武市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2267,
              parent_id: 270,
              region_name: "永宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2268,
              parent_id: 270,
              region_name: "贺兰县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 271,
          parent_id: 20,
          region_name: "固原",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2269,
              parent_id: 271,
              region_name: "原州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2270,
              parent_id: 271,
              region_name: "海原县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2271,
              parent_id: 271,
              region_name: "西吉县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2272,
              parent_id: 271,
              region_name: "隆德县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2273,
              parent_id: 271,
              region_name: "泾源县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2274,
              parent_id: 271,
              region_name: "彭阳县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 272,
          parent_id: 20,
          region_name: "石嘴山",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2275,
              parent_id: 272,
              region_name: "惠农县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2276,
              parent_id: 272,
              region_name: "大武口区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2277,
              parent_id: 272,
              region_name: "惠农区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2278,
              parent_id: 272,
              region_name: "陶乐县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2279,
              parent_id: 272,
              region_name: "平罗县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 273,
          parent_id: 20,
          region_name: "吴忠",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2280,
              parent_id: 273,
              region_name: "利通区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2281,
              parent_id: 273,
              region_name: "中卫县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2282,
              parent_id: 273,
              region_name: "青铜峡市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2283,
              parent_id: 273,
              region_name: "中宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2284,
              parent_id: 273,
              region_name: "盐池县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2285,
              parent_id: 273,
              region_name: "同心县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 274,
          parent_id: 20,
          region_name: "中卫",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2286,
              parent_id: 274,
              region_name: "沙坡头区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2287,
              parent_id: 274,
              region_name: "海原县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2288,
              parent_id: 274,
              region_name: "中宁县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 21,
      parent_id: 1,
      region_name: "青海",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 275,
          parent_id: 21,
          region_name: "西宁",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2289,
              parent_id: 275,
              region_name: "城中区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2290,
              parent_id: 275,
              region_name: "城东区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2291,
              parent_id: 275,
              region_name: "城西区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2292,
              parent_id: 275,
              region_name: "城北区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2293,
              parent_id: 275,
              region_name: "湟中县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2294,
              parent_id: 275,
              region_name: "湟源县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2295,
              parent_id: 275,
              region_name: "大通",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 276,
          parent_id: 21,
          region_name: "果洛",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2296,
              parent_id: 276,
              region_name: "玛沁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2297,
              parent_id: 276,
              region_name: "班玛县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2298,
              parent_id: 276,
              region_name: "甘德县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2299,
              parent_id: 276,
              region_name: "达日县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2300,
              parent_id: 276,
              region_name: "久治县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2301,
              parent_id: 276,
              region_name: "玛多县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 277,
          parent_id: 21,
          region_name: "海北",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2302,
              parent_id: 277,
              region_name: "海晏县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2303,
              parent_id: 277,
              region_name: "祁连县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2304,
              parent_id: 277,
              region_name: "刚察县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2305,
              parent_id: 277,
              region_name: "门源",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 278,
          parent_id: 21,
          region_name: "海东",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2306,
              parent_id: 278,
              region_name: "平安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2307,
              parent_id: 278,
              region_name: "乐都县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2308,
              parent_id: 278,
              region_name: "民和",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2309,
              parent_id: 278,
              region_name: "互助",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2310,
              parent_id: 278,
              region_name: "化隆",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2311,
              parent_id: 278,
              region_name: "循化",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 279,
          parent_id: 21,
          region_name: "海南",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2312,
              parent_id: 279,
              region_name: "共和县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2313,
              parent_id: 279,
              region_name: "同德县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2314,
              parent_id: 279,
              region_name: "贵德县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2315,
              parent_id: 279,
              region_name: "兴海县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2316,
              parent_id: 279,
              region_name: "贵南县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 280,
          parent_id: 21,
          region_name: "海西",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2317,
              parent_id: 280,
              region_name: "德令哈市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2318,
              parent_id: 280,
              region_name: "格尔木市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2319,
              parent_id: 280,
              region_name: "乌兰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2320,
              parent_id: 280,
              region_name: "都兰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2321,
              parent_id: 280,
              region_name: "天峻县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 281,
          parent_id: 21,
          region_name: "黄南",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2322,
              parent_id: 281,
              region_name: "同仁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2323,
              parent_id: 281,
              region_name: "尖扎县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2324,
              parent_id: 281,
              region_name: "泽库县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2325,
              parent_id: 281,
              region_name: "河南蒙古族自治县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 282,
          parent_id: 21,
          region_name: "玉树",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2326,
              parent_id: 282,
              region_name: "玉树县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2327,
              parent_id: 282,
              region_name: "杂多县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2328,
              parent_id: 282,
              region_name: "称多县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2329,
              parent_id: 282,
              region_name: "治多县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2330,
              parent_id: 282,
              region_name: "囊谦县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2331,
              parent_id: 282,
              region_name: "曲麻莱县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 22,
      parent_id: 1,
      region_name: "山东",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 283,
          parent_id: 22,
          region_name: "济南",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2332,
              parent_id: 283,
              region_name: "市中区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2333,
              parent_id: 283,
              region_name: "历下区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2334,
              parent_id: 283,
              region_name: "天桥区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2335,
              parent_id: 283,
              region_name: "槐荫区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2336,
              parent_id: 283,
              region_name: "历城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2337,
              parent_id: 283,
              region_name: "长清区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2338,
              parent_id: 283,
              region_name: "章丘市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2339,
              parent_id: 283,
              region_name: "平阴县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2340,
              parent_id: 283,
              region_name: "济阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2341,
              parent_id: 283,
              region_name: "商河县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 284,
          parent_id: 22,
          region_name: "青岛",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2342,
              parent_id: 284,
              region_name: "市南区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2343,
              parent_id: 284,
              region_name: "市北区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2344,
              parent_id: 284,
              region_name: "城阳区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2345,
              parent_id: 284,
              region_name: "四方区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2346,
              parent_id: 284,
              region_name: "李沧区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2347,
              parent_id: 284,
              region_name: "黄岛区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2348,
              parent_id: 284,
              region_name: "崂山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2349,
              parent_id: 284,
              region_name: "胶州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2350,
              parent_id: 284,
              region_name: "即墨市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2351,
              parent_id: 284,
              region_name: "平度市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2352,
              parent_id: 284,
              region_name: "胶南市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2353,
              parent_id: 284,
              region_name: "莱西市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 285,
          parent_id: 22,
          region_name: "滨州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2354,
              parent_id: 285,
              region_name: "滨城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2355,
              parent_id: 285,
              region_name: "惠民县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2356,
              parent_id: 285,
              region_name: "阳信县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2357,
              parent_id: 285,
              region_name: "无棣县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2358,
              parent_id: 285,
              region_name: "沾化县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2359,
              parent_id: 285,
              region_name: "博兴县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2360,
              parent_id: 285,
              region_name: "邹平县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 286,
          parent_id: 22,
          region_name: "德州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2361,
              parent_id: 286,
              region_name: "德城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2362,
              parent_id: 286,
              region_name: "陵县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2363,
              parent_id: 286,
              region_name: "乐陵市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2364,
              parent_id: 286,
              region_name: "禹城市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2365,
              parent_id: 286,
              region_name: "宁津县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2366,
              parent_id: 286,
              region_name: "庆云县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2367,
              parent_id: 286,
              region_name: "临邑县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2368,
              parent_id: 286,
              region_name: "齐河县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2369,
              parent_id: 286,
              region_name: "平原县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2370,
              parent_id: 286,
              region_name: "夏津县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2371,
              parent_id: 286,
              region_name: "武城县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 287,
          parent_id: 22,
          region_name: "东营",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2372,
              parent_id: 287,
              region_name: "东营区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2373,
              parent_id: 287,
              region_name: "河口区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2374,
              parent_id: 287,
              region_name: "垦利县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2375,
              parent_id: 287,
              region_name: "利津县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2376,
              parent_id: 287,
              region_name: "广饶县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 288,
          parent_id: 22,
          region_name: "菏泽",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2377,
              parent_id: 288,
              region_name: "牡丹区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2378,
              parent_id: 288,
              region_name: "曹县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2379,
              parent_id: 288,
              region_name: "单县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2380,
              parent_id: 288,
              region_name: "成武县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2381,
              parent_id: 288,
              region_name: "巨野县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2382,
              parent_id: 288,
              region_name: "郓城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2383,
              parent_id: 288,
              region_name: "鄄城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2384,
              parent_id: 288,
              region_name: "定陶县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2385,
              parent_id: 288,
              region_name: "东明县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 289,
          parent_id: 22,
          region_name: "济宁",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2386,
              parent_id: 289,
              region_name: "市中区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2387,
              parent_id: 289,
              region_name: "任城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2388,
              parent_id: 289,
              region_name: "曲阜市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2389,
              parent_id: 289,
              region_name: "兖州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2390,
              parent_id: 289,
              region_name: "邹城市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2391,
              parent_id: 289,
              region_name: "微山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2392,
              parent_id: 289,
              region_name: "鱼台县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2393,
              parent_id: 289,
              region_name: "金乡县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2394,
              parent_id: 289,
              region_name: "嘉祥县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2395,
              parent_id: 289,
              region_name: "汶上县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2396,
              parent_id: 289,
              region_name: "泗水县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2397,
              parent_id: 289,
              region_name: "梁山县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 290,
          parent_id: 22,
          region_name: "莱芜",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2398,
              parent_id: 290,
              region_name: "莱城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2399,
              parent_id: 290,
              region_name: "钢城区",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 291,
          parent_id: 22,
          region_name: "聊城",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2400,
              parent_id: 291,
              region_name: "东昌府区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2401,
              parent_id: 291,
              region_name: "临清市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2402,
              parent_id: 291,
              region_name: "阳谷县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2403,
              parent_id: 291,
              region_name: "莘县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2404,
              parent_id: 291,
              region_name: "茌平县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2405,
              parent_id: 291,
              region_name: "东阿县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2406,
              parent_id: 291,
              region_name: "冠县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2407,
              parent_id: 291,
              region_name: "高唐县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 292,
          parent_id: 22,
          region_name: "临沂",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2408,
              parent_id: 292,
              region_name: "兰山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2409,
              parent_id: 292,
              region_name: "罗庄区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2410,
              parent_id: 292,
              region_name: "河东区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2411,
              parent_id: 292,
              region_name: "沂南县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2412,
              parent_id: 292,
              region_name: "郯城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2413,
              parent_id: 292,
              region_name: "沂水县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2414,
              parent_id: 292,
              region_name: "苍山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2415,
              parent_id: 292,
              region_name: "费县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2416,
              parent_id: 292,
              region_name: "平邑县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2417,
              parent_id: 292,
              region_name: "莒南县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2418,
              parent_id: 292,
              region_name: "蒙阴县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2419,
              parent_id: 292,
              region_name: "临沭县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 293,
          parent_id: 22,
          region_name: "日照",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2420,
              parent_id: 293,
              region_name: "东港区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2421,
              parent_id: 293,
              region_name: "岚山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2422,
              parent_id: 293,
              region_name: "五莲县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2423,
              parent_id: 293,
              region_name: "莒县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 294,
          parent_id: 22,
          region_name: "泰安",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2424,
              parent_id: 294,
              region_name: "泰山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2425,
              parent_id: 294,
              region_name: "岱岳区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2426,
              parent_id: 294,
              region_name: "新泰市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2427,
              parent_id: 294,
              region_name: "肥城市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2428,
              parent_id: 294,
              region_name: "宁阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2429,
              parent_id: 294,
              region_name: "东平县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 295,
          parent_id: 22,
          region_name: "威海",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2430,
              parent_id: 295,
              region_name: "荣成市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2431,
              parent_id: 295,
              region_name: "乳山市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2432,
              parent_id: 295,
              region_name: "环翠区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2433,
              parent_id: 295,
              region_name: "文登市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 296,
          parent_id: 22,
          region_name: "潍坊",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2434,
              parent_id: 296,
              region_name: "潍城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2435,
              parent_id: 296,
              region_name: "寒亭区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2436,
              parent_id: 296,
              region_name: "坊子区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2437,
              parent_id: 296,
              region_name: "奎文区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2438,
              parent_id: 296,
              region_name: "青州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2439,
              parent_id: 296,
              region_name: "诸城市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2440,
              parent_id: 296,
              region_name: "寿光市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2441,
              parent_id: 296,
              region_name: "安丘市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2442,
              parent_id: 296,
              region_name: "高密市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2443,
              parent_id: 296,
              region_name: "昌邑市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2444,
              parent_id: 296,
              region_name: "临朐县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2445,
              parent_id: 296,
              region_name: "昌乐县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 297,
          parent_id: 22,
          region_name: "烟台",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2446,
              parent_id: 297,
              region_name: "芝罘区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2447,
              parent_id: 297,
              region_name: "福山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2448,
              parent_id: 297,
              region_name: "牟平区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2449,
              parent_id: 297,
              region_name: "莱山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2450,
              parent_id: 297,
              region_name: "开发区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2451,
              parent_id: 297,
              region_name: "龙口市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2452,
              parent_id: 297,
              region_name: "莱阳市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2453,
              parent_id: 297,
              region_name: "莱州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2454,
              parent_id: 297,
              region_name: "蓬莱市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2455,
              parent_id: 297,
              region_name: "招远市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2456,
              parent_id: 297,
              region_name: "栖霞市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2457,
              parent_id: 297,
              region_name: "海阳市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2458,
              parent_id: 297,
              region_name: "长岛县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 298,
          parent_id: 22,
          region_name: "枣庄",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2459,
              parent_id: 298,
              region_name: "市中区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2460,
              parent_id: 298,
              region_name: "山亭区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2461,
              parent_id: 298,
              region_name: "峄城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2462,
              parent_id: 298,
              region_name: "台儿庄区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2463,
              parent_id: 298,
              region_name: "薛城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2464,
              parent_id: 298,
              region_name: "滕州市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 299,
          parent_id: 22,
          region_name: "淄博",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2465,
              parent_id: 299,
              region_name: "张店区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2466,
              parent_id: 299,
              region_name: "临淄区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2467,
              parent_id: 299,
              region_name: "淄川区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2468,
              parent_id: 299,
              region_name: "博山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2469,
              parent_id: 299,
              region_name: "周村区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2470,
              parent_id: 299,
              region_name: "桓台县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2471,
              parent_id: 299,
              region_name: "高青县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2472,
              parent_id: 299,
              region_name: "沂源县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 23,
      parent_id: 1,
      region_name: "山西",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 300,
          parent_id: 23,
          region_name: "太原",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2473,
              parent_id: 300,
              region_name: "杏花岭区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2474,
              parent_id: 300,
              region_name: "小店区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2475,
              parent_id: 300,
              region_name: "迎泽区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2476,
              parent_id: 300,
              region_name: "尖草坪区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2477,
              parent_id: 300,
              region_name: "万柏林区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2478,
              parent_id: 300,
              region_name: "晋源区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2479,
              parent_id: 300,
              region_name: "高新开发区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2480,
              parent_id: 300,
              region_name: "民营经济开发区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2481,
              parent_id: 300,
              region_name: "经济技术开发区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2482,
              parent_id: 300,
              region_name: "清徐县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2483,
              parent_id: 300,
              region_name: "阳曲县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2484,
              parent_id: 300,
              region_name: "娄烦县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2485,
              parent_id: 300,
              region_name: "古交市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 301,
          parent_id: 23,
          region_name: "长治",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2486,
              parent_id: 301,
              region_name: "城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2487,
              parent_id: 301,
              region_name: "郊区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2488,
              parent_id: 301,
              region_name: "沁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2489,
              parent_id: 301,
              region_name: "潞城市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2490,
              parent_id: 301,
              region_name: "长治县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2491,
              parent_id: 301,
              region_name: "襄垣县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2492,
              parent_id: 301,
              region_name: "屯留县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2493,
              parent_id: 301,
              region_name: "平顺县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2494,
              parent_id: 301,
              region_name: "黎城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2495,
              parent_id: 301,
              region_name: "壶关县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2496,
              parent_id: 301,
              region_name: "长子县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2497,
              parent_id: 301,
              region_name: "武乡县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2498,
              parent_id: 301,
              region_name: "沁源县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 302,
          parent_id: 23,
          region_name: "大同",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2499,
              parent_id: 302,
              region_name: "城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2500,
              parent_id: 302,
              region_name: "矿区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2501,
              parent_id: 302,
              region_name: "南郊区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2502,
              parent_id: 302,
              region_name: "新荣区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2503,
              parent_id: 302,
              region_name: "阳高县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2504,
              parent_id: 302,
              region_name: "天镇县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2505,
              parent_id: 302,
              region_name: "广灵县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2506,
              parent_id: 302,
              region_name: "灵丘县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2507,
              parent_id: 302,
              region_name: "浑源县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2508,
              parent_id: 302,
              region_name: "左云县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2509,
              parent_id: 302,
              region_name: "大同县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 303,
          parent_id: 23,
          region_name: "晋城",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2510,
              parent_id: 303,
              region_name: "城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2511,
              parent_id: 303,
              region_name: "高平市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2512,
              parent_id: 303,
              region_name: "沁水县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2513,
              parent_id: 303,
              region_name: "阳城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2514,
              parent_id: 303,
              region_name: "陵川县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2515,
              parent_id: 303,
              region_name: "泽州县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 304,
          parent_id: 23,
          region_name: "晋中",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2516,
              parent_id: 304,
              region_name: "榆次区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2517,
              parent_id: 304,
              region_name: "介休市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2518,
              parent_id: 304,
              region_name: "榆社县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2519,
              parent_id: 304,
              region_name: "左权县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2520,
              parent_id: 304,
              region_name: "和顺县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2521,
              parent_id: 304,
              region_name: "昔阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2522,
              parent_id: 304,
              region_name: "寿阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2523,
              parent_id: 304,
              region_name: "太谷县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2524,
              parent_id: 304,
              region_name: "祁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2525,
              parent_id: 304,
              region_name: "平遥县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2526,
              parent_id: 304,
              region_name: "灵石县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 305,
          parent_id: 23,
          region_name: "临汾",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2527,
              parent_id: 305,
              region_name: "尧都区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2528,
              parent_id: 305,
              region_name: "侯马市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2529,
              parent_id: 305,
              region_name: "霍州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2530,
              parent_id: 305,
              region_name: "曲沃县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2531,
              parent_id: 305,
              region_name: "翼城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2532,
              parent_id: 305,
              region_name: "襄汾县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2533,
              parent_id: 305,
              region_name: "洪洞县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2534,
              parent_id: 305,
              region_name: "吉县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2535,
              parent_id: 305,
              region_name: "安泽县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2536,
              parent_id: 305,
              region_name: "浮山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2537,
              parent_id: 305,
              region_name: "古县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2538,
              parent_id: 305,
              region_name: "乡宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2539,
              parent_id: 305,
              region_name: "大宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2540,
              parent_id: 305,
              region_name: "隰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2541,
              parent_id: 305,
              region_name: "永和县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2542,
              parent_id: 305,
              region_name: "蒲县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2543,
              parent_id: 305,
              region_name: "汾西县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 306,
          parent_id: 23,
          region_name: "吕梁",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2544,
              parent_id: 306,
              region_name: "离石市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2545,
              parent_id: 306,
              region_name: "离石区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2546,
              parent_id: 306,
              region_name: "孝义市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2547,
              parent_id: 306,
              region_name: "汾阳市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2548,
              parent_id: 306,
              region_name: "文水县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2549,
              parent_id: 306,
              region_name: "交城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2550,
              parent_id: 306,
              region_name: "兴县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2551,
              parent_id: 306,
              region_name: "临县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2552,
              parent_id: 306,
              region_name: "柳林县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2553,
              parent_id: 306,
              region_name: "石楼县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2554,
              parent_id: 306,
              region_name: "岚县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2555,
              parent_id: 306,
              region_name: "方山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2556,
              parent_id: 306,
              region_name: "中阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2557,
              parent_id: 306,
              region_name: "交口县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 307,
          parent_id: 23,
          region_name: "朔州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2558,
              parent_id: 307,
              region_name: "朔城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2559,
              parent_id: 307,
              region_name: "平鲁区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2560,
              parent_id: 307,
              region_name: "山阴县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2561,
              parent_id: 307,
              region_name: "应县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2562,
              parent_id: 307,
              region_name: "右玉县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2563,
              parent_id: 307,
              region_name: "怀仁县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 308,
          parent_id: 23,
          region_name: "忻州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2564,
              parent_id: 308,
              region_name: "忻府区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2565,
              parent_id: 308,
              region_name: "原平市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2566,
              parent_id: 308,
              region_name: "定襄县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2567,
              parent_id: 308,
              region_name: "五台县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2568,
              parent_id: 308,
              region_name: "代县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2569,
              parent_id: 308,
              region_name: "繁峙县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2570,
              parent_id: 308,
              region_name: "宁武县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2571,
              parent_id: 308,
              region_name: "静乐县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2572,
              parent_id: 308,
              region_name: "神池县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2573,
              parent_id: 308,
              region_name: "五寨县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2574,
              parent_id: 308,
              region_name: "岢岚县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2575,
              parent_id: 308,
              region_name: "河曲县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2576,
              parent_id: 308,
              region_name: "保德县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2577,
              parent_id: 308,
              region_name: "偏关县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 309,
          parent_id: 23,
          region_name: "阳泉",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2578,
              parent_id: 309,
              region_name: "城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2579,
              parent_id: 309,
              region_name: "矿区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2580,
              parent_id: 309,
              region_name: "郊区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2581,
              parent_id: 309,
              region_name: "平定县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2582,
              parent_id: 309,
              region_name: "盂县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 310,
          parent_id: 23,
          region_name: "运城",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2583,
              parent_id: 310,
              region_name: "盐湖区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2584,
              parent_id: 310,
              region_name: "永济市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2585,
              parent_id: 310,
              region_name: "河津市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2586,
              parent_id: 310,
              region_name: "临猗县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2587,
              parent_id: 310,
              region_name: "万荣县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2588,
              parent_id: 310,
              region_name: "闻喜县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2589,
              parent_id: 310,
              region_name: "稷山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2590,
              parent_id: 310,
              region_name: "新绛县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2591,
              parent_id: 310,
              region_name: "绛县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2592,
              parent_id: 310,
              region_name: "垣曲县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2593,
              parent_id: 310,
              region_name: "夏县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2594,
              parent_id: 310,
              region_name: "平陆县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2595,
              parent_id: 310,
              region_name: "芮城县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 24,
      parent_id: 1,
      region_name: "陕西",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 311,
          parent_id: 24,
          region_name: "西安",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2596,
              parent_id: 311,
              region_name: "莲湖区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2597,
              parent_id: 311,
              region_name: "新城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2598,
              parent_id: 311,
              region_name: "碑林区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2599,
              parent_id: 311,
              region_name: "雁塔区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2600,
              parent_id: 311,
              region_name: "灞桥区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2601,
              parent_id: 311,
              region_name: "未央区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2602,
              parent_id: 311,
              region_name: "阎良区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2603,
              parent_id: 311,
              region_name: "临潼区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2604,
              parent_id: 311,
              region_name: "长安区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2605,
              parent_id: 311,
              region_name: "蓝田县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2606,
              parent_id: 311,
              region_name: "周至县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2607,
              parent_id: 311,
              region_name: "户县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2608,
              parent_id: 311,
              region_name: "高陵县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 312,
          parent_id: 24,
          region_name: "安康",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2609,
              parent_id: 312,
              region_name: "汉滨区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2610,
              parent_id: 312,
              region_name: "汉阴县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2611,
              parent_id: 312,
              region_name: "石泉县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2612,
              parent_id: 312,
              region_name: "宁陕县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2613,
              parent_id: 312,
              region_name: "紫阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2614,
              parent_id: 312,
              region_name: "岚皋县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2615,
              parent_id: 312,
              region_name: "平利县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2616,
              parent_id: 312,
              region_name: "镇坪县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2617,
              parent_id: 312,
              region_name: "旬阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2618,
              parent_id: 312,
              region_name: "白河县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 313,
          parent_id: 24,
          region_name: "宝鸡",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2619,
              parent_id: 313,
              region_name: "陈仓区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2620,
              parent_id: 313,
              region_name: "渭滨区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2621,
              parent_id: 313,
              region_name: "金台区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2622,
              parent_id: 313,
              region_name: "凤翔县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2623,
              parent_id: 313,
              region_name: "岐山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2624,
              parent_id: 313,
              region_name: "扶风县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2625,
              parent_id: 313,
              region_name: "眉县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2626,
              parent_id: 313,
              region_name: "陇县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2627,
              parent_id: 313,
              region_name: "千阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2628,
              parent_id: 313,
              region_name: "麟游县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2629,
              parent_id: 313,
              region_name: "凤县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2630,
              parent_id: 313,
              region_name: "太白县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 314,
          parent_id: 24,
          region_name: "汉中",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2631,
              parent_id: 314,
              region_name: "汉台区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2632,
              parent_id: 314,
              region_name: "南郑县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2633,
              parent_id: 314,
              region_name: "城固县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2634,
              parent_id: 314,
              region_name: "洋县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2635,
              parent_id: 314,
              region_name: "西乡县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2636,
              parent_id: 314,
              region_name: "勉县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2637,
              parent_id: 314,
              region_name: "宁强县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2638,
              parent_id: 314,
              region_name: "略阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2639,
              parent_id: 314,
              region_name: "镇巴县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2640,
              parent_id: 314,
              region_name: "留坝县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2641,
              parent_id: 314,
              region_name: "佛坪县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 315,
          parent_id: 24,
          region_name: "商洛",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2642,
              parent_id: 315,
              region_name: "商州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2643,
              parent_id: 315,
              region_name: "洛南县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2644,
              parent_id: 315,
              region_name: "丹凤县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2645,
              parent_id: 315,
              region_name: "商南县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2646,
              parent_id: 315,
              region_name: "山阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2647,
              parent_id: 315,
              region_name: "镇安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2648,
              parent_id: 315,
              region_name: "柞水县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 316,
          parent_id: 24,
          region_name: "铜川",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2649,
              parent_id: 316,
              region_name: "耀州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2650,
              parent_id: 316,
              region_name: "王益区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2651,
              parent_id: 316,
              region_name: "印台区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2652,
              parent_id: 316,
              region_name: "宜君县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 317,
          parent_id: 24,
          region_name: "渭南",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2653,
              parent_id: 317,
              region_name: "临渭区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2654,
              parent_id: 317,
              region_name: "韩城市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2655,
              parent_id: 317,
              region_name: "华阴市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2656,
              parent_id: 317,
              region_name: "华县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2657,
              parent_id: 317,
              region_name: "潼关县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2658,
              parent_id: 317,
              region_name: "大荔县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2659,
              parent_id: 317,
              region_name: "合阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2660,
              parent_id: 317,
              region_name: "澄城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2661,
              parent_id: 317,
              region_name: "蒲城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2662,
              parent_id: 317,
              region_name: "白水县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2663,
              parent_id: 317,
              region_name: "富平县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 318,
          parent_id: 24,
          region_name: "咸阳",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2664,
              parent_id: 318,
              region_name: "秦都区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2665,
              parent_id: 318,
              region_name: "渭城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2666,
              parent_id: 318,
              region_name: "杨陵区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2667,
              parent_id: 318,
              region_name: "兴平市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2668,
              parent_id: 318,
              region_name: "三原县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2669,
              parent_id: 318,
              region_name: "泾阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2670,
              parent_id: 318,
              region_name: "乾县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2671,
              parent_id: 318,
              region_name: "礼泉县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2672,
              parent_id: 318,
              region_name: "永寿县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2673,
              parent_id: 318,
              region_name: "彬县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2674,
              parent_id: 318,
              region_name: "长武县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2675,
              parent_id: 318,
              region_name: "旬邑县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2676,
              parent_id: 318,
              region_name: "淳化县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2677,
              parent_id: 318,
              region_name: "武功县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 319,
          parent_id: 24,
          region_name: "延安",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2678,
              parent_id: 319,
              region_name: "吴起县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2679,
              parent_id: 319,
              region_name: "宝塔区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2680,
              parent_id: 319,
              region_name: "延长县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2681,
              parent_id: 319,
              region_name: "延川县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2682,
              parent_id: 319,
              region_name: "子长县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2683,
              parent_id: 319,
              region_name: "安塞县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2684,
              parent_id: 319,
              region_name: "志丹县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2685,
              parent_id: 319,
              region_name: "甘泉县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2686,
              parent_id: 319,
              region_name: "富县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2687,
              parent_id: 319,
              region_name: "洛川县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2688,
              parent_id: 319,
              region_name: "宜川县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2689,
              parent_id: 319,
              region_name: "黄龙县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2690,
              parent_id: 319,
              region_name: "黄陵县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 320,
          parent_id: 24,
          region_name: "榆林",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2691,
              parent_id: 320,
              region_name: "榆阳区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2692,
              parent_id: 320,
              region_name: "神木县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2693,
              parent_id: 320,
              region_name: "府谷县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2694,
              parent_id: 320,
              region_name: "横山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2695,
              parent_id: 320,
              region_name: "靖边县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2696,
              parent_id: 320,
              region_name: "定边县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2697,
              parent_id: 320,
              region_name: "绥德县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2698,
              parent_id: 320,
              region_name: "米脂县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2699,
              parent_id: 320,
              region_name: "佳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2700,
              parent_id: 320,
              region_name: "吴堡县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2701,
              parent_id: 320,
              region_name: "清涧县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2702,
              parent_id: 320,
              region_name: "子洲县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 25,
      parent_id: 1,
      region_name: "上海",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 321,
          parent_id: 25,
          region_name: "上海",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2703,
              parent_id: 321,
              region_name: "长宁区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2704,
              parent_id: 321,
              region_name: "闸北区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2705,
              parent_id: 321,
              region_name: "闵行区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2706,
              parent_id: 321,
              region_name: "徐汇区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2707,
              parent_id: 321,
              region_name: "浦东新区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2708,
              parent_id: 321,
              region_name: "杨浦区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2709,
              parent_id: 321,
              region_name: "普陀区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2710,
              parent_id: 321,
              region_name: "静安区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2711,
              parent_id: 321,
              region_name: "卢湾区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2712,
              parent_id: 321,
              region_name: "虹口区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2713,
              parent_id: 321,
              region_name: "黄浦区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2714,
              parent_id: 321,
              region_name: "南汇区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2715,
              parent_id: 321,
              region_name: "松江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2716,
              parent_id: 321,
              region_name: "嘉定区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2717,
              parent_id: 321,
              region_name: "宝山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2718,
              parent_id: 321,
              region_name: "青浦区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2719,
              parent_id: 321,
              region_name: "金山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2720,
              parent_id: 321,
              region_name: "奉贤区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2721,
              parent_id: 321,
              region_name: "崇明县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 26,
      parent_id: 1,
      region_name: "四川",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 322,
          parent_id: 26,
          region_name: "成都",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2722,
              parent_id: 322,
              region_name: "青羊区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2723,
              parent_id: 322,
              region_name: "锦江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2724,
              parent_id: 322,
              region_name: "金牛区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2725,
              parent_id: 322,
              region_name: "武侯区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2726,
              parent_id: 322,
              region_name: "成华区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2727,
              parent_id: 322,
              region_name: "龙泉驿区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2728,
              parent_id: 322,
              region_name: "青白江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2729,
              parent_id: 322,
              region_name: "新都区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2730,
              parent_id: 322,
              region_name: "温江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2731,
              parent_id: 322,
              region_name: "高新区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2732,
              parent_id: 322,
              region_name: "高新西区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2733,
              parent_id: 322,
              region_name: "都江堰市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2734,
              parent_id: 322,
              region_name: "彭州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2735,
              parent_id: 322,
              region_name: "邛崃市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2736,
              parent_id: 322,
              region_name: "崇州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2737,
              parent_id: 322,
              region_name: "金堂县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2738,
              parent_id: 322,
              region_name: "双流县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2739,
              parent_id: 322,
              region_name: "郫县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2740,
              parent_id: 322,
              region_name: "大邑县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2741,
              parent_id: 322,
              region_name: "蒲江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2742,
              parent_id: 322,
              region_name: "新津县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2743,
              parent_id: 322,
              region_name: "都江堰市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2744,
              parent_id: 322,
              region_name: "彭州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2745,
              parent_id: 322,
              region_name: "邛崃市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2746,
              parent_id: 322,
              region_name: "崇州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2747,
              parent_id: 322,
              region_name: "金堂县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2748,
              parent_id: 322,
              region_name: "双流县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2749,
              parent_id: 322,
              region_name: "郫县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2750,
              parent_id: 322,
              region_name: "大邑县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2751,
              parent_id: 322,
              region_name: "蒲江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2752,
              parent_id: 322,
              region_name: "新津县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 323,
          parent_id: 26,
          region_name: "绵阳",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2753,
              parent_id: 323,
              region_name: "涪城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2754,
              parent_id: 323,
              region_name: "游仙区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2755,
              parent_id: 323,
              region_name: "江油市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2756,
              parent_id: 323,
              region_name: "盐亭县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2757,
              parent_id: 323,
              region_name: "三台县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2758,
              parent_id: 323,
              region_name: "平武县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2759,
              parent_id: 323,
              region_name: "安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2760,
              parent_id: 323,
              region_name: "梓潼县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2761,
              parent_id: 323,
              region_name: "北川县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 324,
          parent_id: 26,
          region_name: "阿坝",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2762,
              parent_id: 324,
              region_name: "马尔康县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2763,
              parent_id: 324,
              region_name: "汶川县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2764,
              parent_id: 324,
              region_name: "理县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2765,
              parent_id: 324,
              region_name: "茂县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2766,
              parent_id: 324,
              region_name: "松潘县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2767,
              parent_id: 324,
              region_name: "九寨沟县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2768,
              parent_id: 324,
              region_name: "金川县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2769,
              parent_id: 324,
              region_name: "小金县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2770,
              parent_id: 324,
              region_name: "黑水县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2771,
              parent_id: 324,
              region_name: "壤塘县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2772,
              parent_id: 324,
              region_name: "阿坝县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2773,
              parent_id: 324,
              region_name: "若尔盖县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2774,
              parent_id: 324,
              region_name: "红原县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 325,
          parent_id: 26,
          region_name: "巴中",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2775,
              parent_id: 325,
              region_name: "巴州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2776,
              parent_id: 325,
              region_name: "通江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2777,
              parent_id: 325,
              region_name: "南江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2778,
              parent_id: 325,
              region_name: "平昌县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 326,
          parent_id: 26,
          region_name: "达州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2779,
              parent_id: 326,
              region_name: "通川区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2780,
              parent_id: 326,
              region_name: "万源市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2781,
              parent_id: 326,
              region_name: "达县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2782,
              parent_id: 326,
              region_name: "宣汉县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2783,
              parent_id: 326,
              region_name: "开江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2784,
              parent_id: 326,
              region_name: "大竹县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2785,
              parent_id: 326,
              region_name: "渠县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 327,
          parent_id: 26,
          region_name: "德阳",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2786,
              parent_id: 327,
              region_name: "旌阳区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2787,
              parent_id: 327,
              region_name: "广汉市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2788,
              parent_id: 327,
              region_name: "什邡市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2789,
              parent_id: 327,
              region_name: "绵竹市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2790,
              parent_id: 327,
              region_name: "罗江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2791,
              parent_id: 327,
              region_name: "中江县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 328,
          parent_id: 26,
          region_name: "甘孜",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2792,
              parent_id: 328,
              region_name: "康定县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2793,
              parent_id: 328,
              region_name: "丹巴县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2794,
              parent_id: 328,
              region_name: "泸定县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2795,
              parent_id: 328,
              region_name: "炉霍县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2796,
              parent_id: 328,
              region_name: "九龙县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2797,
              parent_id: 328,
              region_name: "甘孜县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2798,
              parent_id: 328,
              region_name: "雅江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2799,
              parent_id: 328,
              region_name: "新龙县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2800,
              parent_id: 328,
              region_name: "道孚县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2801,
              parent_id: 328,
              region_name: "白玉县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2802,
              parent_id: 328,
              region_name: "理塘县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2803,
              parent_id: 328,
              region_name: "德格县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2804,
              parent_id: 328,
              region_name: "乡城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2805,
              parent_id: 328,
              region_name: "石渠县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2806,
              parent_id: 328,
              region_name: "稻城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2807,
              parent_id: 328,
              region_name: "色达县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2808,
              parent_id: 328,
              region_name: "巴塘县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2809,
              parent_id: 328,
              region_name: "得荣县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 329,
          parent_id: 26,
          region_name: "广安",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2810,
              parent_id: 329,
              region_name: "广安区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2811,
              parent_id: 329,
              region_name: "华蓥市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2812,
              parent_id: 329,
              region_name: "岳池县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2813,
              parent_id: 329,
              region_name: "武胜县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2814,
              parent_id: 329,
              region_name: "邻水县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 330,
          parent_id: 26,
          region_name: "广元",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2815,
              parent_id: 330,
              region_name: "利州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2816,
              parent_id: 330,
              region_name: "元坝区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2817,
              parent_id: 330,
              region_name: "朝天区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2818,
              parent_id: 330,
              region_name: "旺苍县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2819,
              parent_id: 330,
              region_name: "青川县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2820,
              parent_id: 330,
              region_name: "剑阁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2821,
              parent_id: 330,
              region_name: "苍溪县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 331,
          parent_id: 26,
          region_name: "乐山",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2822,
              parent_id: 331,
              region_name: "峨眉山市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2823,
              parent_id: 331,
              region_name: "乐山市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2824,
              parent_id: 331,
              region_name: "犍为县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2825,
              parent_id: 331,
              region_name: "井研县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2826,
              parent_id: 331,
              region_name: "夹江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2827,
              parent_id: 331,
              region_name: "沐川县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2828,
              parent_id: 331,
              region_name: "峨边",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2829,
              parent_id: 331,
              region_name: "马边",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 332,
          parent_id: 26,
          region_name: "凉山",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2830,
              parent_id: 332,
              region_name: "西昌市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2831,
              parent_id: 332,
              region_name: "盐源县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2832,
              parent_id: 332,
              region_name: "德昌县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2833,
              parent_id: 332,
              region_name: "会理县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2834,
              parent_id: 332,
              region_name: "会东县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2835,
              parent_id: 332,
              region_name: "宁南县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2836,
              parent_id: 332,
              region_name: "普格县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2837,
              parent_id: 332,
              region_name: "布拖县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2838,
              parent_id: 332,
              region_name: "金阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2839,
              parent_id: 332,
              region_name: "昭觉县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2840,
              parent_id: 332,
              region_name: "喜德县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2841,
              parent_id: 332,
              region_name: "冕宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2842,
              parent_id: 332,
              region_name: "越西县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2843,
              parent_id: 332,
              region_name: "甘洛县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2844,
              parent_id: 332,
              region_name: "美姑县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2845,
              parent_id: 332,
              region_name: "雷波县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2846,
              parent_id: 332,
              region_name: "木里",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 333,
          parent_id: 26,
          region_name: "眉山",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2847,
              parent_id: 333,
              region_name: "东坡区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2848,
              parent_id: 333,
              region_name: "仁寿县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2849,
              parent_id: 333,
              region_name: "彭山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2850,
              parent_id: 333,
              region_name: "洪雅县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2851,
              parent_id: 333,
              region_name: "丹棱县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2852,
              parent_id: 333,
              region_name: "青神县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 334,
          parent_id: 26,
          region_name: "南充",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2853,
              parent_id: 334,
              region_name: "阆中市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2854,
              parent_id: 334,
              region_name: "南部县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2855,
              parent_id: 334,
              region_name: "营山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2856,
              parent_id: 334,
              region_name: "蓬安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2857,
              parent_id: 334,
              region_name: "仪陇县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2858,
              parent_id: 334,
              region_name: "顺庆区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2859,
              parent_id: 334,
              region_name: "高坪区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2860,
              parent_id: 334,
              region_name: "嘉陵区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2861,
              parent_id: 334,
              region_name: "西充县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 335,
          parent_id: 26,
          region_name: "内江",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2862,
              parent_id: 335,
              region_name: "市中区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2863,
              parent_id: 335,
              region_name: "东兴区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2864,
              parent_id: 335,
              region_name: "威远县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2865,
              parent_id: 335,
              region_name: "资中县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2866,
              parent_id: 335,
              region_name: "隆昌县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 336,
          parent_id: 26,
          region_name: "攀枝花",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2867,
              parent_id: 336,
              region_name: "东  区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2868,
              parent_id: 336,
              region_name: "西  区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2869,
              parent_id: 336,
              region_name: "仁和区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2870,
              parent_id: 336,
              region_name: "米易县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2871,
              parent_id: 336,
              region_name: "盐边县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 337,
          parent_id: 26,
          region_name: "遂宁",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2872,
              parent_id: 337,
              region_name: "船山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2873,
              parent_id: 337,
              region_name: "安居区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2874,
              parent_id: 337,
              region_name: "蓬溪县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2875,
              parent_id: 337,
              region_name: "射洪县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2876,
              parent_id: 337,
              region_name: "大英县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 338,
          parent_id: 26,
          region_name: "雅安",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2877,
              parent_id: 338,
              region_name: "雨城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2878,
              parent_id: 338,
              region_name: "名山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2879,
              parent_id: 338,
              region_name: "荥经县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2880,
              parent_id: 338,
              region_name: "汉源县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2881,
              parent_id: 338,
              region_name: "石棉县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2882,
              parent_id: 338,
              region_name: "天全县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2883,
              parent_id: 338,
              region_name: "芦山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2884,
              parent_id: 338,
              region_name: "宝兴县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 339,
          parent_id: 26,
          region_name: "宜宾",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2885,
              parent_id: 339,
              region_name: "翠屏区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2886,
              parent_id: 339,
              region_name: "宜宾县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2887,
              parent_id: 339,
              region_name: "南溪县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2888,
              parent_id: 339,
              region_name: "江安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2889,
              parent_id: 339,
              region_name: "长宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2890,
              parent_id: 339,
              region_name: "高县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2891,
              parent_id: 339,
              region_name: "珙县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2892,
              parent_id: 339,
              region_name: "筠连县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2893,
              parent_id: 339,
              region_name: "兴文县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2894,
              parent_id: 339,
              region_name: "屏山县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 340,
          parent_id: 26,
          region_name: "资阳",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2895,
              parent_id: 340,
              region_name: "雁江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2896,
              parent_id: 340,
              region_name: "简阳市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2897,
              parent_id: 340,
              region_name: "安岳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2898,
              parent_id: 340,
              region_name: "乐至县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 341,
          parent_id: 26,
          region_name: "自贡",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2899,
              parent_id: 341,
              region_name: "大安区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2900,
              parent_id: 341,
              region_name: "自流井区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2901,
              parent_id: 341,
              region_name: "贡井区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2902,
              parent_id: 341,
              region_name: "沿滩区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2903,
              parent_id: 341,
              region_name: "荣县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2904,
              parent_id: 341,
              region_name: "富顺县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 342,
          parent_id: 26,
          region_name: "泸州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2905,
              parent_id: 342,
              region_name: "江阳区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2906,
              parent_id: 342,
              region_name: "纳溪区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2907,
              parent_id: 342,
              region_name: "龙马潭区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2908,
              parent_id: 342,
              region_name: "泸县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2909,
              parent_id: 342,
              region_name: "合江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2910,
              parent_id: 342,
              region_name: "叙永县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2911,
              parent_id: 342,
              region_name: "古蔺县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 27,
      parent_id: 1,
      region_name: "天津",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 343,
          parent_id: 27,
          region_name: "天津",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2912,
              parent_id: 343,
              region_name: "和平区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2913,
              parent_id: 343,
              region_name: "河西区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2914,
              parent_id: 343,
              region_name: "南开区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2915,
              parent_id: 343,
              region_name: "河北区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2916,
              parent_id: 343,
              region_name: "河东区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2917,
              parent_id: 343,
              region_name: "红桥区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2918,
              parent_id: 343,
              region_name: "东丽区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2919,
              parent_id: 343,
              region_name: "津南区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2920,
              parent_id: 343,
              region_name: "西青区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2921,
              parent_id: 343,
              region_name: "北辰区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2922,
              parent_id: 343,
              region_name: "塘沽区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2923,
              parent_id: 343,
              region_name: "汉沽区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2924,
              parent_id: 343,
              region_name: "大港区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2925,
              parent_id: 343,
              region_name: "武清区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2926,
              parent_id: 343,
              region_name: "宝坻区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2927,
              parent_id: 343,
              region_name: "经济开发区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2928,
              parent_id: 343,
              region_name: "宁河县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2929,
              parent_id: 343,
              region_name: "静海县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2930,
              parent_id: 343,
              region_name: "蓟县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 28,
      parent_id: 1,
      region_name: "西藏",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 344,
          parent_id: 28,
          region_name: "拉萨",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2931,
              parent_id: 344,
              region_name: "城关区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2932,
              parent_id: 344,
              region_name: "林周县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2933,
              parent_id: 344,
              region_name: "当雄县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2934,
              parent_id: 344,
              region_name: "尼木县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2935,
              parent_id: 344,
              region_name: "曲水县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2936,
              parent_id: 344,
              region_name: "堆龙德庆县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2937,
              parent_id: 344,
              region_name: "达孜县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2938,
              parent_id: 344,
              region_name: "墨竹工卡县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 345,
          parent_id: 28,
          region_name: "阿里",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2939,
              parent_id: 345,
              region_name: "噶尔县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2940,
              parent_id: 345,
              region_name: "普兰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2941,
              parent_id: 345,
              region_name: "札达县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2942,
              parent_id: 345,
              region_name: "日土县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2943,
              parent_id: 345,
              region_name: "革吉县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2944,
              parent_id: 345,
              region_name: "改则县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2945,
              parent_id: 345,
              region_name: "措勤县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 346,
          parent_id: 28,
          region_name: "昌都",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2946,
              parent_id: 346,
              region_name: "昌都县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2947,
              parent_id: 346,
              region_name: "江达县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2948,
              parent_id: 346,
              region_name: "贡觉县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2949,
              parent_id: 346,
              region_name: "类乌齐县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2950,
              parent_id: 346,
              region_name: "丁青县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2951,
              parent_id: 346,
              region_name: "察雅县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2952,
              parent_id: 346,
              region_name: "八宿县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2953,
              parent_id: 346,
              region_name: "左贡县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2954,
              parent_id: 346,
              region_name: "芒康县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2955,
              parent_id: 346,
              region_name: "洛隆县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2956,
              parent_id: 346,
              region_name: "边坝县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 347,
          parent_id: 28,
          region_name: "林芝",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2957,
              parent_id: 347,
              region_name: "林芝县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2958,
              parent_id: 347,
              region_name: "工布江达县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2959,
              parent_id: 347,
              region_name: "米林县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2960,
              parent_id: 347,
              region_name: "墨脱县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2961,
              parent_id: 347,
              region_name: "波密县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2962,
              parent_id: 347,
              region_name: "察隅县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2963,
              parent_id: 347,
              region_name: "朗县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 348,
          parent_id: 28,
          region_name: "那曲",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2964,
              parent_id: 348,
              region_name: "那曲县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2965,
              parent_id: 348,
              region_name: "嘉黎县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2966,
              parent_id: 348,
              region_name: "比如县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2967,
              parent_id: 348,
              region_name: "聂荣县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2968,
              parent_id: 348,
              region_name: "安多县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2969,
              parent_id: 348,
              region_name: "申扎县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2970,
              parent_id: 348,
              region_name: "索县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2971,
              parent_id: 348,
              region_name: "班戈县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2972,
              parent_id: 348,
              region_name: "巴青县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2973,
              parent_id: 348,
              region_name: "尼玛县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 349,
          parent_id: 28,
          region_name: "日喀则",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2974,
              parent_id: 349,
              region_name: "日喀则市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2975,
              parent_id: 349,
              region_name: "南木林县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2976,
              parent_id: 349,
              region_name: "江孜县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2977,
              parent_id: 349,
              region_name: "定日县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2978,
              parent_id: 349,
              region_name: "萨迦县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2979,
              parent_id: 349,
              region_name: "拉孜县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2980,
              parent_id: 349,
              region_name: "昂仁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2981,
              parent_id: 349,
              region_name: "谢通门县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2982,
              parent_id: 349,
              region_name: "白朗县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2983,
              parent_id: 349,
              region_name: "仁布县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2984,
              parent_id: 349,
              region_name: "康马县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2985,
              parent_id: 349,
              region_name: "定结县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2986,
              parent_id: 349,
              region_name: "仲巴县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2987,
              parent_id: 349,
              region_name: "亚东县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2988,
              parent_id: 349,
              region_name: "吉隆县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2989,
              parent_id: 349,
              region_name: "聂拉木县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2990,
              parent_id: 349,
              region_name: "萨嘎县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2991,
              parent_id: 349,
              region_name: "岗巴县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 350,
          parent_id: 28,
          region_name: "山南",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 2992,
              parent_id: 350,
              region_name: "乃东县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2993,
              parent_id: 350,
              region_name: "扎囊县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2994,
              parent_id: 350,
              region_name: "贡嘎县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2995,
              parent_id: 350,
              region_name: "桑日县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2996,
              parent_id: 350,
              region_name: "琼结县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2997,
              parent_id: 350,
              region_name: "曲松县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2998,
              parent_id: 350,
              region_name: "措美县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 2999,
              parent_id: 350,
              region_name: "洛扎县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3000,
              parent_id: 350,
              region_name: "加查县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3001,
              parent_id: 350,
              region_name: "隆子县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3002,
              parent_id: 350,
              region_name: "错那县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3003,
              parent_id: 350,
              region_name: "浪卡子县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 29,
      parent_id: 1,
      region_name: "新疆",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 351,
          parent_id: 29,
          region_name: "乌鲁木齐",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3004,
              parent_id: 351,
              region_name: "天山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3005,
              parent_id: 351,
              region_name: "沙依巴克区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3006,
              parent_id: 351,
              region_name: "新市区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3007,
              parent_id: 351,
              region_name: "水磨沟区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3008,
              parent_id: 351,
              region_name: "头屯河区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3009,
              parent_id: 351,
              region_name: "达坂城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3010,
              parent_id: 351,
              region_name: "米东区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3011,
              parent_id: 351,
              region_name: "乌鲁木齐县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 352,
          parent_id: 29,
          region_name: "阿克苏",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3012,
              parent_id: 352,
              region_name: "阿克苏市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3013,
              parent_id: 352,
              region_name: "温宿县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3014,
              parent_id: 352,
              region_name: "库车县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3015,
              parent_id: 352,
              region_name: "沙雅县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3016,
              parent_id: 352,
              region_name: "新和县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3017,
              parent_id: 352,
              region_name: "拜城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3018,
              parent_id: 352,
              region_name: "乌什县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3019,
              parent_id: 352,
              region_name: "阿瓦提县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3020,
              parent_id: 352,
              region_name: "柯坪县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 353,
          parent_id: 29,
          region_name: "阿拉尔",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3021,
              parent_id: 353,
              region_name: "阿拉尔市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 354,
          parent_id: 29,
          region_name: "巴音郭楞",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3022,
              parent_id: 354,
              region_name: "库尔勒市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3023,
              parent_id: 354,
              region_name: "轮台县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3024,
              parent_id: 354,
              region_name: "尉犁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3025,
              parent_id: 354,
              region_name: "若羌县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3026,
              parent_id: 354,
              region_name: "且末县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3027,
              parent_id: 354,
              region_name: "焉耆",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3028,
              parent_id: 354,
              region_name: "和静县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3029,
              parent_id: 354,
              region_name: "和硕县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3030,
              parent_id: 354,
              region_name: "博湖县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 355,
          parent_id: 29,
          region_name: "博尔塔拉",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3031,
              parent_id: 355,
              region_name: "博乐市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3032,
              parent_id: 355,
              region_name: "精河县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3033,
              parent_id: 355,
              region_name: "温泉县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 356,
          parent_id: 29,
          region_name: "昌吉",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3034,
              parent_id: 356,
              region_name: "呼图壁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3035,
              parent_id: 356,
              region_name: "米泉市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3036,
              parent_id: 356,
              region_name: "昌吉市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3037,
              parent_id: 356,
              region_name: "阜康市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3038,
              parent_id: 356,
              region_name: "玛纳斯县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3039,
              parent_id: 356,
              region_name: "奇台县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3040,
              parent_id: 356,
              region_name: "吉木萨尔县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3041,
              parent_id: 356,
              region_name: "木垒",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 357,
          parent_id: 29,
          region_name: "哈密",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3042,
              parent_id: 357,
              region_name: "哈密市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3043,
              parent_id: 357,
              region_name: "伊吾县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3044,
              parent_id: 357,
              region_name: "巴里坤",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 358,
          parent_id: 29,
          region_name: "和田",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3045,
              parent_id: 358,
              region_name: "和田市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3046,
              parent_id: 358,
              region_name: "和田县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3047,
              parent_id: 358,
              region_name: "墨玉县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3048,
              parent_id: 358,
              region_name: "皮山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3049,
              parent_id: 358,
              region_name: "洛浦县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3050,
              parent_id: 358,
              region_name: "策勒县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3051,
              parent_id: 358,
              region_name: "于田县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3052,
              parent_id: 358,
              region_name: "民丰县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 359,
          parent_id: 29,
          region_name: "喀什",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3053,
              parent_id: 359,
              region_name: "喀什市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3054,
              parent_id: 359,
              region_name: "疏附县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3055,
              parent_id: 359,
              region_name: "疏勒县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3056,
              parent_id: 359,
              region_name: "英吉沙县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3057,
              parent_id: 359,
              region_name: "泽普县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3058,
              parent_id: 359,
              region_name: "莎车县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3059,
              parent_id: 359,
              region_name: "叶城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3060,
              parent_id: 359,
              region_name: "麦盖提县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3061,
              parent_id: 359,
              region_name: "岳普湖县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3062,
              parent_id: 359,
              region_name: "伽师县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3063,
              parent_id: 359,
              region_name: "巴楚县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3064,
              parent_id: 359,
              region_name: "塔什库尔干",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 360,
          parent_id: 29,
          region_name: "克拉玛依",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3065,
              parent_id: 360,
              region_name: "克拉玛依市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 361,
          parent_id: 29,
          region_name: "克孜勒苏",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3066,
              parent_id: 361,
              region_name: "阿图什市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3067,
              parent_id: 361,
              region_name: "阿克陶县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3068,
              parent_id: 361,
              region_name: "阿合奇县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3069,
              parent_id: 361,
              region_name: "乌恰县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 362,
          parent_id: 29,
          region_name: "石河子",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3070,
              parent_id: 362,
              region_name: "石河子市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 363,
          parent_id: 29,
          region_name: "图木舒克",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3071,
              parent_id: 363,
              region_name: "图木舒克市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 364,
          parent_id: 29,
          region_name: "吐鲁番",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3072,
              parent_id: 364,
              region_name: "吐鲁番市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3073,
              parent_id: 364,
              region_name: "鄯善县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3074,
              parent_id: 364,
              region_name: "托克逊县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 365,
          parent_id: 29,
          region_name: "五家渠",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3075,
              parent_id: 365,
              region_name: "五家渠市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 366,
          parent_id: 29,
          region_name: "伊犁",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3076,
              parent_id: 366,
              region_name: "阿勒泰市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3077,
              parent_id: 366,
              region_name: "布克赛尔",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3078,
              parent_id: 366,
              region_name: "伊宁市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3079,
              parent_id: 366,
              region_name: "布尔津县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3080,
              parent_id: 366,
              region_name: "奎屯市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3081,
              parent_id: 366,
              region_name: "乌苏市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3082,
              parent_id: 366,
              region_name: "额敏县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3083,
              parent_id: 366,
              region_name: "富蕴县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3084,
              parent_id: 366,
              region_name: "伊宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3085,
              parent_id: 366,
              region_name: "福海县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3086,
              parent_id: 366,
              region_name: "霍城县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3087,
              parent_id: 366,
              region_name: "沙湾县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3088,
              parent_id: 366,
              region_name: "巩留县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3089,
              parent_id: 366,
              region_name: "哈巴河县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3090,
              parent_id: 366,
              region_name: "托里县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3091,
              parent_id: 366,
              region_name: "青河县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3092,
              parent_id: 366,
              region_name: "新源县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3093,
              parent_id: 366,
              region_name: "裕民县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3094,
              parent_id: 366,
              region_name: "和布克赛尔",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3095,
              parent_id: 366,
              region_name: "吉木乃县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3096,
              parent_id: 366,
              region_name: "昭苏县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3097,
              parent_id: 366,
              region_name: "特克斯县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3098,
              parent_id: 366,
              region_name: "尼勒克县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3099,
              parent_id: 366,
              region_name: "察布查尔",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 30,
      parent_id: 1,
      region_name: "云南",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 367,
          parent_id: 30,
          region_name: "昆明",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3100,
              parent_id: 367,
              region_name: "盘龙区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3101,
              parent_id: 367,
              region_name: "五华区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3102,
              parent_id: 367,
              region_name: "官渡区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3103,
              parent_id: 367,
              region_name: "西山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3104,
              parent_id: 367,
              region_name: "东川区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3105,
              parent_id: 367,
              region_name: "安宁市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3106,
              parent_id: 367,
              region_name: "呈贡县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3107,
              parent_id: 367,
              region_name: "晋宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3108,
              parent_id: 367,
              region_name: "富民县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3109,
              parent_id: 367,
              region_name: "宜良县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3110,
              parent_id: 367,
              region_name: "嵩明县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3111,
              parent_id: 367,
              region_name: "石林县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3112,
              parent_id: 367,
              region_name: "禄劝",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3113,
              parent_id: 367,
              region_name: "寻甸",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 368,
          parent_id: 30,
          region_name: "怒江",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3114,
              parent_id: 368,
              region_name: "兰坪",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3115,
              parent_id: 368,
              region_name: "泸水县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3116,
              parent_id: 368,
              region_name: "福贡县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3117,
              parent_id: 368,
              region_name: "贡山",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 369,
          parent_id: 30,
          region_name: "普洱",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3118,
              parent_id: 369,
              region_name: "宁洱",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3119,
              parent_id: 369,
              region_name: "思茅区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3120,
              parent_id: 369,
              region_name: "墨江",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3121,
              parent_id: 369,
              region_name: "景东",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3122,
              parent_id: 369,
              region_name: "景谷",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3123,
              parent_id: 369,
              region_name: "镇沅",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3124,
              parent_id: 369,
              region_name: "江城",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3125,
              parent_id: 369,
              region_name: "孟连",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3126,
              parent_id: 369,
              region_name: "澜沧",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3127,
              parent_id: 369,
              region_name: "西盟",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 370,
          parent_id: 30,
          region_name: "丽江",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3128,
              parent_id: 370,
              region_name: "古城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3129,
              parent_id: 370,
              region_name: "宁蒗",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3130,
              parent_id: 370,
              region_name: "玉龙",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3131,
              parent_id: 370,
              region_name: "永胜县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3132,
              parent_id: 370,
              region_name: "华坪县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 371,
          parent_id: 30,
          region_name: "保山",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3133,
              parent_id: 371,
              region_name: "隆阳区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3134,
              parent_id: 371,
              region_name: "施甸县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3135,
              parent_id: 371,
              region_name: "腾冲县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3136,
              parent_id: 371,
              region_name: "龙陵县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3137,
              parent_id: 371,
              region_name: "昌宁县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 372,
          parent_id: 30,
          region_name: "楚雄",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3138,
              parent_id: 372,
              region_name: "楚雄市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3139,
              parent_id: 372,
              region_name: "双柏县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3140,
              parent_id: 372,
              region_name: "牟定县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3141,
              parent_id: 372,
              region_name: "南华县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3142,
              parent_id: 372,
              region_name: "姚安县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3143,
              parent_id: 372,
              region_name: "大姚县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3144,
              parent_id: 372,
              region_name: "永仁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3145,
              parent_id: 372,
              region_name: "元谋县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3146,
              parent_id: 372,
              region_name: "武定县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3147,
              parent_id: 372,
              region_name: "禄丰县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 373,
          parent_id: 30,
          region_name: "大理",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3148,
              parent_id: 373,
              region_name: "大理市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3149,
              parent_id: 373,
              region_name: "祥云县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3150,
              parent_id: 373,
              region_name: "宾川县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3151,
              parent_id: 373,
              region_name: "弥渡县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3152,
              parent_id: 373,
              region_name: "永平县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3153,
              parent_id: 373,
              region_name: "云龙县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3154,
              parent_id: 373,
              region_name: "洱源县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3155,
              parent_id: 373,
              region_name: "剑川县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3156,
              parent_id: 373,
              region_name: "鹤庆县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3157,
              parent_id: 373,
              region_name: "漾濞",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3158,
              parent_id: 373,
              region_name: "南涧",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3159,
              parent_id: 373,
              region_name: "巍山",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 374,
          parent_id: 30,
          region_name: "德宏",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3160,
              parent_id: 374,
              region_name: "潞西市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3161,
              parent_id: 374,
              region_name: "瑞丽市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3162,
              parent_id: 374,
              region_name: "梁河县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3163,
              parent_id: 374,
              region_name: "盈江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3164,
              parent_id: 374,
              region_name: "陇川县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 375,
          parent_id: 30,
          region_name: "迪庆",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3165,
              parent_id: 375,
              region_name: "香格里拉县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3166,
              parent_id: 375,
              region_name: "德钦县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3167,
              parent_id: 375,
              region_name: "维西",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 376,
          parent_id: 30,
          region_name: "红河",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3168,
              parent_id: 376,
              region_name: "泸西县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3169,
              parent_id: 376,
              region_name: "蒙自县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3170,
              parent_id: 376,
              region_name: "个旧市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3171,
              parent_id: 376,
              region_name: "开远市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3172,
              parent_id: 376,
              region_name: "绿春县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3173,
              parent_id: 376,
              region_name: "建水县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3174,
              parent_id: 376,
              region_name: "石屏县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3175,
              parent_id: 376,
              region_name: "弥勒县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3176,
              parent_id: 376,
              region_name: "元阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3177,
              parent_id: 376,
              region_name: "红河县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3178,
              parent_id: 376,
              region_name: "金平",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3179,
              parent_id: 376,
              region_name: "河口",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3180,
              parent_id: 376,
              region_name: "屏边",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 377,
          parent_id: 30,
          region_name: "临沧",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3181,
              parent_id: 377,
              region_name: "临翔区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3182,
              parent_id: 377,
              region_name: "凤庆县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3183,
              parent_id: 377,
              region_name: "云县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3184,
              parent_id: 377,
              region_name: "永德县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3185,
              parent_id: 377,
              region_name: "镇康县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3186,
              parent_id: 377,
              region_name: "双江",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3187,
              parent_id: 377,
              region_name: "耿马",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3188,
              parent_id: 377,
              region_name: "沧源",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 378,
          parent_id: 30,
          region_name: "曲靖",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3189,
              parent_id: 378,
              region_name: "麒麟区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3190,
              parent_id: 378,
              region_name: "宣威市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3191,
              parent_id: 378,
              region_name: "马龙县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3192,
              parent_id: 378,
              region_name: "陆良县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3193,
              parent_id: 378,
              region_name: "师宗县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3194,
              parent_id: 378,
              region_name: "罗平县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3195,
              parent_id: 378,
              region_name: "富源县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3196,
              parent_id: 378,
              region_name: "会泽县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3197,
              parent_id: 378,
              region_name: "沾益县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 379,
          parent_id: 30,
          region_name: "文山",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3198,
              parent_id: 379,
              region_name: "文山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3199,
              parent_id: 379,
              region_name: "砚山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3200,
              parent_id: 379,
              region_name: "西畴县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3201,
              parent_id: 379,
              region_name: "麻栗坡县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3202,
              parent_id: 379,
              region_name: "马关县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3203,
              parent_id: 379,
              region_name: "丘北县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3204,
              parent_id: 379,
              region_name: "广南县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3205,
              parent_id: 379,
              region_name: "富宁县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 380,
          parent_id: 30,
          region_name: "西双版纳",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3206,
              parent_id: 380,
              region_name: "景洪市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3207,
              parent_id: 380,
              region_name: "勐海县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3208,
              parent_id: 380,
              region_name: "勐腊县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 381,
          parent_id: 30,
          region_name: "玉溪",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3209,
              parent_id: 381,
              region_name: "红塔区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3210,
              parent_id: 381,
              region_name: "江川县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3211,
              parent_id: 381,
              region_name: "澄江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3212,
              parent_id: 381,
              region_name: "通海县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3213,
              parent_id: 381,
              region_name: "华宁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3214,
              parent_id: 381,
              region_name: "易门县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3215,
              parent_id: 381,
              region_name: "峨山",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3216,
              parent_id: 381,
              region_name: "新平",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3217,
              parent_id: 381,
              region_name: "元江",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 382,
          parent_id: 30,
          region_name: "昭通",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3218,
              parent_id: 382,
              region_name: "昭阳区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3219,
              parent_id: 382,
              region_name: "鲁甸县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3220,
              parent_id: 382,
              region_name: "巧家县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3221,
              parent_id: 382,
              region_name: "盐津县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3222,
              parent_id: 382,
              region_name: "大关县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3223,
              parent_id: 382,
              region_name: "永善县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3224,
              parent_id: 382,
              region_name: "绥江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3225,
              parent_id: 382,
              region_name: "镇雄县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3226,
              parent_id: 382,
              region_name: "彝良县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3227,
              parent_id: 382,
              region_name: "威信县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3228,
              parent_id: 382,
              region_name: "水富县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 31,
      parent_id: 1,
      region_name: "浙江",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 383,
          parent_id: 31,
          region_name: "杭州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3229,
              parent_id: 383,
              region_name: "西湖区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3230,
              parent_id: 383,
              region_name: "上城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3231,
              parent_id: 383,
              region_name: "下城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3232,
              parent_id: 383,
              region_name: "拱墅区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3233,
              parent_id: 383,
              region_name: "滨江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3234,
              parent_id: 383,
              region_name: "江干区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3235,
              parent_id: 383,
              region_name: "萧山区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3236,
              parent_id: 383,
              region_name: "余杭区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3237,
              parent_id: 383,
              region_name: "市郊",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3238,
              parent_id: 383,
              region_name: "建德市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3239,
              parent_id: 383,
              region_name: "富阳市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3240,
              parent_id: 383,
              region_name: "临安市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3241,
              parent_id: 383,
              region_name: "桐庐县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3242,
              parent_id: 383,
              region_name: "淳安县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 384,
          parent_id: 31,
          region_name: "湖州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3243,
              parent_id: 384,
              region_name: "吴兴区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3244,
              parent_id: 384,
              region_name: "南浔区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3245,
              parent_id: 384,
              region_name: "德清县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3246,
              parent_id: 384,
              region_name: "长兴县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3247,
              parent_id: 384,
              region_name: "安吉县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 385,
          parent_id: 31,
          region_name: "嘉兴",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3248,
              parent_id: 385,
              region_name: "南湖区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3249,
              parent_id: 385,
              region_name: "秀洲区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3250,
              parent_id: 385,
              region_name: "海宁市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3251,
              parent_id: 385,
              region_name: "嘉善县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3252,
              parent_id: 385,
              region_name: "平湖市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3253,
              parent_id: 385,
              region_name: "桐乡市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3254,
              parent_id: 385,
              region_name: "海盐县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 386,
          parent_id: 31,
          region_name: "金华",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3255,
              parent_id: 386,
              region_name: "婺城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3256,
              parent_id: 386,
              region_name: "金东区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3257,
              parent_id: 386,
              region_name: "兰溪市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3258,
              parent_id: 386,
              region_name: "市区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3259,
              parent_id: 386,
              region_name: "佛堂镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3260,
              parent_id: 386,
              region_name: "上溪镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3261,
              parent_id: 386,
              region_name: "义亭镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3262,
              parent_id: 386,
              region_name: "大陈镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3263,
              parent_id: 386,
              region_name: "苏溪镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3264,
              parent_id: 386,
              region_name: "赤岸镇",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3265,
              parent_id: 386,
              region_name: "东阳市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3266,
              parent_id: 386,
              region_name: "永康市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3267,
              parent_id: 386,
              region_name: "武义县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3268,
              parent_id: 386,
              region_name: "浦江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3269,
              parent_id: 386,
              region_name: "磐安县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 387,
          parent_id: 31,
          region_name: "丽水",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3270,
              parent_id: 387,
              region_name: "莲都区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3271,
              parent_id: 387,
              region_name: "龙泉市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3272,
              parent_id: 387,
              region_name: "青田县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3273,
              parent_id: 387,
              region_name: "缙云县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3274,
              parent_id: 387,
              region_name: "遂昌县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3275,
              parent_id: 387,
              region_name: "松阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3276,
              parent_id: 387,
              region_name: "云和县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3277,
              parent_id: 387,
              region_name: "庆元县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3278,
              parent_id: 387,
              region_name: "景宁",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 388,
          parent_id: 31,
          region_name: "宁波",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3279,
              parent_id: 388,
              region_name: "海曙区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3280,
              parent_id: 388,
              region_name: "江东区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3281,
              parent_id: 388,
              region_name: "江北区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3282,
              parent_id: 388,
              region_name: "镇海区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3283,
              parent_id: 388,
              region_name: "北仑区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3284,
              parent_id: 388,
              region_name: "鄞州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3285,
              parent_id: 388,
              region_name: "余姚市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3286,
              parent_id: 388,
              region_name: "慈溪市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3287,
              parent_id: 388,
              region_name: "奉化市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3288,
              parent_id: 388,
              region_name: "象山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3289,
              parent_id: 388,
              region_name: "宁海县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 389,
          parent_id: 31,
          region_name: "绍兴",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3290,
              parent_id: 389,
              region_name: "越城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3291,
              parent_id: 389,
              region_name: "上虞市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3292,
              parent_id: 389,
              region_name: "嵊州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3293,
              parent_id: 389,
              region_name: "绍兴县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3294,
              parent_id: 389,
              region_name: "新昌县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3295,
              parent_id: 389,
              region_name: "诸暨市",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 390,
          parent_id: 31,
          region_name: "台州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3296,
              parent_id: 390,
              region_name: "椒江区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3297,
              parent_id: 390,
              region_name: "黄岩区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3298,
              parent_id: 390,
              region_name: "路桥区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3299,
              parent_id: 390,
              region_name: "温岭市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3300,
              parent_id: 390,
              region_name: "临海市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3301,
              parent_id: 390,
              region_name: "玉环县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3302,
              parent_id: 390,
              region_name: "三门县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3303,
              parent_id: 390,
              region_name: "天台县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3304,
              parent_id: 390,
              region_name: "仙居县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 391,
          parent_id: 31,
          region_name: "温州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3305,
              parent_id: 391,
              region_name: "鹿城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3306,
              parent_id: 391,
              region_name: "龙湾区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3307,
              parent_id: 391,
              region_name: "瓯海区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3308,
              parent_id: 391,
              region_name: "瑞安市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3309,
              parent_id: 391,
              region_name: "乐清市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3310,
              parent_id: 391,
              region_name: "洞头县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3311,
              parent_id: 391,
              region_name: "永嘉县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3312,
              parent_id: 391,
              region_name: "平阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3313,
              parent_id: 391,
              region_name: "苍南县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3314,
              parent_id: 391,
              region_name: "文成县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3315,
              parent_id: 391,
              region_name: "泰顺县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 392,
          parent_id: 31,
          region_name: "舟山",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3316,
              parent_id: 392,
              region_name: "定海区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3317,
              parent_id: 392,
              region_name: "普陀区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3318,
              parent_id: 392,
              region_name: "岱山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3319,
              parent_id: 392,
              region_name: "嵊泗县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        },
        {
          id: 393,
          parent_id: 31,
          region_name: "衢州",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3320,
              parent_id: 393,
              region_name: "衢州市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3321,
              parent_id: 393,
              region_name: "江山市",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3322,
              parent_id: 393,
              region_name: "常山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3323,
              parent_id: 393,
              region_name: "开化县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3324,
              parent_id: 393,
              region_name: "龙游县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 32,
      parent_id: 1,
      region_name: "重庆",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 394,
          parent_id: 32,
          region_name: "重庆",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3325,
              parent_id: 394,
              region_name: "合川区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3326,
              parent_id: 394,
              region_name: "江津区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3327,
              parent_id: 394,
              region_name: "南川区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3328,
              parent_id: 394,
              region_name: "永川区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3329,
              parent_id: 394,
              region_name: "南岸区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3330,
              parent_id: 394,
              region_name: "渝北区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3331,
              parent_id: 394,
              region_name: "万盛区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3332,
              parent_id: 394,
              region_name: "大渡口区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3333,
              parent_id: 394,
              region_name: "万州区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3334,
              parent_id: 394,
              region_name: "北碚区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3335,
              parent_id: 394,
              region_name: "沙坪坝区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3336,
              parent_id: 394,
              region_name: "巴南区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3337,
              parent_id: 394,
              region_name: "涪陵区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3338,
              parent_id: 394,
              region_name: "江北区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3339,
              parent_id: 394,
              region_name: "九龙坡区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3340,
              parent_id: 394,
              region_name: "渝中区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3341,
              parent_id: 394,
              region_name: "黔江开发区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3342,
              parent_id: 394,
              region_name: "长寿区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3343,
              parent_id: 394,
              region_name: "双桥区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3344,
              parent_id: 394,
              region_name: "綦江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3345,
              parent_id: 394,
              region_name: "潼南县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3346,
              parent_id: 394,
              region_name: "铜梁县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3347,
              parent_id: 394,
              region_name: "大足县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3348,
              parent_id: 394,
              region_name: "荣昌县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3349,
              parent_id: 394,
              region_name: "璧山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3350,
              parent_id: 394,
              region_name: "垫江县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3351,
              parent_id: 394,
              region_name: "武隆县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3352,
              parent_id: 394,
              region_name: "丰都县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3353,
              parent_id: 394,
              region_name: "城口县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3354,
              parent_id: 394,
              region_name: "梁平县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3355,
              parent_id: 394,
              region_name: "开县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3356,
              parent_id: 394,
              region_name: "巫溪县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3357,
              parent_id: 394,
              region_name: "巫山县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3358,
              parent_id: 394,
              region_name: "奉节县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3359,
              parent_id: 394,
              region_name: "云阳县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3360,
              parent_id: 394,
              region_name: "忠县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3361,
              parent_id: 394,
              region_name: "石柱",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3362,
              parent_id: 394,
              region_name: "彭水",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3363,
              parent_id: 394,
              region_name: "酉阳",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3364,
              parent_id: 394,
              region_name: "秀山",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 33,
      parent_id: 1,
      region_name: "香港",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 395,
          parent_id: 33,
          region_name: "香港",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3365,
              parent_id: 395,
              region_name: "沙田区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3366,
              parent_id: 395,
              region_name: "东区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3367,
              parent_id: 395,
              region_name: "观塘区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3368,
              parent_id: 395,
              region_name: "黄大仙区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3369,
              parent_id: 395,
              region_name: "九龙城区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3370,
              parent_id: 395,
              region_name: "屯门区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3371,
              parent_id: 395,
              region_name: "葵青区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3372,
              parent_id: 395,
              region_name: "元朗区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3373,
              parent_id: 395,
              region_name: "深水埗区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3374,
              parent_id: 395,
              region_name: "西贡区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3375,
              parent_id: 395,
              region_name: "大埔区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3376,
              parent_id: 395,
              region_name: "湾仔区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3377,
              parent_id: 395,
              region_name: "油尖旺区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3378,
              parent_id: 395,
              region_name: "北区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3379,
              parent_id: 395,
              region_name: "南区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3380,
              parent_id: 395,
              region_name: "荃湾区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3381,
              parent_id: 395,
              region_name: "中西区",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3382,
              parent_id: 395,
              region_name: "离岛区",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 34,
      parent_id: 1,
      region_name: "澳门",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 396,
          parent_id: 34,
          region_name: "澳门",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3383,
              parent_id: 396,
              region_name: "澳门",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    },
    {
      id: 35,
      parent_id: 1,
      region_name: "台湾",
      region_type: 1,
      agency_id: 0,
      children: [
        {
          id: 397,
          parent_id: 35,
          region_name: "台湾",
          region_type: 2,
          agency_id: 0,
          children: [
            {
              id: 3384,
              parent_id: 397,
              region_name: "台北",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3385,
              parent_id: 397,
              region_name: "高雄",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3386,
              parent_id: 397,
              region_name: "基隆",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3387,
              parent_id: 397,
              region_name: "台中",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3388,
              parent_id: 397,
              region_name: "台南",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3389,
              parent_id: 397,
              region_name: "新竹",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3390,
              parent_id: 397,
              region_name: "嘉义",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3391,
              parent_id: 397,
              region_name: "宜兰县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3392,
              parent_id: 397,
              region_name: "桃园县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3393,
              parent_id: 397,
              region_name: "苗栗县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3394,
              parent_id: 397,
              region_name: "彰化县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3395,
              parent_id: 397,
              region_name: "南投县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3396,
              parent_id: 397,
              region_name: "云林县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3397,
              parent_id: 397,
              region_name: "屏东县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3398,
              parent_id: 397,
              region_name: "台东县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3399,
              parent_id: 397,
              region_name: "花莲县",
              region_type: 3,
              agency_id: 0,
              
            },
            {
              id: 3400,
              parent_id: 397,
              region_name: "澎湖县",
              region_type: 3,
              agency_id: 0,
              
            }
          ]
        }
      ]
    }
  ]
};
